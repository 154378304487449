import React from "react";
import "./AboutUsWhoWeAre.css";

import { Link } from "react-router-dom";

const AboutUsWhoWeAre = () => {
  return (
    <>
      <div className="main-aboutus-div">
        <div className="container-div">
          <div className="heading-about-us">
            <h2 style={{ fontSize: "3.5rem", "padding-left": "1.2rem" }}>
              WHO WE ARE ?
            </h2>
          </div>
          <div className="about-us-p">
            <h2>
              A brief introduction to our team and expertise in Web & Blockchain
              Development{" "}
            </h2>
            <p className="contents">
              Unlike our competitors, our services are personalized, secure, and
              infinitely scalable web and blockchain solutions. We combine
              talent with quality and achieve results that provide a positive
              impact on your business. Our main value is the customer focus and
              guarantee that every job is done with the maximum professionally.
            </p>
            <div className="Who-we-are-buttons">
              <Link to="/contact-us">
                {" "}
                <div className="HddrBtns ">
                  <button className="btn BtnPrimry TlkBtn" target="_blank">
                    <span className="img-fluid lazyload ">Visit Now</span>
                    <span>Visit Now</span>
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsWhoWeAre;

