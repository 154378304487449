// industriesData.js
const industriesData = {
  software: [
    {
      id: 1,
      title: "Manufacturing",
      description:
        "Software development manufacturing streamlines coding, testing, and deployment using systematic processes, tools, and automation for quality delivery.",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "Retail",
      description:
        "Retail in software development involves creating, customizing, or selling software solutions tailored for retail businesses to enhance operations.",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: "Healthcare",
      description:
        "Healthcare software development enhances patient care, streamlines processes, ensures data security, and drives innovation in medical technologies.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Finance",
      description:
        "Effective software development finance involves budgeting, resource allocation, cost control, ROI analysis, and optimizing development efficiency.",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: "Education",
      description:
        "Software development education equips learners with coding, problem-solving, and design skills to build, test, and maintain software applications.",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: "Human Resources",
      description:
        "Effective HR in software development focuses on talent acquisition, skill development, team collaboration, and employee well-being for success.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: "Project Management",
      description:
        "Efficient software development requires planning, team collaboration, resource management, and agile methodologies to deliver quality solutions.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Graphic Design",
      description:
        "Graphic design in software development enhances user interfaces, ensuring visually appealing and intuitive experiences that improve usability and engagement.",
      image: "./images/graphic-designer.webp",
    },
  ],
  erp: [
    {
      id: 1,
      title: "Manufacturing",
      description:
        "ERP software manufacturers are experts in developing solutions that integrate business processes, enhancing efficiency & decision-making across various industries.",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "Retail",
      description:
        "ERP software for retail smooth-out operations enhances inventory management, improves customer satisfaction, & improves decision-making with real-time data.",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: "Healthcare",
      description:
        "ERP software in healthcare refines processes improves patient care, manages resources, & ensures compliance, boosting efficiency & reducing costs.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Finance",
      description:
        "ERP software development requires essential investment in research, design, coding, testing, & ongoing maintenance to ensure scalability, security, & integration.",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: "Education",
      description:
        "ERP software development education focuses on programming, business processes, and system integration, preparing professionals to build efficient enterprise solutions.",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: "Human Resources",
      description:
        "Human resources in ERP software development focus on skilled developers, project managers, and testers who collaborate to create efficient, customized solutions.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: "Project Management",
      description:
        "Effective project management in ERP software development ensures clear goals, efficient resource allocation, and timely delivery, driving business process integration.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Graphic Design",
      description:
        "Graphic design in ERP software development enhances user experience with intuitive interfaces, improving usability and visual appeal for better system interaction.",
      image: "./images/graphic-designer.webp",
    },
  ],
  pos: [
    {
      id: 1,
      title: "Retail",
      description:
        "POS software in the retail assiduity streamlines deals,  force, and client operation, enhancing effectiveness and delivering lawless deals.",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "Hospitality",
      description:
        "POS software in the hospitality assiduity streamlines operations, enhances guest gests, manages force, and simplifies payment processes.",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: "Healthcare",
      description:
        "POS systems in healthcare streamline patient billing, force operation, and payment processing, enhancing effectiveness and perfecting patient experience.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Warehousing",
      description:
        "The warehousing assiduity in POS system software streamlines force operation, tracks stock situations, and enhances functional effectiveness.",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: " Automotive",
      description:
        "The warehousing assiduity in POS system software streamlines force operation, tracks stock levels software in the automotive assiduity streamlines deals, manages force, tracks services, and enhances client gests with effectiveness",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: "Food & Beverage",
      description:
        " POS system software in the food & libation assiduity streamlines order processing, force operation, and payments, enhancing effectiveness and client experience.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: " E-commerce",
      description:
        "POS system software in e-commerce streamlines deals,  force operation, and client experience, enhancing effectiveness and boosting online deals performances, and enhances functional effectiveness.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Education",
      description:
        "POS system software in the education assiduity streamlines figure collection, pupil operation, and reporting, enhancing effectiveness and perfecting fiscal shadowing.",
      image: "./images/graphic-designer.webp",
    },
  ],
  inventory: [
    {
      id: 1,
      title: "Retail",
      description:
        "Tracks stock, and sales and manages inventory at multiple sites.",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "E-commerce",
      description:
        "Manages online product listings, warehouse operations, and order fulfilment.",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: " Manufacturing ",
      description:
        "Monitors raw materials, production processes, and finished goods.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Healthcare",
      description:
        "Ensures proper management of medical supplies and equipment.",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: " Hospitality",
      description:
        "Looks after supplies, equipment and stock for hotels, restaurants and event staging.",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: "Wholesale & Distributor",
      description:
        " Provides accurate tracking of bulk inventory and order management.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: "Food & Beverage",
      description:
        "Monitors perishable goods, stock levels, and inventory turnover rates.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Construction",
      description:
        "Tracks tools, equipment, and building materials across projects.",
      image: "./images/graphic-designer.webp",
    },
  ],
  accounting: [
    {
      id: 1,
      title: "Healthcare",
      description:
        "Healthcare account and billing software well runs invoicing, payments, insurance claims, and financial management expertly.",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "Retail & E-commerce",
      description:
        "Elegant inventory, billing, payments, and financial reporting for ideal retail and e-commerce operations.",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: "  Telecommunications ",
      description:
        "Telecommunications trust on account and billing software for invoicing, subscription management, and payment tracking.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Finance",
      description:
        "Finance in account and billing software up-to-date invoicing, payments, reporting, and financial management capability.",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: " Hospitality",
      description:
        " Well-run hospitality finances with automated billing, expense tracking, and revenue management for excellent operations.",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: " Real Estate",
      description:
        " Well-organized property transactions, rental management, invoicing, and financial reporting for real estate businesses.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: "Education",
      description:
        "Education in account and billing software authorizes industries with financial accuracy, automation, and perfect management.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Manufacturing",
      description:
        "Manufacturing account and billing software well-organized financial processes, tracks costs, manages orders, and ensures accuracy in production-related transactions.",
      image: "./images/graphic-designer.webp",
    },
  ],
 hrm: [
    {
      id: 1,
      title: "Corporate Sector",
      description:
        "HRMS software simplifies hiring, attendance tracking, and payroll handling within the corporate sector.",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "Education Sector",
      description:
        "HRMS software makes staff management easier, monitors attendance, automates payroll, and improves efficiency in educational institutions.",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: "  Healthcare Industry ",
      description:
        "In the health industry, HRMS software tracks employee scheduling, ensures adherence, and runs benefits for all healthcare professionals.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Manufacturing Sector",
      description:
        "Manufacturing companies use HRMS software to manage work schedules, track performance, and organize training programs.",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: " Retail Industry",
      description:
        " HRMS software is used by manufacturing companies to manage work schedules, track performance, and organize training programs.",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: " Hospitality Sector",
      description:
        "Hospitality businesses will also use the software to facilitate scheduling of the employee, tracking of attendance, and also payroll to enable easy, rapid, and effective management.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: "Technology Companies",
      description:
        "Tech companies employ HRMS software to manage their employee data and track projects along with streamlining recruitment processes efficiently.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Nonprofit Organizations",
      description:
        " Nonprofit organizations utilize HRMS software to manage volunteers, process payroll, and ensure compliance with regulations.",
      image: "./images/graphic-designer.webp",
    },
  ],
  employee: [
    {
      id: 1,
      title: "Human Resources",
      description:
        "Human coffers in hand shadowing software streamlines reclamation, performance monitoring, and hand operation to boost effectiveness and productivity. ",
      image: "./images/factory.webp",
    },
    {
      id: 2,
      title: "Retail",
      description:
        " Retail hand shadowing software helps manage work hours, and examiner performance, and optimize schedules, boosting productivity and icing compliance. ",
      image: "./images/supermarket.webp",
    },
    {
      id: 3,
      title: "  Manufacturing ",
      description:
        "Hand shadowing software in manufacturing boosts productivity by covering attendance, tasks, and performance,  icing effective operations and streamlining operations.",
      image: "./images/healthcare.webp",
    },
    {
      id: 4,
      title: "Construction",
      description:
        " Construction hand shadowing software streamlines pool operation, enhancing productivity, covering work hours, and icing safety compliance on job spots. ",
      image: "./images/bank.webp",
    },
    {
      id: 5,
      title: " Healthcare",
      description:
        " Healthcare in hand shadowing software helps cover hand well-being track health data, and ensure compliance with health regulations for a safer plant.",
      image: "./images/school.webp",
    },
    {
      id: 6,
      title: " Freelance",
      description:
        "Freelance tracking in employee software allows businesses to monitor remote work, track hours, and manage projects efficiently.",
      image: "./images/user.webp",
    },
    {
      id: 7,
      title: "Delivery Services",
      description:
        "  Delivery services in hand shadowing software streamline logistics, optimize routes, and enhance real-time shadowing for bettered effectiveness and timely deliveries.",
      image: "./images/architect.webp",
    },
    {
      id: 8,
      title: "Education",
      description:
        " Education in hand shadowing software helps enhance chops, track progress, and literacy openings for professional growth within associations.",
      image: "./images/graphic-designer.webp",
    },
  ],
};

export default industriesData;
