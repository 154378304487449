import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import InventroySwiperSlider from "./SwiperSlider/InventroySwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
import SeoHelmet from "../../../Helmet";
import OurProjects from "../../OurProjects";
import projectData from "../../projectData";
import FAQSection2 from "../../FAQSection2";
import OurProcedureNew from "../../OurProcedureNew";
// import '../../main.css'

const InventoryManagmentSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Simplify and Scale: Inventory Management Software Development"
          description=" Discover Simplify and Scale, the new inventory management software development to streamline operations, optimise stock levels, and scale your business effortlessly. Authorise your team with real-time insights and smart automation tools."
          keywords="Inventory Management Software Development"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      Creative Inventory Management Software Development
                      Solution Company
                    </h1>
                    <p className="heading-tagline">
                      Managing Force businesslike is crucial to business
                      success. With scalable software results, you can
                      streamline operations, reduce crimes, and better stock
                      control. Our innovative systems adjust to your business
                      requirements, icing real-time shadowing, smarter decision-
                      timber, and flawless growth. Transfigure your force
                      operation and boost productivity with our adjusted
                      software results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Specialist in Inventory Management Software
                </h2>
                <p style={{ fontSize: "20px" }}>
                  We specialize in making powerful, reliable inventory
                  management software customised specifically for your business.
                  Our solutions make tracking stock a little easier while
                  eliminating errors in your processes with orders. No matter
                  whether it is a small business or a rapidly growing
                  enterprise, we deliver all the tools to simplify and enhance
                  efficiency throughout inventory management processes at every
                  level.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/managmentsoft.webp" alt="managmentsoft" />
              </div>
            </div>
          </section>

          <InventroySwiperSlider />
          <KeyFeatures
            heading=" Key Features Of Inventory Management System "
            description=" "
            filterKey="inventory"
          />
          <TechnologyStack />

          <Industries
            category="inventory"
            heading="Industries That Use Our Software Solutions"
          />

          <AdminControlFeatures
            category="software"
            heading="Admin Control Features"
          />

          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>

          <section>
            <OurProjects
              projectData={projectData}
              heading="Our Projects: A Showcase of Impactful Solutions"
              description=""
              filterKey="inventory"
            />
          </section>

          <section>
            <SimilarFile />
          </section>
          <FAQSection2 category="inventory" />
          <OurProcedureNew category="inventory" />
        </div>
      </div>
    </>
  );
};

export default InventoryManagmentSoftware;
