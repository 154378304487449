import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import "./Blog.css";

const BlogShow = () => {
  const { id } = useParams(); // Fetch the ID from URL parameters
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://maincompanybackend.metablocktechnologies.org/api/v1/artical/${id}`
        );
        if (
          response.data.message === "No blogs found for the selected category"
        ) {
          setError("No blogs found for this ID.");
        } else {
          setBlog(response.data); // Set blog data
        }
      } catch (err) {
        console.error("Error fetching blog data:", err);
        setError(
          "Failed to fetch blog data. Please check the ID or try again later."
        );
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchBlog();
  }, [id]);

  // Display a loading message while data is being fetched
  if (loading) return <p>Loading...</p>;

  // Display an error message if the fetch fails
  if (error) return <p>{error}</p>;

  return (
    <div>
      {/* Title Bar */}
      <div className="titlebar titlebar-sm scheme-dark bg-gray-2 bb-fade-black-005">
        <div className="titlebar-inner py-5">
          <div className="container titlebar-container">
            <div className="row titlebar-container">
              <h1 className="ourtopheadingblog">Our Blogs</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <section id="content">
        <div className="content-wrap pb-0">
          <div className="container clearfix">
            <div className="row">
              <div className="col-md-8">
                <div className="single-post nobottommargin">
                  <div
                    style={{ marginTop: "15px" }}
                    className="titlebar-col col-md-12"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                      className="font-size-14 text-fade-dark-06"
                    >
                      <h1
                        style={{ fontSize: "40px", fontFamily: "serif" }}
                        className="font-size-26 mb-1"
                      >
                        {blog?.title}{" "}
                      </h1>
                    </div>
                  </div>
                  {/* Blog Image */}
                  <div className="">
                    <img
                      style={{ margin: "0 auto", marginTop: "10px" }}
                      src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${blog?.image}`}
                      alt={blog.title || "Blog Image"}
                      className="img-responsive"
                    />
                  </div>
                  {/* Blog Content */}
                  <div className="entry clearfix">
                    <div
                      style={{ padding: "30px" }}
                      className="entry-content notopmargin"
                    >
                      <p
                        style={{
                          fontFamily: "arial",
                          fontSize: "20px",
                          color: "white",
                        }}
                      >
                        {blog?.description}
                      </p>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>

              {/* Sidebar */}
              <div className="col-md-3 col-md-offset-1 sidebar-container">
                <aside className="main-sidebar"></aside>
                <div className="aside-block">
                  <h3 className="aside-title">Tags</h3>
                  <ul className="aside-tags list-unstyled">
                    {blog?.tags?.length > 0 ? (
                      blog.tags.map((tag, index) => (
                        <li key={index}>
                          <b className="text-blue-800">#</b>
                          {tag}
                        </li>
                      ))
                    ) : (
                      <li>No tags available</li>
                    )}
                  </ul>
                </div>
              </div>
              {/* End Sidebar */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogShow;