import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Blog.css";

const Blog = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]); // All blogs based on filter
  const [categories, setCategories] = useState([]); // Available categories
  const [selectedCategory, setSelectedCategory] = useState(null); // Selected category
  const [trendingBlogs, setTrendingBlogs] = useState([]); // Trending blogs state
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  // Fetch all blogs or specific blogs by category
  const fetchBlogs = useCallback(async (categoryId = "", query = "") => {
    try {
      const url = categoryId
        ? `https://maincompanybackend.metablocktechnologies.org/api/v1/artical/blogs/filter?categoryId=${categoryId}`
        : "https://maincompanybackend.metablocktechnologies.org/api/v1/artical";
      const response = await axios.get(url);
      let filteredBlogs = response.data;

      if (query) {
        filteredBlogs = filteredBlogs.filter((blog) =>
          blog.title.toLowerCase().includes(query.toLowerCase())
        );
      }

      setBlogs(filteredBlogs);
    } catch (error) {
      console.error("Error fetching blogs:", error.message);
    }
  }, []);

  // Fetch categories
  const fetchCategories = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org/api/v1/blogcategory"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  }, []);

  // Fetch trending blogs
  const fetchTrendingBlogs = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org/api/v1/artical"
      );
      const trending = response.data.filter((blog) => blog.Trending === true);
      setTrendingBlogs(trending);
    } catch (error) {
      console.error("Error fetching trending blogs:", error.message);
    }
  }, []);

  // Handle category click to filter blogs
  const handleCategoryClick = (categoryId) => {
    if (categoryId === "all") {
      setSelectedCategory(null);
      fetchBlogs(); // Fetch all blogs when "All" is selected
    } else {
      setSelectedCategory(categoryId);
      fetchBlogs(categoryId); // Fetch blogs for the selected category
    }
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update search query state
  };

  // Handle search button click
  const handleSearchClick = () => {
    fetchBlogs(selectedCategory, searchQuery); // Fetch blogs based on search and selected category
  };

  // Increment likes function
  const handleLikeClick = async (blogId) => {
    try {
      const response = await axios.put(
        `https://maincompanybackend.metablocktechnologies.org/api/v1/artical/${blogId}/increment-likes`
      );
      const updatedBlog = response.data;

      // Update the blogs state to reflect the new number of likes
      setBlogs((prevBlogs) =>
        prevBlogs.map((blog) =>
          blog._id === updatedBlog._id
            ? { ...blog, likes: updatedBlog.likes }
            : blog
        )
      );
    } catch (error) {
      console.error("Error incrementing likes:", error.message);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchCategories();
    fetchTrendingBlogs();
  }, [fetchBlogs, fetchCategories, fetchTrendingBlogs]);

  return (
    <>
      <main className="blog_new_main">
        <section className="blog_new_banner">
          <div className="container-fluid p-0">
            <div className="VideoWrper">
              <div className="video_sec">
                <img src="" alt="" />
              </div>
              <div className="container">
                <div className="content_sec">
                  <div className="title_sec">
                    <h2 className="title">Next-gen Blogs</h2>
                  </div>
                  <p className="contents">
                    Bits and Bytes Explored in the Ever-Evolving Digital
                    Landscape
                  </p>
                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-5 col-md-5 col-10">
                      <div className="search_sec">
                        <div className="sear_fd1">
                          <div className="input-group">
                            <input
                              type="text"
                              name="search"
                              className="form-control"
                              placeholder="SEARCH"
                              value={searchQuery}
                              onChange={handleSearchChange} // Handle search input change
                            />
                            <span className="input-group-addon">
                              <button
                                className="btn search_btn"
                                onClick={handleSearchClick} // Handle search button click
                              >
                                <i className="fa fa-search" />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <h1 className="custom-text">Trending Blogs</h1>

        <section className="blog_new_trending_sec" id="blog_new_trending_sec">
          <div className="container-fluid p-0">
            <div className="carousel_sec">
              <div className="trending-blog slider">
                {trendingBlogs.length > 0 ? (
                  trendingBlogs.slice(0, 3).map((blog, index) => (
                    <div
                      key={index}
                      className="items"
                      style={{
                        background: 'url("")',
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="trending_box">
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-12">
                            <div className="sub_title">{blog.title}</div>
                            <p className="sub_contents">{blog.description}</p>
                            <div className="readmore_btn_sec">
                              <a
                                onClick={() =>
                                  navigate(`/Blogshow/${blog._id}`)
                                }
                                className="btn BtnPrimry TlkBtn readmore_btn"
                                target="_blank"
                              >
                                <span>Read More</span>
                                <span>Read More</span>
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="img_sec">
                              <img
                                style={{ borderRadius: "10px" }}
                                src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${blog.image}`}
                                alt={blog.title} // Fixed typo from blog.tittle to blog.title
                                title={blog.title}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No trending blogs available.</p>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="blog_new_popular_sec" id="blog_new_popular_sec">
          <div className="container">
            {/* Categories Navigation */}
            <div
              className="nav flex-column nav-pills"
              id="popular_tab_sec"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className={`nav-link ${
                  selectedCategory === null ? "active" : ""
                }`}
                type="button"
                onClick={() => handleCategoryClick("all")}
              >
                <span>All</span>
              </button>

              {categories.map((category) => (
                <button
                  className={`nav-link ${
                    selectedCategory === category._id ? "active" : ""
                  }`}
                  id={category._id}
                  data-toggle="pill"
                  type="button"
                  role="tab"
                  aria-selected={selectedCategory === category._id}
                  key={category._id}
                  onClick={() => handleCategoryClick(category._id)}
                >
                  <span>{category.name}</span>
                </button>
              ))}
            </div>

            {/* Articles Section */}
            <div className="tab-content" id="popular_tab_content_sec">
              <div
                className="tab-pane fade show active"
                id="ai-"
                role="tabpanel"
                aria-labelledby="ai-_tab"
              >
                <div
                  style={{ marginBottom: "20px" }}
                  className="row justify-content-center"
                >
                  {/* Dynamically render blogs */}
                  {blogs.length > 0 ? (
                    blogs.map((blog) => (
                      <div className="col-md-3" key={blog._id}>
                        <div
                          className="popular_tab_box"
                          style={{
                            backgroundColor: "#262626",
                            borderRadius: "8px",
                          }}
                        >
                          <div className="category_btn_sec">
                            <a href="#" className="category_btn">
                              {blog.title.split(" ").slice(0, 2).join(" ")}
                            </a>
                          </div>
                          <a
                            href={blog.link || "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="img_sec">
                              <img
                                className="img-fluid"
                                style={{
                                  borderTopLeftRadius: "5px",
                                  borderTopRightRadius: "5px",
                                }}
                                src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${blog.image}`}
                                alt={blog.title || "Article Image"}
                                title={blog.title}
                              />
                            </div>
                            <div className="content_sec content_sec1">
                              <div className="sub_title">
                                {blog.title.split(" ").slice(0, 5).join(" ")}
                              </div>
                              <p className="sub_contents">
                                {blog.description
                                  .split(" ")
                                  .slice(0, 15)
                                  .join(" ")}
                                ...
                              </p>
                            </div>
                          </a>
                          <div className="bottom_sec">
                            <div
                              className="like_sec"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <button
                                className="btn like_sec_btn"
                                onClick={() => handleLikeClick(blog._id)}
                              >
                                <i className="fa fa-thumbs-up" />
                              </button>
                              <span>{blog.likes || 0}</span>
                            </div>
                            <div className="read_more_sec">
                              <Link to={`/Blogshow/${blog._id}`}>
                                <button>Read more</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No blogs found for the selected category.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Blog;
