import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import HotalSwiperSlider from "./SwiperSlider/HotalSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const HotelManagmentSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Hotel Managment Software Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    Hotel Management Software streamlines hotel operations by
                    automating tasks like reservations, check-ins, and billing,
                    enhancing guest satisfaction and operational efficiency.
                    With integrated features for inventory management and
                    analytics, it empowers hoteliers to optimize resources,
                    deliver personalized experiences, and maximize revenue
                    potential effectively.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in hotel management software, our focus is on
                providing tailored solutions to meet the diverse needs of
                hoteliers and hospitality businesses. Our software is
                meticulously crafted to streamline various aspects of hotel
                operations, including reservations, guest management,
                housekeeping, and billing. With our expertise, hoteliers can
                efficiently manage room inventory, streamline check-in and
                check-out processes, and deliver exceptional guest experiences.
                Whether it's a small boutique hotel or a large chain, our
                management software is designed to optimize efficiency, enhance
                guest satisfaction, and drive revenue growth. We are committed
                to empowering hoteliers with the tools they need to succeed in
                the dynamic and competitive hospitality industry.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/hotelmanagmentdev.webp" alt="hotelmanagmentdev" />
            </div>
          </div>
        </section>

        <HotalSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
      <CardSlider/>
    </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
      <Phonecardslider />
    </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default HotelManagmentSoftware;
