// techStackData.js
const techStackData = [
  {
    category: "Web",
    description: "Over the years web development has undergone a drastic change and as it remains competitive we ensure at MetaBlock we adopt the enhanced technologies. With experience in developing state-of-the-art tools such as HTML, CSS, JavaScript, Bootstrap, Tailwind, and React JS, we realize present-day, adaptive, and high-quality websites to conform to our customers’ requirements.",
    technologies: [
      {
        name: "Next Js",
        image: "./SGimage/nuxtimg.webp",
      },
      {
        name: "Next Js",
        image: "./SGimage/nextjsicon.webp",
      },
      {
        name: "Express Js",
        image: "./SGimage/express-js.webp",
      },
      {
        name: "Node Js",
        image: "./SGimage/nodejs.webp",
      },
      {
        name: "Angular JS",
        image: "./SGimage/angularicon.webp",
      },
      {
        name: "Mongo DB",
        image: "./SGimage/mongodbicon.webp",
      },
      {
        name: "JQuery",
        image: "./SGimage/jqueryicon.webp",
      },
      {
        name: "My Sql",
        image: "./SGimage/mysqlicon.webp",
      },
      {
        name: "PHP",
        image: "./SGimage/phpicon.webp",
      },
      {
        name: "Laravel",
        image: "./SGimage/laravelicon.webp",
      },
      {
        name: "Codeigniter",
        image: "./SGimage/codeignitoricon.webp",
      },
      {
        name: "Bootstrap",
        image: "./SGimage/bootstrapicon.webp",
      },
      {
        name: "HTML 5",
        image: "./SGimage/htmlcssicon.webp",
      },
      {
        name: "CSS 3",
        image: "./SGimage/cssicon.webp",
      },
    ],
  },
  {
    category: "Mobile",
    description: "A mobile tech stack refers to the software development instruments, languages, and environment utilized in mobile applications development. It contains all that is required for creating the application interface and functionality (UI/UX) as well as users’ tasks and data (UI/and UX/UI interfaces). It is actually more of a developer toolkit for designing successful and effective mobile applications.",
    technologies: [
      {
        name: "Flutter",
        image: "./SGimage/MobileIcon/Fluttericon.webp",
      },
      {
        name: "Kotlin",
        image: "./SGimage/MobileIcon/kotlinicon.webp",
      },
      {
        name: "Swift",
        image: "./SGimage/MobileIcon/swifticon.webp",
      },
      {
        name: "Android Studio",
        image: "./SGimage/MobileIcon/androidstudio.webp",
      },
      {
        name: "iOS",
        image: "./SGimage/MobileIcon/iosicon.webp",
      },
      {
        name: "Android",
        image: "./SGimage/MobileIcon/androidicon.webp",
      },
      {
        name: "Java",
        image: "./SGimage/MobileIcon/javaicon.webp",
      },
      {
        name: "Jetpack",
        image: "./SGimage/MobileIcon/jetpackicon.webp",
      },
      {
        name: "Ionic",
        image: "./SGimage/MobileIcon/iconicicon.webp",
      },
      {
        name: "ReactJS",
        image: "./SGimage/MobileIcon/reactjsicon.webp",
      },
      {
        name: "Codeigniter",
        image: "./SGimage/MobileIcon/codeignitoricon.webp",
      },
      {
        name: "MongoDB",
        image: "./SGimage/MobileIcon/mongodbicon.webp",
      },
    ],
  },
  {
    category: "Desktop Apps",
    description: "Once performed outstandingly in web development strategies, the company broadened our field to desktop application development, meeting our clients’ specific needs across different industries. Our apps are not resource hungry and are optimized to perform their functionalities at ultrahigh speed and with high accuracy. In this sector, we develop windows application software using Node.js, React.js, Express.js, MongoDB, PHP, Laravel etc., here the developed software will be running perfectly with high functionality in any PC.",
    technologies: [
      {
        name: "REACT JS",
        image: "./SGimage/DeskstopIcon/reactjsicon.webp",
      },
      {
        name: "NW.JS",
        image: "./SGimage/DeskstopIcon/nwjsicon.webp",
      },
      {
        name: "APP.JS",
        image: "./SGimage/DeskstopIcon/appjsicon.webp",
      },
      {
        name: "Meteor",
        image: "./SGimage/DeskstopIcon/meteoricon.webp",
      },
      {
        name: "Proton Native",
        image: "./SGimage/DeskstopIcon/protonicon.webp",
      },
      {
        name: "Sevelet",
        image: "./SGimage/DeskstopIcon/sevleticon.webp",
      },
      {
        name: "Angular 8",
        image: "./SGimage/DeskstopIcon/angularicon.webp",
      },
      {
        name: "Node JS",
        image: "./SGimage/DeskstopIcon/nodejsicon.webp",
      },
      {
        name: "Vue.JS",
        image: "./SGimage/DeskstopIcon/vuejsicon.webp",
      },
      {
        name: "Electron.JS",
        image: "./SGimage/DeskstopIcon/electronjsicon.webp",
      },
      {
        name: "Angular JS",
        image: "./SGimage/DeskstopIcon/angularicon.webp",
      },
    ],
  },
  {
    category: "Blockchain",
    description: "The blockchain technology is a system that has several layers; all of them contribute to the development of the secure decentralized system. The mid layer is the blockchain protocol through which everything is made trustworthy. Besides, various systems of tools and programming languages enable handling transactions and functioning of applications. All these layers hold significant responsibilities of maintaining the system secure and efficient.",
    technologies: [
      {
        name: "Solidity",
        image: "./SGimage/blockchainicon/solidityicon.webp",
      },
      {
        name: "HyperLedger",
        image: "./SGimage/blockchainicon/hyperledgericon.webp",
 },
      {
        name: "Golang",
        image: "./SGimage/blockchainicon/golangicon.webp",
      },
      {
        name: "SmartContract",
        image: "./SGimage/blockchainicon/smartcontracticon.webp",
      },
      {
        name: "IPFS",
        image: "./SGimage/blockchainicon/ipfsicon.webp",
      },
    ],
  },
  {
    category: "Blockchain Tools",
    description: "It is important to remark that blockchain oriented tools are indispensable to building trustworthy information storage infrastructures. They assist in creation of a decentralized database that is secure from tampering, modification or hacking. Such tools are important in that they keep data secure, accurate, and from being modified by unauthorized persons.",
    technologies: [
      {
        name: "Metamask",
        image: "./SGimage/BlockchainToolsIcon/metamaskicon.webp",
      },
      {
        name: "Ganache",
        image: "./SGimage/BlockchainToolsIcon/ganacheicon.webp",
      },
      {
        name: "Truffle",
        image: "./SGimage/BlockchainToolsIcon/truffleicon.webp",
      },
      {
        name: "Remix",
        image: "./SGimage/BlockchainToolsIcon/remixicon.webp",
      },
    ],
  },
  {
    category: "Game",
    description: "A game tech stack refers to the underlying tools that exist to support game development, as well as the technologies required to continue supporting and selling the games. They include managing game data, working server side, to giving the game its face in form of a front-end as well as its brain in form of a back-end.",
    technologies: [
      {
        name: "Unity",
        image: "./SGimage/GameIcon/unityicon.webp",
      },
      {
        name: "Unreal Engine",
        image: "./SGimage/GameIcon/unrealicon.webp",
      },
      {
        name: "React",
        image: "./SGimage/GameIcon/reactjs.png",
      },
      {
        name: "NodeJs",
        image: "./SGimage/GameIcon/nodejsicon.webp",
      },
      {
        name: "Mongo DB",
        image: "./SGimage/GameIcon/mongodbicon.webp",
      },
      {
        name: "Public Blockchains",
        image: "./SGimage/GameIcon/publicblokchainicon.webp",
      },
      {
        name: "Marmalade SDK",
        image: "./SGimage/GameIcon/marmaladeicon.webp",
      },
    ],
  },
];

export default techStackData;