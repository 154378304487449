// TechStack.jsx
import React from 'react';
import techStackData from './techStackData'; // Adjust the path as necessary
import { Link } from 'react-router-dom'; // Ensure you have react-router-dom installed

const TechStack = () => {
  return (
    <div className="MtaverseSec DapsMainBg TechStk">
      <div className="container container-1170">
        <div className="BnrCnt OurservHdd mb-lg-4">
          <h2 className="grident-color-sub-title">
            Modern Web Development with the Latest Tools
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-2 order-lg-2">
            <div className="nav flex-column nav-pills VrtclTbb" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              {techStackData.map((category, index) => (
                <button
                  key={index}
                  className={`nav-link ${index === 0 ? 'active' : ''}`}
                  data-toggle="pill"
                  data-target={`#TechStkTbb${index + 1}`}
                  type="button"
                  role="tab"
                  aria-controls={`TechStkTbb${index + 1}`}
                  aria-selected={index === 0}
                >
                  {category.category}
                </button>
              ))}
            </div>
          </div>
          <div className="col-lg-10 order-lg-1">
            <div className="tab-content" id="v-pills-tabContent">
              {techStackData.map((category, index) => (
                <div
                  key={index}
                  className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                  id={`TechStkTbb${index + 1}`}
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-xl-6 images-ignite-innovation order-2 order-xl-1">
                      <div className="TechStkIconSec">
                        <div className="row">
                          {category.technologies.map((tech, techIndex) => (
                            <div className="col-lg-3 col mb-3" key={techIndex}>
                              <div className="TechStkIconBox text-center aos-init aos-animate" data-aos="fade-up" data-aos-delay={techIndex * 100}>
                                <div className="TechStkIcon">
                                  <img
                                    src={tech.image}
                                    alt={tech.name}
                                    title={tech.name}
                                    loading="eager"
                                    className="img-fluid lazyload"
                                  />
                                </div>
                                <div className="TechStkIcnCnt">
                                  <p>{tech.name}</p>
                                  <p>{tech.description}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="visite-now-button-ignite">
                            <Link to="/contact-us">
                              <div className="HddrBtns mt-4">
                                <button
                                  data-toggle="modal"
                                  data-target="#popup_form_modal"
                                  className="btn BtnPrimry TlkBtn"
                                  target="_blank"
                                >
                                  <span className="img-fluid lazyload mr-2">Visit Now</span>
                                  <span>Visit Now</span>
                                </button>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ignite-innovation-responsive col-xl-6 pt-5 pl-5 contant-ignite-innovation order-1 order-xl-2">
                      <div className="TchStkCnt">
                        <h3 className="h3_title">{category.category}</h3>
                        <p>{category.description}</p>
                      </div>
                      <Link to="/contact-us">
                        <div className="HddrBtns mt-4">
                          <button
                            data-toggle="modal"
                            data-target="#popup_form_modal"
                            className="btn BtnPrimry TlkBtn"
                            target="_blank"
                          >
                            <span className="img-fluid lazyload mr-2">Visit Now</span>
                            <span>Visit Now</span>
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechStack;