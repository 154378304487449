// import React from 'react'
import "./Ourteam.css";
import {
  FaFacebook,
  FaTwitter,
  FaGooglePlus,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import { FaWhatsapp } from "react-icons/fa";

const Ourteam = () => {
  const [ourteam, setOurTeam] = useState([]);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org/api/v1/team/getallmembers"
      );
      setOurTeam(response.data.data.members);
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };

  console.log(ourteam);

  // const ourteam = [
  //     {
  //         image: "./teams/deepak.webp",
  //         name: "Deepak",
  //         position: "Unity, AR/ VR/ Real Engine Developer",
  //         experience: "10+ Year"

  //     },
  //     {
  //         image: "https://picsum.photos/130/130?image=1027",

  //         name: "Ranjita Chaudhary",

  //         position: "Unity Developer",
  //         experience: "3.4+ Year"

  //     },
  //     {
  //         image: "./teams/saurabh.webp",
  //         name: "Sourabh Tirthani",
  //         position: "Blockchain Developer",
  //         experience: "4.5+ Year"
  //     },
  //     {
  //         image: "./teams/jayesh.webp",
  //         name: "Jayesh Vijay",
  //         position: "Full Stack MERN Developer",
  //         experience: "4.5+ Years"
  //     },
  //     {
  //         image: "./teams/nishant2.webp",
  //         name: "Nishant ",
  //         position: "Blockchain Developer",
  //         experience: "3+ Year"
  //     },
  //     {
  //         image: "./teams/shivam.webp",
  //         name: "Shivam Kalani",
  //         position: "MERN Stack Developer",
  //         experience: "3.2+ Year"
  //     },

  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",

  //         name: "Umang Mathur",

  //         position: "Sr. Unity Developer",
  //         experience: "6+ Year"

  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Ashnal",
  //         position: "Node Developer",
  //         experience: "4.2+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Lucky Kumawat",
  //         position: "Full Stack Developer",
  //         experience: "3.5+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Navneet",
  //         position: "PHP Developer",
  //         experience: "6+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Sunil",
  //         position: "React Native",
  //         experience: "3+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Somendra",
  //         position: "sr. Unity Developer",
  //         experience: "6+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Arvind Joshi",
  //         position: "Android Developer",
  //         experience: "4+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Govind Mishra",
  //         position: "Full Stack Developer",
  //         experience: "3.5+ Year"
  //     },

  //     {
  //         image: "./teams/vishnu.webp",
  //         name: "Vishnu",
  //         position: "Frontend React Developer",
  //         experience: "2.5+ Year"
  //     },
  //     {
  //         image: "./teams/ekta.webp",

  //         name: "Ekta",
  //         position: "Business Development Manager",
  //         experience: "4+ Year"

  //     },

  //     {
  //         image: "https://picsum.photos/130/130?image=1027",
  //         name: "Laxmi",
  //         position: "Frontend React Developer",
  //         experience: "1.5+ Year"
  //     },
  //     {
  //         image: "https://picsum.photos/130/130?image=1027",

  //         name: "Rita Yadav",

  //         position: "Business Development Manager",
  //         experience: "4+ Year"

  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Krishna",
  //         position: "Sales & Marketing Specialist",
  //         experience: "5+ Year"
  //     },
  //     {
  //         image: "./teams/devanshu.webp",
  //         name: "Devanshu",
  //         position: "Frontend React Developer",
  //         experience: "3+ Year"
  //     },

  //     {
  //         image: "./teams/Mohit.webp",
  //         name: "Mohit",
  //         position: "Frontend React Developer",
  //         experience: "3+ Year"
  //     },
  //     {
  //         image: "./teams/aakarsh.webp",
  //         name: "Aakarsh",
  //         position: "Business Development Manager",
  //         experience: "3.5+ Year"
  //     },

  //     {
  //         image: "https://picsum.photos/130/130?image=1027",
  //         name: "Sneha Goyal",
  //         position: "Business Development Manager",
  //         experience: "4.5+ Year"
  //     },

  //     {
  //         image: "./teams/Avinash.webp",
  //         name: "Avinash",
  //         position: "Frontend React Developer",
  //         experience: "1+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Nagendra",
  //         position: "Sales & Marketing Specialist",
  //         experience: "8+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Navratan",
  //         position: "Frontend React Developer",
  //         experience: "3+ Year"
  //     },

  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Aayush Sharma",
  //         position: "Sales Manager",
  //         experience: "3+ Year"
  //     },

  //     {
  //         image: "./teams/arjun.webp",
  //         name: "Arjun",
  //         position: "Web Desingner & Frontend Developer",
  //         experience: "1.5+ Year"
  //     },

  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Lavish",
  //         position: "Graphics Designer",
  //         experience: "4+ Year"
  //     },
  //     {
  //         image: "https://picsum.photos/130/130?image=1027",
  //         name: "Sakshi",
  //         position: "Figma Designer",
  //         experience: "2+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Manish",
  //         position: "photoshop illustrator",
  //         experience: "4+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Priyansh",
  //         position: "Sr. Digital Marketing",
  //         experience: "4.5+ Year"
  //     },
  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Ankal",
  //         position: "SEO Expert",
  //         experience: "7.5+ Year"
  //     },
  //     {
  //         image: "https://picsum.photos/130/130?image=1027",
  //         name: "Yukta",
  //         position: "Human Resource",
  //         experience: "2+ Year"
  //     },
  //     {
  //         image: "https://picsum.photos/130/130?image=1027",
  //         name: "Garima",
  //         position: "Software Tester",
  //         experience: "3.5+ Year"
  //     },

  //     {
  //         image: "https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.webp",
  //         name: "Deepak Govind",
  //         position: "Software Tester",
  //         experience: "3+ Year"
  //     },
  // ]
  return (
    <div>
      <section className="breadcrumb_section breadcrumb_padd">
        <div className="container"></div>
      </section>

      <section className="Enterprise-container">
        <div className="Enterprise-boxes">
          <div className="Enterprise-left-dive">
            <h1 className="">Lalit Gupta</h1>
            <p className="about-text">
              <span className="about-text-span">
                Founder & CEO of MetaBlock
              </span>
              <p className="lalit-sir-about">
                My journey has been a combination of technological innovation
                and spiritual enlightenment, having started from modest
                beginnings in Hindaun City, Rajasthan. I developed a strong
                affinity for mathematics while growing up in a tiny town, which
                ultimately inspired me to pursue higher education in Jaipur.
                Even though I was never much of an academic, spirituality
                provided me with a place to quench my curiosity.&nbsp;
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/lalitgupta108/"
                >
                  <span>Read More...</span>
                </a>{" "}
              </p>
            </p>
          </div>
          <div className="Enterprise-right-dive">
            <img
              className="w-full h-72 sm:h-64 md:h-72  rounded-[50%] transition-transform duration-300 transform hover:scale-105 "
              style={{ height: "auto", width: "auto" }}
              src="./teams/lalitsir.webp"
              alt="Founder & CEO of MetaBlock"
            />
          </div>
        </div>
      </section>

      {/* Harsh sir image and text */}
      <section className="Enterprise-container px-4 sm:px-8 lg:px-16 py-8">
        <div className="Enterprise-boxes flex flex-col lg:flex-row items-center gap-8 lg:gap-12">
          <div className="Enterprise-right-dive ">
            <img
              className="w-48 h-48 sm:w-[15rem] sm:h-[20rem] sm:rounded-full  md:w-[25rem] md:h-[25rem] lg:w-[30rem] lg:h-[30rem] rounded-full transition-transform duration-300 transform hover:scale-105"
              src="./teams/harsh-sir.webp"
              alt="Co-Founder & CTO"
            />
          </div>
          <div className="Enterprise-left-dive text-center lg:text-left max-w-lg">
            <h1 className=" ">Harsh Gupta</h1>
            <p className="about-text mt-4 sm:mt-5 lg:mt-6">
              <span className="about-text-span block font-semibold text-lg sm:text-xl lg:text-2xl">
                Co-Founder & CTO
              </span>
              <p className="lalit-sir-about text-sm sm:text-base lg:text-lg mt-3 sm:mt-4">
                Blockchain Development || MERN Development || React Development
                || React Native Development || Website Development || Software
                Development &nbsp;
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/harsh-gupta-a9257b28b/"
                  className="text-blue-500 hover:underline"
                >
                  Read More...
                </a>
              </p>
            </p>
          </div>
        </div>
      </section>

      <section className="breadcrumb_section breadcrumb_padd">
        <div className="container">
          <h1> Team Member</h1>
        </div>
      </section>

      <div className="flex flex-col lg:flex-row items-center justify-center gap-14 pt-32 pb-32 w-full sm:w-[100px] sm:max-w-[300px] md:w-[502px] mx-auto ">
        <div
          className=" flex rounded-2xl border"
          style={{
            boxShadow: "1px 1px 20px white",
          }}
        >
          <div
            className="max-w-sm  mx-auto rounded-lg shadow-lg p-2 text-center w-[200px]  hidden md:block "
            style={{
              filter: "drop-shadow(1px 1px 20px white)",
            }}
          >
            <h2 className="text-2xl text-center md:text-3xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
              Akshita Agrawal
            </h2>
            <p className="text-lg md:text-xl font-medium bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent mt-2">
              Chief Digital Marketing Officer
            </p>
            <p
              style={{ fontSize: "13px" }}
              className=" md:text-base text-white mt-4 "
            >
              The Chief Digital Marketing Officer (CDMO) oversees digital
              marketing strategies to boost online presence and drive business
              growth. They focus on customer engagement and data-driven
              campaigns.
            </p>
          </div>
          <div className="img-wrapper rounded-2xl md:rounded-r-2xl ">
            <img
              src="./teams/Akshitamam.jpg"
              alt="Atul Prajapati"
              className="sm:rounded-2xl"
            />

            <h2 className="rounded-b-2xl">
              Akshita Agrawal
              <p>Chief Digital Marketing Officer</p>
            </h2>

            <ul>
              <li>
                <a href="https://wa.me/+919251925816">
                  <i className="fab fa-whatsapp" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/yourinstagramusername/">
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/yourfacebookusername/">
                  <i className="fab fa-facebook" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* 2nd member */}
        <div className="flex rounded-2xl">
          <div
            className=" flex  rounded-2xl border "
            style={{
              boxShadow: "1px 1px 20px white",
            }}
          >
            <div className="img-wrapper rounded-2xl md:rounded-r-2xl ">
              <img
                src="./teams/ajayyogi.webp"
                alt="Atul Prajapati"
                className="sm:rounded-2xl"
              />

              <h2 className="rounded-b-2xl">
                Ajay Yogi
                <p>Chief Marketing Officer</p>
              </h2>

              <ul>
                <li>
                  <a href="https://wa.me/+919358593003">
                    <i className="fab fa-whatsapp" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/yourinstagramusername/">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/yourfacebookusername/">
                    <i className="fab fa-facebook" />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="max-w-sm mx-auto rounded-lg shadow-lg p-2 text-center  w-[200px]  hidden md:block"
              style={{
                filter: "drop-shadow(1px 1px 20px white)",
              }}
            >
              <h2 className="text-2xl text-center md:text-3xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                Ajay Yogi
              </h2>
              <p className="text-lg md:text-xl font-medium bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent mt-2">
                Chief Marketing Officer
              </p>
              <p
                style={{ fontSize: "13px" }}
                className="text-sm md:text-base text-white mt-4"
              >
                The Chief Marketing Officer (CMO) leads marketing strategies to
                enhance brand presence and drive business growth. They focus on
                customer engagement and market innovation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center gap-14 pt-25 pb-32 w-full sm:w-[100px] sm:max-w-[300px] md:w-[502px] mx-auto ">
        <div
          className=" flex rounded-2xl border"
          style={{
            boxShadow: "1px 1px 20px white",
          }}
        >
          <div
            className="max-w-sm mx-auto rounded-lg shadow-lg p-2 text-center w-[200px]  hidden md:block"
            style={{
              filter: "drop-shadow(1px 1px 20px white)",
            }}
          >
            <h2 className="text-2xl text-center md:text-3xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
              Nagendra
            </h2>
            <p className="text-lg md:text-xl font-medium bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent mt-2">
              Head Of Metablock-BUsiness Program
            </p>
            <p
              style={{ fontSize: "13px" }}
              className=" md:text-base text-white mt-4 "
            >
              Leading Metablock’s business program, building partnerships,
              growing projects, and driving solutions for better results.
            </p>
          </div>
          <div className="img-wrapper rounded-2xl md:rounded-r-2xl ">
            <img
              src="./teams/nagendra.webp"
              alt="Atul Prajapati"
              className="sm:rounded-2xl"
            />

            <h2 className="rounded-b-2xl">
              Nagendra
              <p>Head Of Metablock-BUsiness Program</p>
            </h2>

            <ul>
              <li>
                <a href="https://wa.me/+919251971523">
                  <i className="fab fa-whatsapp" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/yourinstagramusername/">
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/yourfacebookusername/">
                  <i className="fab fa-facebook" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* 2nd member */}
        <div className="rounded-2xl">
          <div
            className=" flex  sm:rounded-2xl border"
            style={{
              boxShadow: "1px 1px 20px white",
            }}
          >
            <div className="img-wrapper rounded-r-2xl sm:rounded-2xl">
              <img
                src="./teams/sanjay.webp"
                alt="Atul Prajapati"
                className="sm:rounded-2xl "
              />

              <h2 className="sm:rounded-b-2xl">
                Sanjay
                <p>Head Of Metablock-Partner Program</p>
              </h2>

              <ul>
                <li>
                  <a href="https://wa.me/+919251925818">
                    <i className="fab fa-whatsapp" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/yourinstagramusername/">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/yourfacebookusername/">
                    <i className="fab fa-facebook" />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="max-w-sm mx-auto rounded-lg shadow-lg p-2 text-center  w-[200px]  hidden md:block"
              style={{
                filter: "drop-shadow(1px 1px 20px white)",
              }}
            >
              <h2 className="text-2xl text-center md:text-3xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                Sanjay
              </h2>
              <p className="text-lg md:text-xl font-medium bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent mt-2">
                Head Of Metablock-Partner Program
              </p>
              <p
                style={{ fontSize: "13px" }}
                className="text-sm md:text-base text-white mt-4"
              >
                Managing Metablock’s partner program, building strong
                relationships, growing partnerships, and
                ensuring shared success.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* our Team Members */}

      <div className="container-ourteam">
        <div className="row">
          {ourteam?.length > 0 ? (
            ourteam?.map((item, index) => (
              <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
                <div className="our-team ">
                  <div className="picture">
                    <img
                      style={{ height: "23vh" }}
                      className="img-fluid mb-md-5 "
                      src={`https://maincompanybackend.metablocktechnologies.org/team/images/${item.image}`}
                      alt={item.name}
                    />
                  </div>
                  <div className="team-content">
                    <h3 className="name">{item.name}</h3>
                    <h6 className="xle-ourteam">{item.position}</h6>
                  </div>
                  <ul className="social">
                    <h5>{item.experience}</h5>
                  </ul>
                </div>
              </div>
            ))
          ) : (
            <p>Loading team members...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ourteam;
