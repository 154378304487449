import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import AccountingSwiperSlider from "./SwiperSlider/AccountingSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import OurStepByStep from "../../../components/OurStepByStep";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
import SeoHelmet from "../../../Helmet";
import OurProjects from "../../OurProjects";
import projectData from "../../projectData";
import FAQSection2 from "../../FAQSection2";
import OurProcedureNew from "../../OurProcedureNew";
// import '../../main.css'

const AccountBilling = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Professional Accounting & Billing Software Development Services"
          description=" Professional Accounting & Billing Software Development Services - smooth your business operations with custom solutions style for accuracy, planning, and growth."
          keywords="Accounting & Billing Software Development"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      Accounting And Billing Software Development Company
                    </h1>
                    <p className="heading-tagline">
                      MetaBlock Technologies specializes in providing innovative
                      account & billing software solutions to make finance
                      management hassle-free. Our easy-to-use platforms help
                      increase the efficiency, accuracy, and scalability of
                      small and medium enterprises. With technology at its peak
                      stage supported by the dedicated team, we empower
                      organizations in their finance management through enhanced
                      ease, which saves time and builds revenue streams. Shield
                      yourself with proper billing transformation through us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Speciality In Account & Billing Software{" "}
                </h2>
                <p style={{ fontSize: "20px" }}>
                  Account and billing software specializes in efficient
                  financial control, accurate invoices, and smooth and timely
                  payment. These solutions offer specialized extreme features
                  such as automated billing, expense tracking, and reporting,
                  minimizing errors and saving time. They are tailored to
                  organizations looking to boost productivity and compliance and
                  enable simplification of otherwise complicated accounting
                  tasks through easy-to-use tools.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/accountsoft.webp" alt="accountsoft" />
              </div>
            </div>
          </section>

          <AccountingSwiperSlider />
          <KeyFeatures
            heading=" Key Features Of Account & Billing Software "
            description=" "
            filterKey="accounting"
          />
          <TechnologyStack />

          <Industries
            category="accounting"
            heading="Industries That Use Our Software Solutions"
          />

          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          <OurStepByStep />
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>
          <section>
            <OurProjects
              projectData={projectData}
              heading="Our Projects: A Showcase of Impactful Solutions"
              description="View different categories that depict the most advanced services provided by our company. Based on dynamic web development, mobile applications, gaming, and blockchain, these projects represent the best samples of our work. No matter if you are seeking a game, NFT, Metaverse experience, or enterprise software, our engaged team is involved in turning your ideas into reality using cutting-edge technologies. Check out the portfolio page of MetaBlock see some of the successful projects and learn how ideas are brought to life."
              filterKey="accounting"
            />
          </section>
          <section>
            <SimilarFile />
          </section>
          <FAQSection2 category="accounting"/>
          <OurProcedureNew category="inventory" />
        </div>
      </div>
    </>
  );
};

export default AccountBilling;
