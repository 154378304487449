import React from "react";
import "./GeneralGamesDevelopment.css";
import AutoPlay from "../../MainPage/ReactSlick";
import AutoPlaygame from "./Gameslider";
import { useNavigate } from "react-router-dom";
import GameDemandSlider from "./GameDemandSlider";
import WhyChooseUs from "../../MainPage/WhyChooseUs";
import SeoHelmet from "../../../Helmet";
import FAQSection2 from "../../FAQSection2";
const GeneralGamesDevelopment = () => {
  const encodedText = encodeURIComponent(
    "Hello, I am Interested in Game Development"
  );

  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Game Development Company: Transforming Ideas into Reality "
          description="MetaBlock is a top game development company that turns your ideas into innovative, high-quality, multi-platform games."
          keywords=""
        />

        <main id="game_dev_comp_main">
          <section className="game_dev_comp_banner">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 col-12">
                  <div className="banner_sec">
                    <h1 className="ourservice-titles-new">
                      <span className="bnr_clr_title">
                        BEST GAME DEVELOPMENT
                      </span>
                      <br /> COMPANY
                    </h1>
                    <p className="contents">
                      <br /> The best game development company is innovative,
                      and creates games with value, using great design, new
                      technologies, and an easy-to-use interface.
                    </p>
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=9358593003&text=${encodedText}`}
                    >
                      <div
                        className="HddrBtns banner_btn_sec"
                        // onClick={() => navigate("/contact-us")}
                      >
                        <button
                          data-toggle="modal"
                          data-target="#popup_form_modal"
                          className="btn banner_btn"
                        >
                          <span>Tap for a Quotation</span>
                        </button>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="game_dev_comp_services">
            <div className="container">
              <h2 className="title ">Game Development Solution we Offer</h2>
              <div className="nft-marketplace-usecase slider">
                <AutoPlaygame />
              </div>
            </div>
          </section>
          <section className="game_dev_comp_platform">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-5 p-lg-0">
                  <img
                    style={{ width: "80%" }}
                    src="images/gaming-web-image.webp"
                    className="img-fluid lazyload"
                    alt="Game_Development_on_Prime_Platforms"
                    title="Our Game Development on Prime Platforms"
                  />
                </div>
                <div className="col-lg-7 cus_col">
                  <h2 className="title">
                    Our Game Development on Major Platforms
                  </h2>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="platform_box-inner">
                        <div className="head_sec">
                          <img
                            src="./images/vrgoogles.webp"
                            className="sprite_icons lazyload"
                            alt="AR_VR_Icon"
                            title="AR and VR"
                          />
                          <h3 className="sub_title">AR and VR</h3>
                        </div>
                        <p className="sub_contents">
                          Our selections incorporate the finest AR and VR
                          advancements to provide you with groundbreaking,
                          smoothly operated, exceedingly interesting gaming
                          experiences.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="platform_box-inner">
                        <div className="head_sec">
                          <img
                            src="./images/game-console.webp"
                            className="sprite_icons lazyload"
                            alt="Console_Icon"
                            title="Console"
                          />
                          <h3 className="sub_title">Console</h3>
                        </div>
                        <p className="sub_contents">
                          We have a niche in creating games for the primary
                          gaming platforms such as PlayStation 4 and consoles
                          from Nintendo. Thus, we are aiming to provide
                          qualitative and qualitative experiences that will fit
                          the peculiarities of each console.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="platform_box-inner">
                        <div className="head_sec">
                          <img
                            src="./images/gaming.webp"
                            className="sprite_icons lazyload"
                            alt="Mobile_Icon"
                            title="Mobile"
                          />
                          <h3 className="sub_title">Mobile</h3>
                        </div>
                        <p className="sub_contents">
                          Our skilled game and mobile app developers use the
                          latest technologies and game engines like Unity and
                          Unreal Engine to create immersive, cross-platform
                          games for Android and iOS. Each game undergoes
                          rigorous quality assurance to ensure smooth
                          performance and accessibility.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="platform_box-inner">
                        <div className="head_sec">
                          <img
                            src="./images/computer-game.webp"
                            className="sprite_icons lazyload"
                            alt="PC_Icon"
                            title="PC"
                          />
                          <h3 className="sub_title">PC</h3>
                        </div>
                        <p className="sub_contents">
                          Our services include full-scale game development on PC
                          platforms as well as the option to port a game onto
                          another platform.
                        </p>
                      </div>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=9358593003&text=${encodedText}`}
                  >
                    <div
                      className="game_dev_btn_sec"
                      // onClick={() => navigate("/contact-us")}
                    >
                      <button
                        data-toggle="modal"
                        data-target="#popup_form_modal"
                        className="btn game_dev_btn"
                      >
                        <span>Get A Live Demo</span>
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="game_dev_comp_techstack">
            <div className="container">
              <h2 className="title">INNOVATIVE TECHNOLOGIES WE USE</h2>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="tech_box">
                    <h3 className="sub_title">Unreal Engine</h3>
                    <p className="sub_contents">
                      Unreal Engine is an effective game development tool that
                      provides a superior rendering system, strong physics, and
                      intelligence. It allows the designing of a visually
                      appealing experience for various devices with real-time
                      functionality.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="tech_box">
                    <h3 className="sub_title">Unity</h3>
                    <p className="sub_contents">
                      -Unity is a widely used game engine that enables designers
                      and engineers to model, develop, and deploy 2D and 3D
                      games and Virtual Reality simulations. They enable you to
                      enjoy a flexible development platform with effective
                      scripting support for your game development.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="tech_box">
                    <h3 className="sub_title">Node</h3>
                    <p className="sub_contents">
                      Node is a flexible developmental tool for games that
                      includes features such as dynamic physics, high-quality
                      graphic display, and artificial intelligence enhancements.
                      These capabilities enable our developers to deliver an
                      exciting, graphic-rich game experience.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="tech_box">
                    <h3 className="sub_title">Java</h3>
                    <p className="sub_contents">
                      Java is a widely used and versatile programming language
                      known for its efficiency, portability, and low-level
                      control. It is ideal for developing a variety of software
                      solutions and applications across different platforms.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="tech_box">
                    <h3 className="sub_title">C++</h3>
                    <p className="sub_contents">
                      Is a refined computer language that Conjures with C;
                      however, it supports features of object-oriented
                      programming paradigms. It permits the extension of methods
                      of doing application development in modularity and high
                      performance across the different domains of use.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="tech_box">
                    <h3 className="sub_title">React</h3>
                    <p className="sub_contents">
                      React is a current language whose implementation is based
                      on Object-Oriented Programming and was developed by
                      Microsoft for the creation of applications. With the
                      support of different development requirements, including
                      the development of desktop or WEB applications and games,
                      it provides more flexibility and efficiency.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="tech_box">
                    <h3 className="sub_title">Swift</h3>
                    <p className="sub_contents">
                      Swift is a post-JavaScript framework so it’s an
                      object-oriented language that was developed by Microsoft
                      and is mostly used to create apps. It can accommodate
                      various kinds of development requirements, such as
                      desktop, web, and game application development, which on
                      top of it, makes the process productive and versatile.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="tech_box">
                    <h3 className="sub_title">HTML</h3>
                    <p className="sub_contents">
                      HTML or Hypertext Markup Language is the general language
                      used for creating content to be located on the World Wide
                      Web. It employs tags to describe content in the form of
                      elements and sections of a web page and placement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="game_dev_comp_quant">
            <div className="container">
              <h2 className="title">Quantifying Our Journey</h2>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="quant_list">
                    <img
                      src="./images/rating.webp"
                      className="sprite_icons lazyload"
                      alt="250+_Innovators_Crafting_Games"
                      title="250+ Innovators Crafting Games"
                      
                    />
                    <div className="sub_title">
                      250+ Innovators Crafting Games
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="quant_list">
                    <img
                      src="./images/handshake.webp"
                      className="sprite_icons lazyload"
                      alt="400+_Projects_Set_in_Motion"
                      title="400+ Projects Set in Motion"
                     
                    />
                    <div className="sub_title">400+ Projects Set in Motion</div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="quant_list">
                    <img
                      src="./images/coordinator.webp"
                      className="sprite_icons lazyload"
                      alt="15+_Years_of_Gaming_Mastery"
                      title="15+ Years of Gaming Mastery"
                      
                    />
                    <div className="sub_title">15+ Years of Gaming Mastery</div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="quant_list">
                    <img
                      src="./images/enjoy.webp"
                      className="sprite_icons lazyload"
                      alt="75+_Countries_Immersed_in_Fun"
                      title="75+ Countries Immersed in Fun"
                      
                    />
                    <div className="sub_title">
                      75+ Countries Immersed in Fun
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <GameDemandSlider />
          <section className="game_dev_comp_production">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-12 order-2 order-lg-1">
                  <div className="img_sec">
                    <img
                      style={{ borderRadius: "10px", width: "200%" }}
                      src="./images/stake-img.webp"
                      className="img-fluid lazyload"
                      alt="MetaBlock_Your_go_to_game_development_company"
                      title="MetaBlock Your go-to game development company.Find out why"
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-12 order-1 order-lg-2">
                  <h2 className="title">
                  Game Development Process: From Start to Finish
                  </h2>
                  <div className="why_scrollsec">
                    <div className="why_box">
                      <div className="head_sec">
                        <img
                          style={{ width: "40px" }}
                          src="./images/blueprint.webp"
                          className="img-fluid lazyload"
                          alt="Game_Production"
                          title="Game Production"
                        />
                        <h3 className="sub_title">Information Collation</h3>
                      </div>
                      <p className="sub_contents">
                      Being an independent game design company, we focus on the unique requirements of each project. By utilizing the best game-making software, such as Unity, Unreal Engine, and other industry-leading tools, we ensure precision and creativity in every game. We also guarantee proper input checks through regular reporting and detailed documentation.  
                      </p>
                    </div>
                    <div className="why_box">
                      <div className="head_sec">
                        <img
                          style={{ width: "40px" }}
                          src="./images/artist.webp"
                          className="img-fluid lazyload"
                          alt="Game_Production"
                          title="Game Production"
                        />
                        <h3 className="sub_title">Blueprint</h3>
                      </div>
                      <p className="sub_contents">
                      In the blueprinting phase, detailed concepts and research are made and architectures are based on client feedback to construct game design documents and implementation strategies. Stakeholder meetings also help clarify all the options before the pre-production and production phases.

                      </p>
                    </div>
                    <div className="why_box">
                      <div className="head_sec">
                        <img
                          style={{ width: "40px" }}
                          src="./images/contact.webp"
                          className="img-fluid lazyload"
                          alt="Game_Production"
                          title="Game Production"
                        />
                        <h3 className="sub_title">
                        Imagination Depiction and Asset Creation
                        </h3>
                      </div>
                      <p className="sub_contents">
                      Concept artists and 2D/3D modelers on our team help to develop the game vision by making concept art and designing characters, props, and locations. Talented artists and animators work to ensure that such assets are integrated well into the final piece of the artwork.

                      </p>
                    </div>
                    <div className="why_box">
                      <div className="head_sec">
                        <img
                          style={{ width: "40px" }}
                          src="./images/game-development.webp"
                          className="img-fluid lazyload"
                          alt="Game_Production"
                          title="Game Production"
                        />
                        <h3 className="sub_title">
                          Game Design and Intensive Quality Testing
                        </h3>
                      </div>
                      <p className="sub_contents">
                      The kind of development team we have comprises mostly technical personnel with exceptional skills in both the latest technologies and the best game engines. Our game design process is driven by creativity and innovation, ensuring each game is visually engaging and immersive. 

                      </p>
                    </div>
                    <div className="why_box">
                      <div className="head_sec">
                        <img
                          style={{ width: "40px" }}
                          src="./images/gaming.webp"
                          className="img-fluid lazyload"
                          alt="Game_Production"
                          title="Game Production"
                        />
                        <h3 className="sub_title">Help &amp; Upkeep</h3>
                      </div>
                      <p className="sub_contents">
                      Through the MetaBlock launch, it also focuses on maintaining the games regularly and providing technical help after the game’s release to make certain that games are constantly updated on different platforms. For further details on the packages that we offer and the type of services that entails as well as the overriding SLAs, please visit our website with information basic on industry standards for the gaming sector.

                      </p>
                    </div>
                    <div className="why_box">
                      <div className="head_sec">
                        <img
                          style={{ width: "40px" }}
                          src="./images/computer-game.webp"
                          className="img-fluid lazyload"
                          alt="Game_Production"
                          title="Game Production"
                        />
                        <h3 className="sub_title">
                          {" "}
                          Game Longevity and Revenue Strategies
                        </h3>
                      </div>
                      <p className="sub_contents">
                      -We increase the number of players using the game and their involvement due to exclusive in-game occurrences and changes in the economy. Regarding sustainable revenue generation, our services also consist of linking IAPs and advertising techniques.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <WhyChooseUs />
          <section className="game_dev_comp_why">
            <div className="container">
              <div className="why_img_sec">
                <img
                  style={{ width: "30px" }}
                  src="./images/contact.webp"
                  className="img-fluid lazyload"
                  alt="MetaBlock_Your_go_to_game_development_company"
                  title="MetaBlock Your go-to game development company.Find out  why"
                />
              </div>
              <div className="row align-items-center">
                <div className="col-lg-3 col-12">
                  <h2 className="title">
                    <span style={{ color: "#FFA024" }}>MetaBlock</span>
                    <br /> Your Trusted Game Development  <br /> Partner.
                    
                  </h2>
                  <p className="contents">
                  MetaBlock is your game development company that aims to develop games that will provoke customers’ interest and help brands expand their audiences. People are engaged because we love gaming for a cause and let it create a difference. If you decide to work with us you will be surprised by the changes we can make to your project.

                  </p>
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=9358593003&text=${encodedText}`}
                  >
                    <div className="game_dev_btn_sec">
                      <button
                        data-toggle="modal"
                        data-target="#popup_form_modal"
                        className="btn game_dev_btn"
                      >
                        <span>Tap for a Quotation</span>
                      </button>
                    </div>
                  </a>
                </div>
                <div className="col-lg-5 col-12">
                  <div className="img_sec">
                    <img
                      // style={{ paddingLeft: "150px" }}
                      src="./images/3dimage-in.webp"
                      className="lazyload go-to-gamedevelopment"
                      alt="MetaBlock_Your_go_to_game_development_company"
                      title="MetaBlock Your go-to game development company.Find out why"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="why_scrollsec">
                    <div className="why_box">
                      <h3 className="sub_title">FLEX MODE </h3>
                      <p className="sub_contents">
                      The Flex Method is an agile, iterative development approach that focuses on delivering functional features at each stage through a series of sprints. By breaking the development process into manageable phases, we ensure continuous improvement, adaptability, and the highest quality delivery, making sure every feature meets the project’s objectives efficiently.

                      </p>
                    </div>
                    <div className="why_box">
                      <h3 className="sub_title">Passionate Game Creators</h3>
                      <p className="sub_contents">
                      The more than 350-strong veteran staff at our company develops fantastic games through integrating concepts, graphics, and sound. Our goal is to cover both the corporate and the artistic side of video games, keeping up with markets and inventions. We love gaming and provide the best gaming experience we can give.

                      </p>
                    </div>
                    <div className="why_box">
                      <h3 className="sub_title">Eclectic Portfolio</h3>
                      <p className="sub_contents">
                      Our game collection currently contains over 400 interesting pieces of varied types Windows and Web, iOS, AR/VR, and Android-based. There is something for every type of player with genres including sports, action, role-playing games, arcade, MMORPG, and casual.

                      </p>
                    </div>
                    <div className="why_box">
                      <h3 className="sub_title">
                        Different Engagement Formats
                      </h3>
                      <p className="sub_contents">
                      We provide options for game production such as resource-based, fixed cost, and time and material. Select the best option for your project taking into consideration the cost of the services.

                      </p>
                    </div>
                    <div className="why_box">
                      <h3 className="sub_title">Agile Connection</h3>
                      <p className="sub_contents">
                      -It is, therefore, important to understand that at MetaBlock, we address this issue, by adopting strategies and channels of communication that are unique to the organization’s requirements for effective communication. Schedule-wise and preference-wise, we make sure that you can get a hold of us through calls, e-mail or even through instant chatting depending on your availability and convenience till the project is done.

                      </p>
                    </div>
                    <div className="why_box">
                      <h3 className="sub_title">Ease of Access</h3>
                      <p className="sub_contents">
                      As we have established ourselves as a major game brand, we have connected with various small and medium enterprises, businessmen/women, and governments all over the world. We have been successful in our journey, which has enabled us to partner with various industries globally.

                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
              
            </div>
            
          </section>
          <FAQSection2 category="game"/>
        </main>
      </div>
    </>
  );
};

export default GeneralGamesDevelopment;
