import React, { useState } from "react";
import adminControlData from "./adminControlData"; // Adjust the path as necessary
import "./AdminControlFeature.css"; // Make sure this path is correct

const truncateDescription = (description) => {
  return description.length > 100 ? description.substring(0, 100) + '...' : description;
};

const AdminControlFeatures = ({ category, heading }) => {
  const features = adminControlData[category] || [];
  const [expanded, setExpanded] = useState(null);

  const toggleContent = (id) => {
    setExpanded(expanded === id ? null : id); // Toggle the expanded state for the clicked card
  };

  return (
    <div className="m-0">
      <div className="Admin-control-main-container">
        <h2>{heading}</h2>
        <div className="Admin-control-inner-container">
          {features.map((feature) => (
            <div key={feature.id} className="a-card card-discover admin-control-card">
              <div className="card-icon-title-wrapper">
                <div className="card__icon pb-1">
                  <div className="icon__wrapper">
                    <img src={feature.image} alt={feature.title} />
                  </div>
                </div>
                <h3 className="card__title card__title-hover fs-4 g-text">{feature.title}</h3>
              </div>
              <div className="card__description-wrapper">
                <p className={`card__description ${expanded === feature.id ? 'expanded' : ''}`}>
                  {expanded === feature.id ? feature.description : truncateDescription(feature.description)}
                </p>
                {feature.description.length > 150 && (
                  <button className="read-more-btn" onClick={() => toggleContent(feature.id)}>
                    {expanded === feature.id ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminControlFeatures;