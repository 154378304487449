import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import RealstateSwiperSlider from "./SwiperSlider/RealstateSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const RealstateSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Real State Software Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    Real Estate Software offers comprehensive solutions for
                    property management, listings, and client relationship
                    management. With features like property analytics and
                    marketing tools, it empowers real estate professionals to
                    streamline transactions, enhance customer experiences, and
                    drive business growth in dynamic markets.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in real estate software, our expertise lies in
                providing tailored solutions to meet the diverse needs of real
                estate professionals and agencies. Our software is meticulously
                designed to streamline various aspects of property management,
                including listing management, lead generation, client
                communication, and transaction tracking. With our expertise,
                real estate professionals can efficiently manage their listings,
                attract potential buyers or tenants, and close deals seamlessly.
                Whether it's a small independent agency or a large brokerage,
                our customizable software ensures smooth operations and enhanced
                productivity, empowering our clients to succeed in the
                competitive real estate market.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/realstatedev.webp" alt="realstatedev" />
            </div>
          </div>
        </section>

        <RealstateSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
      <CardSlider/>
    </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
      <Phonecardslider />
    </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default RealstateSoftware;
