import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";

import TrevellingSwiperSlider from "./ApplicationSwiperSlider/TrevellingSwiperSlider";
import SeoHelmet from "../../../Helmet";
import TechnologyStack from "../../../components/TechnologyStack";
import faqData from "../../faqData";
import FAQSection2 from "../../FAQSection2";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

const TravellingApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Talk To The Best Travel App Development Company"
          description="Metablock Technologies is the best leading travel app development company with a rich experience in developing best travel app development solutions."
          keywords="Travel App Development Company"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      {" "}
                      |Metablock Technologies| Best Travel App Development
                      company
                    </h1>
                    <p className="heading-tagline">
                      It is the best Travel app development company in jaipur,
                      making easy trip planning and navigation. by offering
                      tools for booking flights, accommodations, and activities.
                      They improve the travel experience with features like
                      maps, user reviews, and itinerary management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Experts Travel App Developers
                </h2>
                <p style={{ fontSize: "20px" }}>
                  MetaBlock Technologies make easy travel with travel booking
                  apps. Designed by expert travel app developers. It helps you
                  plan, book, and navigate your trips easily. It provides travel
                  app development services like virtual tours, discovering local
                  spots, and real-time updates on events and attractions.
                  Whether you're traveling alone or with family, our app makes
                  unforgettable experiences. Say goodbye to travel stress and
                  hello to endless possibilities. Start your journey with
                  MetaBlock Technologies today!
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
              </div>
            </div>
          </section>

          <TrevellingSwiperSlider />
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>
          <OurProjects
            projectData={projectData}
            heading="Explore our impressive portfolio showcase."
            description=" We offer a range of services including web development, Flutter app development, game development, software development, mobile app development and blockchain development."
            filterKey="mobileapp" // Display only software projects
          />

          <section>
            <SimilarFile />
          </section>
        </div>
        <FAQSection2 category="travelapp"/>
      </div>
    </>
  );
};

export default TravellingApps;
