import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import MatrimonialSwiperSlider from "./SwiperSlider/MatrimonialSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const MatrimonialSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Matrimonial Software Development Company
                  </h1>
                  <h3 className="heading-tagline">
                    Matrimonial Software facilitates the search for life
                    partners by providing customizable platforms for matchmaking
                    and matrimonial services. With robust features such as
                    profile matching algorithms and secure communication
                    channels, it enhances the efficiency and reliability of the
                    matchmaking process, fostering meaningful connections and
                    successful unions.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>


        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                As specialists in matrimonial software, our focus is on
                providing tailored solutions to help individuals and families
                navigate the complex process of finding compatible life
                partners. Our software is meticulously designed to offer
                intuitive interfaces, advanced search capabilities, and robust
                privacy features. With our expertise, users can create detailed
                profiles, search for matches based on specific criteria, and
                communicate securely with potential partners. Whether it's for
                traditional arranged marriages or modern matchmaking, our
                matrimonial software is optimized for efficiency, reliability,
                and confidentiality. We are committed to facilitating meaningful
                connections, fostering lasting relationships, and helping our
                clients find their perfect match.{" "}
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">

              <img src="./images/matrimoniyadev.webp" alt="matrimoniyadev" />
            </div>
          </div>
        </section>

        <MatrimonialSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        {/* <section >
      <CardSlider/>
    </section> */}
        {/* <RelatedPortfolioSlider/> */}
        {/* <OnDemandAppSlider/> */}

        {/* <section className='Phone-card-slider'>
      <Phonecardslider />
    </section> */}


        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
};

export default MatrimonialSoftware;
