// import Card from "./MainPage/Card";
// import RelatedPortfolioSlider from "./MainPage/RelatedPortfolioSlider";
// import AutoPlay from "./MainPage/ReactSlick";
// import GenerativeAl from "./MainPage/GenerativeAl";
// import React from "react";
// import WhyChooseUs from "./MainPage/WhyChooseUs";
// import TrandingProduct from "./MainPage/TrandingProduct";
// import { Link } from "react-router-dom";
// import PlayTOEarnPortfoliio from "./MainPage/PlayToEarnPorfolio";
// import MetaversePortfolio from "./MainPage/MetaversePorfolio";
// import BlockchainPorfolio from "./MainPage/BlockchainPorfolio";
// import ExchangePorfolio from "./MainPage/ExchangePorfolio";
// import NFTPorfolio from "./MainPage/NFTPorfolio";
// import DefiPorfolio from "./MainPage/DefiPorfolio";
// import DAppPorfolio from "./MainPage/DAppPorfolio";
// import BrandsWork from "./MainPage/BrandsWork";
// import Ourprocesure from "./MainPage/Ourprocesure";
// import BenefitsColabratingSlider from "./MainPage/BenefitsOfColabratingSlider";

// const SimilarFile = () => {
//   return (
//     <>
//       <div className="responsive-in-black-bg">
//         <div
//           className="MtaverseSec DapsMainBg OurPrjctBg"
//           style={{ background: "black" }}
//         >
//           <div>
//             <WhyChooseUs />
//           </div>

          // {/* Our Projects: A Showcase of Impactful Solutions  */}
          // <div className="browse-our-impactful MtaverseSec DapsMainBg OurPrjctBg">
          //   <div className="container container-1170">
          //     <div className="row">
          //       <div className=" col-xl-10">
          //         <div
          //           style={{
          //             display: "flex",
          //             justifyContent: "center",
          //             alignItems: "center",
          //           }}
          //           className="OurSrvcHdd mb-3"
          //         >
          //           <h2
          //             className="title our-service-heading"
          //             style={{
          //               color: "purple",
          //               margin: "0px 0px 0px 0px",
          //               width: "100%",
          //             }}
          //           >
          //             Our Projects: A Showcase of Impactful Solutions
          //           </h2>
          //         </div>
          //         <div
          //           className="Browse-impactful BnrCnt OurservHdd mb-4 OurPrjctHddSec"
          //           style={{ textAlign: "center" }}
          //         >
          //           <p className="text-left">
          //             View different categories that depict the most advanced
          //             services provided by our company.
          //           </p>
          //         </div>
          //       </div>
          //     </div>
          //     <div className="row">
          //       <div className="Portfolio-buttons col-lg-2">
          //         <div
          //           className="nav flex-column nav-pills VrtclTbb PrjctTb"
          //           id="v-pills-tab"
          //           role="tablist"
          //           aria-orientation="vertical"
          //         >
          //           <button
          //             className="nav-link active"
          //             data-toggle="pill"
          //             data-target="#PrjctTbb7"
          //             type="button"
          //             role="tab"
          //             aria-controls="PrjctTbb7"
          //             aria-selected="false"
          //           >
          //             <h3 style={{ fontSize: "1.2rem" }}>Software </h3>
          //           </button>
          //           <button
          //             className="nav-link"
          //             data-toggle="pill"
          //             data-target="#PrjctTbb3"
          //             type="button"
          //             role="tab"
          //             aria-controls="PrjctTbb3"
          //             aria-selected="false"
          //           >
          //             <h3 style={{ fontSize: "1.2rem" }}>UX/UI </h3>
          //           </button>
          //           <button
          //             className="nav-link"
          //             data-toggle="pill"
          //             data-target="#PrjctTbb4"
          //             type="button"
          //             role="tab"
          //             aria-controls="PrjctTbb4"
          //             aria-selected="false"
          //           >
          //             <h3 style={{ fontSize: "1.2rem" }}>Cybersecurity</h3>
          //           </button>
          //           <button
          //             className="nav-link"
          //             data-toggle="pill"
          //             data-target="#PrjctTbb9"
          //             type="button"
          //             role="tab"
          //             aria-controls="PrjctTbb9"
          //             aria-selected="false"
          //           >
          //             <h3 style={{ fontSize: "1.2rem" }}>CI/CD</h3>
          //           </button>
          //           <button
          //             className="nav-link"
          //             data-toggle="pill"
          //             data-target="#PrjctTbb1"
          //             type="button"
          //             role="tab"
          //             aria-controls="PrjctTbb1"
          //             aria-selected="false"
          //           >
          //             <h3 style={{ fontSize: "1.2rem" }}>IoT App</h3>
          //           </button>

          //           <button
          //             className="nav-link"
          //             data-toggle="pill"
          //             data-target="#PrjctTbb5"
          //             type="button"
          //             role="tab"
          //             aria-controls="PrjctTbb5"
          //             aria-selected="false"
          //           >
          //             <h3 style={{ fontSize: "1.2rem" }}>Scalable</h3>
          //           </button>
          //           <button
          //             className="nav-link "
          //             data-toggle="pill"
          //             data-target="#PrjctTbb6"
          //             type="button"
          //             role="tab"
          //             aria-controls="PrjctTbb6"
          //             aria-selected="true"
          //           >
          //             <h3 style={{ fontSize: "1.2rem" }}>Testing & QA</h3>
          //           </button>

          //           <button
          //             className="nav-link"
          //             data-toggle="pill"
          //             data-target="#PrjctTbb10"
          //             type="button"
          //             role="tab"
          //             aria-controls="PrjctTbb10"
          //             aria-selected="false"
          //           >
          //             <h3 style={{ fontSize: "1.2rem" }}>Cloud Computing </h3>
          //           </button>
          //         </div>
          //       </div>
          //       <div className="col-xl-10">
          //         <div className="tab-content" id="v-pills-tabContent">
          //           <div
          //             className="tab-pane fade show active"
          //             id="PrjctTbb6"
          //             role="tabpanel"
          //           >
          //             <div>
          //               <div className="PrjctDtlsItm game-1">
          //                 <div className="row align-items-center">
          //                   <div className="col-lg-7">
          //                     <div className="PrjctDtlCnt">
          //                       <h3 className="port_sub_title">Testing & QA</h3>
          //                       <p>
          //                         Software testing and quality assurance are
          //                         essential to ensure that the product
          //                         functions, behaves, and performs as required.
          //                         It enhances user satisfaction and reduces
          //                         costs and system failure rates by discovering
          //                         bugs and issues early. Quality testing
          //                         guarantees great software delivery in terms of
          //                         user relations with the product and meeting
          //                         business objectives.
          //                       </p>
          //                     </div>
          //                   </div>
          //                   <div className="col-lg-5">
          //                     <div className="PrjctImg text-center">
          //                       <img
          //                         src="./SGimage/gamerimg.webp"
          //                         alt="Metaverse"
          //                         title="Metaverse"
          //                         loading="eager"
          //                         className="img-fluid lazyload"
          //                         width="70%"
          //                       />
          //                     </div>
          //                   </div>
          //                 </div>

          //                 <div className="PrjctsBtnSec game_PrjctBtn">
          //                   <div className="mr-4 mb-4">
          //                     <div className="PrjctBtn_new Btn211-42">
          //                       Skill Game
          //                     </div>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <div className="PrjctBtn_new Btn211-42">
          //                       TeenPati Game
          //                     </div>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <div className="PrjctBtn_new Btn211-42">
          //                       Ludo Game
          //                     </div>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <div className="PrjctBtn_new Btn211-42">
          //                       Fantasy Game
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec game_PrjctBtn">
          //                   <div className="mr-4 mb-4">
          //                     <div className="PrjctBtn_new Btn211-42">
          //                       Callbreak Game
          //                     </div>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <div className="PrjctBtn_new Btn211-42">
          //                       Rummy Game
          //                     </div>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="RltdPrtflo">
          //                 <div className="RltdPrjHddSec">
          //                   <div className="RltdPrjHdd">
          //                     <div className="h5_title">Related Portfolio</div>
          //                   </div>
          //                 </div>

          //                 <RelatedPortfolioSlider />
          //               </div>
          //             </div>
          //           </div>
          //           <div
          //             className="tab-pane fade"
          //             id="PrjctTbb1"
          //             role="tabpanel"
          //           >
          //             <div>
          //               <div className="PrjctDtlsItm meta-1">
          //                 <div className="row align-items-center">
          //                   <div className="col-lg-7">
          //                     <div className="PrjctDtlCnt">
          //                       <h3 className="port_sub_title">IoT App</h3>
          //                       <p>
          //                         Software development for Internet of Things
          //                         (IoT) applications involves creating solutions
          //                         that enable devices to connect, communicate,
          //                         and interact over the Internet. These
          //                         applications often involve hardware, software,
          //                         and cloud technologies for processing
          //                         real-time data, automating tasks, and
          //                         improving decision-making.
          //                       </p>
          //                     </div>
          //                   </div>
          //                   <div className="col-lg-5">
          //                     <div className="PrjctImg text-center">
          //                       <img
          //                         src="./SGimage/metaverse.webp"
          //                         alt="Metaverse"
          //                         title="Metaverse"
          //                         loading="eager"
          //                         className="img-fluid lazyload"
          //                         width="70%"
          //                       />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Metaverse_Game"
          //                     >
          //                       Smart Contract
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Metaverse_Casino_Game"
          //                     >
          //                       Private/Public Blockchain
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Metaverse_NFT_Marketplace"
          //                     >
          //                       Blockchain For Industries
          //                     </button>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Metaverse_Wallet"
          //                     >
          //                       POC Development
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Metaverse_Token"
          //                     >
          //                       Enterprise Blockchain
          //                     </button>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="RltdPrtflo">
          //                 <div className="RltdPrjHddSec">
          //                   <div className="RltdPrjHdd">
          //                     <div className="h5_title">Related Portfolio</div>
          //                   </div>
          //                 </div>

          //                 <PlayTOEarnPortfoliio />
          //               </div>
          //             </div>
          //           </div>
          //           <div
          //             className="tab-pane fade"
          //             id="PrjctTbb3"
          //             role="tabpanel"
          //           >
          //             <div>
          //               <div className="PrjctDtlsItm blkchain-1">
          //                 <div className="row align-items-center">
          //                   <div className="col-lg-7">
          //                     <div className="PrjctDtlCnt">
          //                       <h3 className="port_sub_title">UX/UI</h3>
          //                       <p>
          //                         User Experience (UX) and User Interface (UI)
          //                         design are pivotal in software engineering.
          //                         While the former revolves around achieving
          //                         customer delight, it ties elements such as the
          //                         system usability enhancement, accessibility to
          //                         it, and the interaction between the user with
          //                         the system. It, therefore, cuts across
          //                         research prototyping and testing to measure
          //                         the extent to which the system serves the
          //                         purposes set out for any given intended target
          //                         user. UI design is on the visual aspects of
          //                         the software, such as layout, color schemes,
          //                         typography, and any other elements for
          //                         interaction. A good UI should make the app
          //                         pleasant to look at and intuitive to use so
          //                         that it can guide users comfortably through
          //                         the app.
          //                       </p>
          //                     </div>
          //                   </div>
          //                   <div className="col-lg-5">
          //                     <div className="PrjctImg text-center">
          //                       <img
          //                         src="./SGimage/blockchainimg.webp"
          //                         alt="Blockchain"
          //                         title="Blockchain"
          //                         loading="eager"
          //                         className="img-fluid lazyload"
          //                       />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Smart_Contract_Development"
          //                     >
          //                       Decentralization
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Private/Public Blockchain"
          //                     >
          //                       Immutability
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Blockchain Industries"
          //                     >
          //                       Transparency
          //                     </button>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="POC Development"
          //                     >
          //                       Security
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Enterprise Blockchain Solutions"
          //                     >
          //                       Smart Contracts
          //                     </button>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="RltdPrtflo">
          //                 <div className="RltdPrjHddSec">
          //                   <div className="RltdPrjHdd">
          //                     <div className="h5_title">Related Portfolio</div>
          //                   </div>
          //                 </div>

          //                 <MetaversePortfolio />
          //               </div>
          //             </div>
          //           </div>
          //           <div
          //             className="tab-pane fade"
          //             id="PrjctTbb4"
          //             role="tabpanel"
          //           >
          //             <div>
          //               <div className="PrjctDtlsItm">
          //                 <div className="row align-items-center">
          //                   <div className="col-lg-7">
          //                     <div className="PrjctDtlCnt">
          //                       <h3 className="port_sub_title">
          //                         Cybersecurity
          //                       </h3>
          //                       <p>
          //                         Undoubtedly, the most vital aspect of software
          //                         development nowadays is cybersecurity itself.
          //                         It helps ensure that applications remain
          //                         protected against evolving dangers as well as
          //                         weaknesses. Cybersecurity involves embedding
          //                         strong security practices from the initiation
          //                         of software development into the SDLC through
          //                         its deployment and maintenance phases. Secure
          //                         coding techniques, regular vulnerability
          //                         assessments, and penetration testing create a
          //                         pipeline for the early identification and
          //                         mitigation of risks by developers.
          //                       </p>
          //                     </div>
          //                   </div>
          //                   <div className="col-lg-5">
          //                     <div className="PrjctImg">
          //                       <img
          //                         style={{ width: "200px" }}
          //                         src="./images/software-crypto.webp "
          //                         alt="Exchange"
          //                         title="Exchange"
          //                         loading="eager"
          //                       />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Crypto Exchange"
          //                     >
          //                       Software Development
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Crypto MLM"
          //                     >
          //                       HRMS Software
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Crypto HYIP"
          //                     >
          //                       SAAS Software
          //                     </button>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Cryptocurrency"
          //                     >
          //                       Matrimonial Software
          //                     </button>
          //                   </div>

          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Cryptocurrency"
          //                     >
          //                       Hotel Management
          //                     </button>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="RltdPrtflo">
          //                 <div className="RltdPrjHddSec">
          //                   <div className="RltdPrjHdd">
          //                     <div className="h5_title">Related Portfolio</div>
          //                   </div>
          //                 </div>

          //                 <BlockchainPorfolio />
          //               </div>
          //             </div>
          //           </div>
          //           <div
          //             className="tab-pane fade"
          //             id="PrjctTbb5"
          //             role="tabpanel"
          //           >
          //             <div>
          //               <div className="PrjctDtlsItm nft-1">
          //                 <div className="row align-items-center">
          //                   <div className="col-lg-7">
          //                     <div className="PrjctDtlCnt">
          //                       <h3 className="port_sub_title">Scalable</h3>
          //                       <p>
          //                         Scalable software design principles emphasize
          //                         building systems that will be able to handle
          //                         growth in an efficient manner. Tenets include
          //                         modularity, loose coupling, high cohesion, and
          //                         asynchronous processing practices enable the
          //                         system to remain flexible and maintainable, as
          //                         well as perform even in times of capacity and
          //                         complexity increases, making it much easier to
          //                         scale at a minimum disruption.
          //                       </p>
          //                     </div>
          //                   </div>
          //                   <div className="col-lg-5">
          //                     <div className="PrjctImg text-center">
          //                       <img
          //                         src="./SGimage/neft.webp"
          //                         alt="Metaverse"
          //                         title="Metaverse"
          //                         loading="eager"
          //                         className="img-fluid lazyload"
          //                         width="70%"
          //                       />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="NFT Marketplace"
          //                     >
          //                       NFT Marketplace
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="NFT Game"
          //                     >
          //                       NFT Game Development
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="NFT Token"
          //                     >
          //                       NFT Token Development
          //                     </button>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="White Label NFT Solutions"
          //                     >
          //                       White Label NFT Solutions
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Launchpad Solutions"
          //                     >
          //                       Launchpad Solutions
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Fractional NFT Marketplace"
          //                     >
          //                       Fractional NFT
          //                     </button>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="RltdPrtflo">
          //                 <div className="RltdPrjHddSec">
          //                   <div className="RltdPrjHdd">
          //                     <div className="h5_title">Related Portfolio</div>
          //                   </div>
          //                 </div>

          //                 <ExchangePorfolio />
          //               </div>
          //             </div>
          //           </div>
          //           <div
          //             className="tab-pane fade"
          //             id="PrjctTbb7"
          //             role="tabpanel"
          //           >
          //             <div>
          //               <div className="PrjctDtlsItm play-1">
          //                 <div className="row align-items-center">
          //                   <div className="col-lg-7">
          //                     <div className="PrjctDtlCnt">
          //                       <h3 className="port_sub_title">Software</h3>
          //                       <p>
          //                         Customized software development is the
          //                         creation, design, and maintenance of software
          //                         according to the specifications of a company.
          //                         Unlike packaged solutions, custom software is
          //                         more scalable, flexible, and ideal for a
          //                         particular company's unique business
          //                         processes. It will improve efficiency,
          //                         integrate seamlessly with the part of any
          //                         existing system, and enhance competitive
          //                         superiority by rendering the special issues
          //                         arising in or for a given company or industry.
          //                       </p>
          //                     </div>
          //                   </div>
          //                   <div className="col-lg-5">
          //                     <div className="PrjctImg text-center">
          //                       <img
          //                         src="./SGimage/playto.webp "
          //                         alt="Play To Earn"
          //                         title="Play To Earn"
          //                         loading="eager"
          //                         className="img-fluid lazyload"
          //                       />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Axie Infinity"
          //                     >
          //                       Static Website
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Decentraland Clone"
          //                     >
          //                       Dynamic Website
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Gods Unchained Clone"
          //                     >
          //                       E-commerce Website
          //                     </button>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec"></div>
          //               </div>
          //               <div className="RltdPrtflo">
          //                 <div className="RltdPrjHddSec">
          //                   <div className="RltdPrjHdd">
          //                     <div className="h5_title">Related Portfolio</div>
          //                   </div>
          //                 </div>

          //                 <NFTPorfolio />
          //               </div>
          //             </div>
          //           </div>
          //           <div
          //             className="tab-pane fade"
          //             id="PrjctTbb9"
          //             role="tabpanel"
          //           >
          //             <div>
          //               <div className="PrjctDtlsItm defi-1">
          //                 <div className="row align-items-center">
          //                   <div className="col-lg-7">
          //                     <div className="PrjctDtlCnt">
          //                       <h3 className="port_sub_title">CI/CD</h3>
          //                       <p>
          //                         Continuous Integration (CI): A practice
          //                         whereby developers keep merging their code
          //                         changes into the shared mainline at least once
          //                         a day, where an automated build triggers to
          //                         verify that such changes are compatible with
          //                         the existing codebase. It helps discover and
          //                         fix defects quickly, improve the quality of
          //                         code, and reduce the amount of integration
          //                         problems. Continuous Delivery (CD): Enabling
          //                         tests set of codes to be automatically
          //                         deployed into staging production. This indeed
          //                         offers rapid releases of software updates with
          //                         very few human interventions.
          //                       </p>
          //                     </div>
          //                   </div>
          //                   <div className="col-lg-5">
          //                     <div className="PrjctImg text-center">
          //                       <img
          //                         src="./images/application.webp "
          //                         alt="Application"
          //                         title="Application"
          //                         loading="eager"
          //                       />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="DeFi Development"
          //                     >
          //                       Application Development
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Travelling Application"
          //                     >
          //                       Travelling Application
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Social media"
          //                     >
          //                       Social media application
          //                     </button>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Grocery Application"
          //                     >
          //                       Grocery Application
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="fitness application"
          //                     >
          //                       fitness application
          //                     </button>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="RltdPrtflo">
          //                 <div className="RltdPrjHddSec">
          //                   <div className="RltdPrjHdd">
          //                     <div className="h5_title">Related Portfolio</div>
          //                   </div>
          //                 </div>

          //                 <DefiPorfolio />
          //               </div>
          //             </div>
          //           </div>
          //           <div
          //             className="tab-pane fade"
          //             id="PrjctTbb10"
          //             role="tabpanel"
          //           >
          //             <div>
          //               <div className="PrjctDtlsItm dapp-1">
          //                 <div className="row align-items-center">
          //                   <div className="col-lg-7">
          //                     <div className="PrjctDtlCnt">
          //                       <h3 className="port_sub_title">
          //                         Cloud Computing
          //                       </h3>
          //                       <p>
          //                         Cloud computing in software development
          //                         essentially allows developers access to
          //                         scalable computing resources through the
          //                         internet. It promotes joint ventures by
          //                         improving flexibility and lessening
          //                         infrastructure costs. Cloud platforms provide
          //                         developers the ability to quickly deploy,
          //                         test, and update applications for faster
          //                         delivery along with high availability and
          //                         seamless integration across various
          //                         environments.
          //                       </p>
          //                     </div>
          //                   </div>
          //                   <div className="col-lg-5">
          //                     <div className="PrjctImg text-center">
          //                       <img
          //                         src="./SGimage/dapp.webp "
          //                         alt="Dapp"
          //                         title="Dapp"
          //                         loading="eager"
          //                         className="img-fluid lazyload"
          //                         width="60%"
          //                       />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="PrjctsBtnSec">
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Dapp Development"
          //                     >
          //                       Dapp Development
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Tron Dapp"
          //                     >
          //                       Tron Dapp Development
          //                     </button>
          //                   </div>
          //                   <div className="mr-4 mb-4">
          //                     <button
          //                       type="button"
          //                       className="btn PrjctBtn Btn211-42"
          //                       aria-label="Ethereum Dapp"
          //                     >
          //                       Ethereum Dapp
          //                     </button>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="RltdPrtflo">
          //                 <div className="RltdPrjHddSec">
          //                   <div className="RltdPrjHdd">
          //                     <div className="h5_title">Related Portfolio</div>
          //                   </div>
          //                 </div>

          //                 <DAppPorfolio />
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>

//           {/* Testimonials from Delighted Clients  */}
//           <div className="AutoPlay-section" style={{ background: "black" }}>
//             <AutoPlay />
//           </div>

//           {/* BENEFITS TO COLLABORATING WITH US  */}
//           <div className="why_MetaBlock_blockchain_sec">
//             <div className="container">
//               <div className="BnrCnt OurservHdd mb-lg-4">
//                 <h2
//                   style={{ fontSize: "40px", fontWeight: "bold" }}
//                   className="text-center why-Metablock-heading grident-color-sub-title "
//                 >
//                   BENEFITS TO COLLABORATING WITH US
//                   {/* BENEFITS TO COLLABORATING WITH US. */}
//                 </h2>
//                 <p className="text-center">
//                   At MetaBlock, customers are like a part of the family, and we
//                   treat every customer just like family and always put their
//                   needs before ours.
//                 </p>
//               </div>

//               {/* BenefitsColabratingSlider  */}
//               <div className="why_MetaBlock_blockchain_card">
//                 <div className="row align-items-center">
//                   <BenefitsColabratingSlider />
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Brands we have worked with  */}
//           <div style={{ background: "black" }}>
//             <BrandsWork />
//           </div>

//            {/* Our Procedure  */}
//           <div className="Ourprocesure-main-div">
//             <Ourprocesure />
//           </div>

//           {/* Frequently Ased Questions  */}
//           <main id="faq-questions-div" className="faq_main">
//             <div className="faq_section_new">
//               <div className="container">
//                 <div style={{ gap: "100px" }} className="row ">
//                   <div className="col-xl-7 col-12">
//                     <div className="mid_section">
//                       <div className="section-heading">
//                         <p className="faq_title">
//                           Frequently Asked <br /> Questions (FAQs)
//                         </p>
//                         <p className="faq_contents" />
//                       </div>
//                     </div>
//                     <div className="job-cnt">
//                       <div className="panel-group" id="accordion">
//                         <div className="panel panel-default" role="tablist">
//                           <div
//                             className="panel-heading"
//                             role="tab"
//                             aria-selected="true"
//                             id="headingOne1"
//                           >
//                             <div className="panel-title">
//                               <a
//                                 className="collapsed"
//                                 role="button"
//                                 data-toggle="collapse"
//                                 data-parent="#accordion"
//                                 href="#collapseOne1"
//                                 aria-expanded="false"
//                                 aria-controls="collapseOne1"
//                               >
//                                 <p className="qus_title">
//                                   Q.1 - What Is Software Development?
//                                 </p>
//                               </a>
//                             </div>
//                           </div>
//                           <div
//                             id="collapseOne1"
//                             className="panel-collapse collapse"
//                             aria-labelledby="headingOne1"
//                             data-parent="#accordion"
//                           >
//                             <div className="panel-body job-cnt-hd">
//                               <p>
//                                 Software development refers to creating,
//                                 testing, designing, or maintaining applications
//                                 or systems used in computing. This includes
//                                 using certain programming languages, tools, or
//                                 methodologies for developing solutions to
//                                 specific needs or solving problems to perform
//                                 the task on a computer or device.
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="panel panel-default" role="tablist">
//                           <div
//                             className="panel-heading"
//                             role="tab"
//                             aria-selected="true"
//                             id="headingOne2"
//                           >
//                             <div className="panel-title">
//                               <a
//                                 className="collapsed"
//                                 role="button"
//                                 data-toggle="collapse"
//                                 data-parent="#accordion"
//                                 href="#collapseOne2"
//                                 aria-expanded="false"
//                                 aria-controls="collapseOne2"
//                               >
//                                 <p className="qus_title">
//                                   Q.2 - What are the two main types of software?
//                                 </p>
//                               </a>
//                             </div>
//                           </div>
//                           <div
//                             id="collapseOne2"
//                             className="panel-collapse collapse"
//                             aria-labelledby="headingOne2"
//                             data-parent="#accordion"
//                           >
//                             <div className="panel-body job-cnt-hd">
//                               <p>
//                                 Software is primarily divided into two
//                                 categories: application software and system
//                                 software. An application is any software that
//                                 solves a specific need or performs tasks that
//                                 can be done using it. System software is
//                                 programmed and designed to run computer hardware
//                                 and also gives a platform to applications that
//                                 run over it.
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="panel panel-default" role="tablist">
//                           <div
//                             className="panel-heading"
//                             role="tab"
//                             aria-selected="true"
//                             id="headingOne3"
//                           >
//                             <div className="panel-title">
//                               <a
//                                 className="collapsed"
//                                 role="button"
//                                 data-toggle="collapse"
//                                 data-parent="#accordion"
//                                 href="#collapseOne3"
//                                 aria-expanded="false"
//                                 aria-controls="collapseOne3"
//                               >
//                                 <p className="qus_title">
//                                   Q.3 - What are the benefits of software
//                                   development in business?
//                                 </p>
//                               </a>
//                             </div>
//                           </div>
//                           <div
//                             id="collapseOne3"
//                             className="panel-collapse collapse"
//                             aria-labelledby="headingOne3"
//                             data-parent="#accordion"
//                           >
//                             <div className="panel-body job-cnt-hd">
//                               <p>
//                                 1. Tailored Solutions 2. Increased Efficiency{" "}
//                                 <br /> 3. Cost-Effectiveness 4. Enhanced
//                                 Customer Experience <br /> 5. Scalability and
//                                 Flexibility 6. Data Management and Analytics{" "}
//                                 <br /> 7. Competitive Advantage 8. Improved
//                                 Communication <br />
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="panel panel-default" role="tablist">
//                           <div
//                             className="panel-heading"
//                             role="tab"
//                             aria-selected="true"
//                             id="headingOne4"
//                           >
//                             <div className="panel-title">
//                               <a
//                                 className="collapsed"
//                                 role="button"
//                                 data-toggle="collapse"
//                                 data-parent="#accordion"
//                                 href="#collapseOne4"
//                                 aria-expanded="false"
//                                 aria-controls="collapseOne4"
//                               >
//                                 <p className="qus_title">
//                                   Q.4 - What is the cost of software development
//                                   in India?
//                                 </p>
//                               </a>
//                             </div>
//                           </div>
//                           <div
//                             id="collapseOne4"
//                             className="panel-collapse collapse"
//                             aria-labelledby="headingOne4"
//                             data-parent="#accordion"
//                           >
//                             <div className="panel-body job-cnt-hd">
//                               <p>
//                                 Basic software Cost will be approx 100,000 INR
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="panel panel-default" role="tablist">
//                           <div
//                             className="panel-heading"
//                             role="tab"
//                             aria-selected="true"
//                             id="headingOne5"
//                           >
//                             <div className="panel-title">
//                               <a
//                                 className="collapsed"
//                                 role="button"
//                                 data-toggle="collapse"
//                                 data-parent="#accordion"
//                                 href="#collapseOne5"
//                                 aria-expanded="false"
//                                 aria-controls="collapseOne5"
//                               >
//                                 <p className="qus_title">
//                                   Q.5 - Which Software Is The Best For Business?
//                                 </p>
//                               </a>
//                             </div>
//                           </div>
//                           <div
//                             id="collapseOne5"
//                             className="panel-collapse collapse"
//                             aria-labelledby="headingOne5"
//                             data-parent="#accordion"
//                           >
//                             <div className="panel-body job-cnt-hd">
//                               <p>
//                                 Here are the top categories of software and
//                                 examples - <br /> CRM - Salesforce, HubSpot CRM,
//                                 Zoho <br /> CRM ERP - SAP ERP, Oracle Netsuite,
//                                 Odoo
//                               </p>
//                             </div>
//                           </div>
//                         </div>

//                         <br />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </main>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SimilarFile;





import React from "react";

import AutoPlay from "./MainPage/ReactSlick";

import WhyChooseUs from "./MainPage/WhyChooseUs";

import BrandsWork from "./MainPage/BrandsWork";
import Ourprocesure from "./MainPage/Ourprocesure";
import BenefitsToCollaborating from "./BenefitsToCollaborating";


const SimilarFile = () => {
 
  return (
    <>
      <div className="responsive-in-black-bg">
        <div className="MtaverseSec DapsMainBg OurPrjctBg" style={{ background: "black" }}>
          <div>
            <WhyChooseUs />
          </div>

          <div className="AutoPlay-section" style={{ background: "black" }}>
            <AutoPlay />
          </div>

          {/* BENEFITS TO COLLABORATING WITH US */}
          {/* <div className="why_MetaBlock_blockchain_sec">
            <div className="container">
              <div className="BnrCnt OurservHdd mb-lg-4">
                <h2
                  style={{ fontSize: "40px", fontWeight: "bold" }}
                  className="text-center why-Metablock-heading grident-color-sub-title"
                >
                  BENEFITS TO COLLABORATING WITH US
                </h2>
                <p className="text-center">
                  At MetaBlock, customers are like a part of the family, and we
                  treat every customer just like family and always put their
                  needs before ours.
                </p>
              </div>

             
              <div className="why_MetaBlock_blockchain_card">
                <div className="row align-items-center">
                  <BenefitsColabratingSlider />
                </div>
              </div>
            </div>
          </div> */}

          <BenefitsToCollaborating/>

          {/* Brands we have worked with */}
          <div style={{ background: "black" }}>
            <BrandsWork />
          </div>

          {/* Our Procedure */}
          {/* <div className="Ourprocesure-main-div">
            <Ourprocesure />
          </div> */}

        

        </div>
      </div>
    </>
  );
};

export default SimilarFile; 