import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";



import ApplicationSwiperSlider from "./ApplicationSwiperSlider/ApplicationSwiperSlider";
import SeoHelmet from "../../../Helmet";
import TechnologyStack from "../../../components/TechnologyStack";
import faqData from "../../faqData";
import FAQSection2 from "../../FAQSection2";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";


const ApplicatonDevelopment = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="A Complete Counselor to Mobile App Development Services for Businesses"
          description="A Best Mobile App Development Services Company helps businesses build and launch low-cost mobile apps that meet their goals and users' needs, ensuring a smooth, successful experience from start to finish."
          keywords="Mobile App Development Services Company"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      Best Mobile App Development Services Company
                    </h1>
                    <p className="heading-tagline">
                    We Offer Top-notch Best Mobile App Development Services Company  in iOS, Android, And Hybrid Platforms. This Is the process of making mobile App development applications that run on smartphones and tablets.

  <br />
  100+ Mobile Apps Delivered 
  <br />
  1500 + Satisfied clients
  <br />
  50+ Team Strength
  <br />
  91% Repeat Business
  <br />
   
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  We are experts in app development{" "}
                </h2>
                <p style={{ fontSize: "20px" }}>
                  At Metablocks Technologies, we specialize in providing
                  high-quality mobile App development solutions Friendly to meet
                  the specific needs of our clients. With a team of experienced
                  designers and app developers, we offer a detailed range of
                  services to create seamless, user-friendly, and innovative
                  mobile applications for Hybrid, iOS, And Android mobile apps
                  platforms.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
              </div>
            </div>
          </section>

          <ApplicationSwiperSlider />
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>

          <section>
          <OurProjects
            projectData={projectData}
            heading="Explore our impressive portfolio showcase."
            description=" We offer a range of services including web development, Flutter app development, game development, software development, mobile app development and blockchain development."
            filterKey="mobileapp" // Display only software projects
          />
            <SimilarFile />
          </section>
        </div>
        <FAQSection2 category="mobileapp"/>
      </div>
    </>
  );
};

export default ApplicatonDevelopment;
