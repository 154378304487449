

import React, { useState, useEffect } from "react";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Video = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const [galleryType, setGalleryType] = useState("All");
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
    fetchVideos();
  }, []);

  useEffect(() => {
    fetchVideos();
  }, [galleryType]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org/api/v1/category"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const fetchVideos = async () => {
    setLoading(true);
    try {
      let response;
      if (galleryType === "All") {
        response = await axios.get(
          "https://maincompanybackend.metablocktechnologies.org/api/v1/video/videos"
        );
      } else {
        response = await axios.get(
          `https://maincompanybackend.metablocktechnologies.org/api/v1/video/videos/category/${galleryType}`
        );
      }
      setVideos(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching videos", error);
      setLoading(false);
    }
  };

  const openLightbox = (index) => {
    setVideoIndex(index);
    setLightboxOpen(true);
  };

  const handleButtonClick = (type) => {
    setGalleryType(type);
  };

  const handleSelectChange = (event) => {
    handleButtonClick(event.target.value);
  };
  console.log(videos);
  return (
    <main id="video_div">
      <section className="breadcrumb_section breadcrumb_padd">
        <div className="container">
          <div className="row justify-content-center">
            <ul className="list-inline nomargin flex align-middle gap-2">
              <li>
                <a href="/" target="_blank">
                  Home
                </a>
              </li>
              <li>&gt;</li>
              <li>Video Gallery</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="gallery-buttons">
        <div className="gallery-buttons-div">
          <button
            className="gallery-buttons-div-button"
            onClick={() => handleButtonClick("All")}
          >
            All
          </button>
          {categories.map((category) => (
            <button
              className="gallery-buttons-div-button"
              key={category._id}
              onClick={() => handleButtonClick(category._id)}
            >
              {category.name}
            </button>
          ))}
        </div>
        <div className="gallery-buttons-responsive">
          <select
            className="gallery-responsive-select"
            onChange={handleSelectChange}
          >
            <option value="All">All</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </section>
      <section className="gallery-area pt-100 pb-70">
        <div className="container">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="row">
              {videos?.map((video, index) => (
                <div className="col-lg-4 col-md-6 col-sm-6" key={video._id}>
                  <div className="single-gallery-item">
                    <a href="#" onClick={() => openLightbox(index)}>
                      <iframe
                        width="100%"
                        height="230px"
                        src={video.url}
                        title="Video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        style={{
                          borderRadius: "15px", // Adjust the radius as needed
                          border: "3px solid red",
                          objectFit: "cover", // Adjust the border width and color as needed
                        }}
                      ></iframe>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
      {lightboxOpen && (
        <Lightbox
          mainSrc={videos[videoIndex].url}
          nextSrc={videos[(videoIndex + 1) % videos.length].url}
          prevSrc={videos[(videoIndex + videos.length - 1) % videos.length].url}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setVideoIndex((videoIndex + videos.length - 1) % videos.length)
          }
          onMoveNextRequest={() =>
            setVideoIndex((videoIndex + 1) % videos.length)
          }
        />
      )}
    </main>
  );
};

export default Video;
