import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import ResturentSwiperSlider from "./SwiperSlider/SAASSwiperSlider";
import ResturentSwipersSlider from "./SwiperSlider/ResturentSwiperSlider";
import SeoHelmet from "../../../Helmet";

import FAQSection2 from "../../FAQSection2";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'

const RestaurantManagmentSystemSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Unlocking Success with Custom Restaurant Software Development

"
          description="
Custom Restaurant software development makes waves in managing operations, customer experience, and efficiency. Delve into feature sets, benefits, and how custom-made solutions can change your restaurant business.

"
          keywords="Custom Restaurant Software Development"
        />
      </div>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Restaurant Managment System Software Development Company
                  </h1>
                  <p className="heading-tagline">
                    Custom Restaurant software development facilitates effective
                    daily operations for businesses in handling reservations,
                    orders, inventories, staff, and conceptualizing finances.
                    Developing such software involves constructing a strong
                    design model to meet the peculiar needs of restaurants.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className="Key-feature-heading">
                Specialist In Restaurant Management System
              </h1>
              <p style={{ fontSize: "20px" }}>
                There lies a specialization at [MetaBlock Technologies]
                regarding restaurant management software development adeptness
                in delivering custom solutions to meet the needs of all
                modern-era food service establishments. Our very expertise will
                enable you to integrate features such as inventory tracking, POS
                systems, staff management, and online ordering streams for
                effortless working together to ensure maximum customer
                satisfaction. With highly advanced technologies and an in-depth
                understanding of workflow processes within restaurants, we
                develop cost-reducing, efficiency-boosting, and growth-driving
                software for our clients. Get a transformative experience for
                your restaurant with our path-breaking software, one at a time.
              </p>

              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">
              <img src="./images/restrorentdev.webp" alt="restrorentdev" />
            </div>
          </div>
        </section>

        <ResturentSwipersSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
         
        </section>
       
        <section>
          <OurProjects
            projectData={projectData}
            heading="Our Projects: A Showcase of Impactful Solutions"
            description="View different categories that depict the most advanced services provided by our company. Based on dynamic web development, mobile applications, gaming, and blockchain, these projects represent the best samples of our work. No matter if you are seeking a game, NFT, Metaverse experience, or enterprise software, our engaged team is involved in turning your ideas into reality using cutting-edge technologies. Check out the portfolio page of MetaBlock see some of the successful projects and learn how ideas are brought to life."
            filterKey="restaurant" // Display only software projects
          />
        </section>

        <section>
          <SimilarFile />
        </section>
        <FAQSection2 category="restaurant"/>
      </div>
    </>
  );
};

export default RestaurantManagmentSystemSoftware;
