import React, { useState } from "react";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Login_admin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://maincompanybackend.metablocktechnologies.org/api/v1/authlogin/login",
        {
          username,
          password,
        }
      );
      setMessage(response.data.message);

      if (response.data.token) {
        navigate("/admin_page");
      }
    } catch (error) {
      setMessage(error.response?.data?.message || "Login failed");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="flex h-screen items-center justify-center p-10 bg-gray-100">
      <div className="xl:w-1/2 rounded-2xl border border-blue-800 md:shadow-xl bg-white">
        <div className="grid md:grid-cols-2 p-5">
          <div className="flex items-center justify-center">
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/login-10299071-8333958.png?f=webp"
              alt="Login Illustration"
              className="w-full h-auto"
            />
          </div>
          <div className="flex items-center justify-center">
            <form onSubmit={handleSubmit} className="w-full max-w-sm">
              <h1 className="text-center font-extrabold uppercase text-rose-500 mb-5">
                Admin Login
              </h1>
              <input
                type="text"
                className="mb-3 w-full rounded-2xl text-black outline-rose-400 px-5 py-3"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <div className="relative mb-3">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full rounded-2xl text-black outline-rose-400 px-5 py-3 pr-12" // Add padding-right to make space for the icon
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 focus:outline-none"
                >
                  {showPassword ? (
                    <FaEyeSlash className="bg-black text-black rounded" />
                  ) : (
                    <FaEye className="bg-black text-black rounded" />
                  )}
                </button>
              </div>
              <button
                type="submit"
                className="w-full rounded-2xl bg-rose-500 px-5 py-3 font-semibold text-white hover:bg-rose-600"
              >
                Login
              </button>
              {message && (
                <p className="mt-4 text-center text-red-500">{message}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login_admin;
