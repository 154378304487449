import React, { useEffect, useState } from "react";
import "./main.css";
import "./Component/MetaversDevelopment/MetaverseDevelopment.css";
import SimilarFile from "./Component/SimilarFile";
import AboutForm from "./Component/MainPage/AboutForm";
import AboutUsWhoWeAre from "./Component/MainPage/AboutUsWhoWeAre";
import { Button } from "@mui/material";
import OurServices from "./Component/MainPage/OurServices/OurServices";
import TrandingProduct from "./Component/MainPage/TrandingProduct";
import { Link } from "react-router-dom";
import WhyITServiceImportant from "./Component/MainPage/WhyITServiceImportant";
import ChallengesInYourBusiness from "./Component/MainPage/ChallengesInYourBusiness";
import BenefitsOfColabratingSlider from "./Component/MainPage/BenefitsOfColabratingSlider";
import SeoHelmet from "./Helmet";
import FAQSection2 from "./Component/FAQSection2";
import projectData from "./Component/projectData";
import OurProjects from "./Component/OurProjects";
import TechStack from "./Component/TechStack";

const Main = () => {
  useEffect(() => {
    const counters = document.querySelectorAll(".vdoCntLstCount");
    const duration = 1000; // Total duration in milliseconds
    const frameRate = 30; // Frames per second
    const interval = duration / frameRate; // Time per frame

    counters.forEach((counter) => {
      const target = +counter.getAttribute("data-target");
      const steps = frameRate; // Total steps (60 FPS over 5 seconds)
      const increment = target / steps;

      let current = 0;
      let stepCount = 0;

      const updateCount = () => {
        stepCount++;
        current += increment;

        if (stepCount < steps) {
          counter.innerText = `${Math.ceil(current)}+`;
          setTimeout(updateCount, interval);
        } else {
          counter.innerText = `${target}+`; // Ensure the final value is accurate
        }
      };

      updateCount();
    });
  }, []);
  return (
    <div>
      <SeoHelmet
        pagetitle="Best Web and Blockchain Development Company for Transforming Your Business"
        description="MetaBlock is the best web and blockchain development company providing web development services and solutions that will revolutionize your corporation. Our services are centred on developing end-to-end secure and ‘cloud-branded’ web-based solutions tailored to your business needs."
        keywords="web development, blockchain, MetaBlock, business transformation, secure solutions"
      />
      <div style={{ background: "black" }}>
        <div className="Main-div MnuPaddTop">
          

          <div className="w-full px-4 py-10">
            <div className="flex flex-col lg:flex-row gap-8 mt-28">
              {/* Left Side Content - Takes full width when AboutForm is hidden */}
              <div className="flex-1 max-w-full lg:max-w-[60%] text-justify">
                <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold leading-tight">
                  <div className="text-white">BEST WEB AND BLOCKCHAIN</div>
                  <br />
                  <span className="bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-transparent bg-clip-text">
                    DEVELOPMENT COMPANY IN INDIA
                  </span>
                </h1>
                <p className="text-gray-300 mt-8 text-xl md:text-2xl lg:text-3xl">
                  We are a best web and blockchain development company that
                  provides secure, effective web and blockchain services.
                  Clients are able to enhance themselves and succeed in the
                  modern technologically enhanced world with our sophisticated
                  systems in place.
                </p>
                <p className="text-gray-300 font-semibold mt-6 text-2xl md:text-3xl lg:text-4xl">
                  WE ARE THE LEADERS IN WEB & BLOCKCHAIN DEVELOPMENT.
                </p>

                {/* Stats Section */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-16">
                  <div className="bg-gray-800 p-6 rounded-2xl text-center">
                    <p className="text-3xl md:text-4xl lg:text-5xl font-bold">
                      54+
                    </p>
                    <p className="text-gray-300 text-md">Team</p>
                  </div>
                  <div className="bg-gray-800 p-6 rounded-2xl text-center">
                    <p className="text-3xl md:text-4xl lg:text-5xl font-bold">
                      250+
                    </p>
                    <p className="text-gray-300 text-md">Projects</p>
                  </div>
                  <div className="bg-gray-800 p-6 rounded-2xl text-center">
                    <p className="text-3xl md:text-4xl lg:text-5xl font-bold">
                      4+
                    </p>
                    <p className="text-gray-300 text-md">Years of Expertise</p>
                  </div>
                  <div className="bg-gray-800 p-6 rounded-2xl text-center">
                    <p className="text-3xl md:text-4xl lg:text-5xl font-bold">
                      1+
                    </p>
                    <p className="text-gray-300 text-md">Countries</p>
                  </div>
                </div>
              </div>

              {/* Right Side About Form - Hidden below 1024px */}
              <div className="hidden lg:block lg:max-w-[35%]">
                <div id="aboutForm" className="lg:ml-8 xl:ml-12">
                  <AboutForm />
                </div>
              </div>
            </div>
          </div>

          <div className="AboutUsWhoWeAre-div">
            <AboutUsWhoWeAre />
          </div>

          <div className="OurServices-main-div">
            <OurServices />
          </div>

          <div>
            <TechStack />
          </div>

          <div>
            <WhyITServiceImportant />
          </div>

          <div>
            <ChallengesInYourBusiness />
          </div>

          <div>
            <TrandingProduct />
          </div>
          <section>
            <OurProjects
              projectData={projectData}
              heading=" Our Projects: A Showcase of Impactful Solutions"
              description=" Visit MetaBlock and discover more about our competence in dynamic web development, mobile applications, gaming, and blockchain. Whether it is creating groundbreaking games or designing NFTs and enterprise applications, our team gets your visions right."
              filterKey="home"
            />
          </section>
          <div>
            <SimilarFile />
          </div>
          <FAQSection2 category="home" />
        </div>
      </div>
    </div>
  );
};

export default Main;

{
  /* This is old code of Techstack */
}
{
  /* <div className="MtaverseSec DapsMainBg TechStk">
            <div className="container container-1170">
             
              <div className="BnrCnt OurservHdd mb-lg-4">
                <h2 className="grident-color-sub-title">
                  Modern Web Development with the Latest Tools
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-2 order-lg-2">
                  <div
                    className="nav flex-column nav-pills VrtclTbb"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active "
                      data-toggle="pill"
                      data-target="#TechStkTbb1"
                      type="button"
                      role="tab"
                      aria-controls="TechStkTbb1"
                      aria-selected="true"
                    >
                      Web
                    </button>
                    <button
                      className="nav-link"
                      data-toggle="pill"
                      data-target="#TechStkTbb2"
                      type="button"
                      role="tab"
                      aria-controls="TechStkTbb2"
                      aria-selected="false"
                    >
                      Mobile
                    </button>
                    <button
                      className="nav-link"
                      data-toggle="pill"
                      data-target="#TechStkTbb3"
                      type="button"
                      role="tab"
                      aria-controls="TechStkTbb3"
                      aria-selected="false"
                    >
                      Desktop Apps
                    </button>
                    <button
                      className="nav-link"
                      data-toggle="pill"
                      data-target="#TechStkTbb4"
                      type="button"
                      role="tab"
                      aria-controls="TechStkTbb4"
                      aria-selected="false"
                    >
                      Blockchain
                    </button>
                    <button
                      className="nav-link"
                      data-toggle="pill"
                      data-target="#TechStkTbb5"
                      type="button"
                      role="tab"
                      aria-controls="TechStkTbb5"
                      aria-selected="false"
                    >
                      Blockchain Tools
                    </button>
                    <button
                      className="nav-link"
                      data-toggle="pill"
                      data-target="#TechStkTbb6"
                      type="button"
                      role="tab"
                      aria-controls="TechStkTbb5"
                      aria-selected="false"
                    >
                      Game
                    </button>
                  </div>
                </div>
                <div className="col-lg-10 order-lg-1">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="TechStkTbb1"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-xl-6 images-ignite-innovation order-2 order-xl-1">
                          <div className="TechStkIconSec">
                            <div className="row">
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={300}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/nuxtimg.webp"
                                      alt="Nuxt Js"
                                      title="Nuxt Js"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Next Js</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={300}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/nextjsicon.webp"
                                      alt="Next Js"
                                      title="Next Js"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Next Js</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={300}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/express-js.webp"
                                      alt="Express Js"
                                      title="Express Js"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Express Js</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={200}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/nodejs.webp"
                                      alt="Node Js"
                                      title="Node Js"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Node Js</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={800}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/angularicon.webp"
                                      alt="Angular JS"
                                      title="Angular JS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Angular JS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={400}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/mongodbicon.webp"
                                      alt="Mongo DB"
                                      title="Mongo DB"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Mongo DB</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={500}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/jqueryicon.webp"
                                      alt="JQuery"
                                      title="JQuery"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>JQuery</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={600}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/mysqlicon.webp"
                                      alt="My Sql"
                                      title="My Sql"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>My Sql</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={700}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/phpicon.webp"
                                      alt="PHP"
                                      title="PHP"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>PHP</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={100}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/laravelicon.webp"
                                      alt="Laravel"
                                      title="Laravel"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Laravel</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={900}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/codeignitoricon.webp "
                                      alt="Codeigniter"
                                      title="Codeigniter"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Codeigniter</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={1200}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/bootstrapicon.webp"
                                      alt="Bootstrap"
                                      title="Bootstrap"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Bootstrap</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={1000}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/htmlcssicon.webp"
                                      alt="HTML 5"
                                      title="HTML 5"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>HTML 5</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div
                                  className="TechStkIconBox text-center aos-init aos-animate"
                                  data-aos="fade-up"
                                  data-aos-delay={1100}
                                >
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/cssicon.webp"
                                      alt="CSS 3"
                                      title="CSS 3"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>CSS 3</p>
                                  </div>
                                </div>
                              </div>
                              <div className="visite-now-button-ignite">
                                <Link to="/contact-us">
                                  {" "}
                                  <div className="HddrBtns mt-4">
                                    <button
                                      data-toggle="modal"
                                      data-target="#popup_form_modal"
                                      className="btn BtnPrimry TlkBtn"
                                      target="_blank"
                                    >
                                      <span className="img-fluid lazyload mr-2">
                                        Visit Now
                                      </span>
                                      <span>Visit Now</span>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ignite-innovation-responsive col-xl-6 pt-5 pl-5 contant-ignite-innovation order-1 order-xl-2">
                          <div className="TchStkCnt">
                            <h3 className="h3_title ">Web</h3>
                            <p>
                              Over the years web development has undergone a
                              drastic change and as it remains competitive we
                              ensure at MetaBlock we adopt the enhanced
                              technologies. With experience in developing
                              state-of-the-art tools such as HTML, CSS,
                              JavaScript, Bootstrap, Tailwind, and React JS, we
                              realize present-day, adaptive, and high-quality
                              websites to conform to our customers’
                              requirements.{" "}
                            </p>
                          </div>
                          <Link to="/contact-us">
                            {" "}
                            <div className="HddrBtns mt-4">
                              <button
                                data-toggle="modal"
                                data-target="#popup_form_modal"
                                className="btn BtnPrimry TlkBtn"
                                target="_blank"
                              >
                                <span className="img-fluid lazyload mr-2">
                                  Visit Now
                                </span>
                                <span>Visit Now</span>
                              </button>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="TechStkTbb2"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-xl-6 n order-2 order-xl-1">
                          <div className="TechStkIconSec">
                            <div className="row">
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/Fluttericon.webp"
                                      alt="Flutter"
                                      title="Flutter"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Flutter</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/kotlinicon.webp"
                                      alt="Kotlin"
                                      title="Kotlin"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Kotlin</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/swifticon.webp"
                                      alt="Swift"
                                      title="Swift"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Swift</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/androidstudio.webp"
                                      alt="Android Studio"
                                      title="Android Studio"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Android Studio</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/iosicon.webp"
                                      alt="iOS"
                                      title="iOS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>iOS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/androidicon.webp"
                                      alt="Android"
                                      title="Android"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Android</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/javaicon.webp"
                                      alt="Java"
                                      title="Java"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Java</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/jetpackicon.webp"
                                      alt="Jetpack"
                                      title="Jetpack"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Jetpack</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/iconicicon.webp"
                                      alt="Ionic"
                                      title="Ionic"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Ionic</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/reactjsicon.webp"
                                      alt="ReactJS"
                                      title="ReactJS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>ReactJS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/codeignitoricon.webp"
                                      alt="Codeigniter"
                                      title="Codeigniter"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Codeigniter</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/MobileIcon/mongodbicon.webp"
                                      alt="Mongo DB"
                                      title="Mongo DB"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Mongo DB</p>
                                  </div>
                                </div>
                              </div>
                              <div className="visite-now-button-ignite">
                                <Link to="/contact-us">
                                  {" "}
                                  <div className="HddrBtns mt-4">
                                    <button
                                      data-toggle="modal"
                                      data-target="#popup_form_modal"
                                      className="btn BtnPrimry TlkBtn"
                                      target="_blank"
                                    >
                                      <span className="img-fluid lazyload mr-2">
                                        Visit Now
                                      </span>
                                      <span>Visit Now</span>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ignite-innovation-responsive col-xl-6 pt-5 pl-5 contant-ignite-innovation order-1 order-xl-2">
                          <div className="TchStkCnt">
                            <h3 className="h3_title">Mobile</h3>
                            <p>
                              A mobile tech stack refers to the software
                              development instruments, languages, and
                              environment utilized in mobile applications
                              development. It contains all that is required for
                              creating the application interface and
                              functionality (UI/UX) as well as users’ tasks and
                              data (UI/and UX/UI interfaces). It is actually
                              more of a developer toolkit for designing
                              successful and effective mobile applications.
                            </p>
                          </div>
                          <Link to="/contact-us">
                            {" "}
                            <div className="HddrBtns mt-4">
                              <button
                                data-toggle="modal"
                                data-target="#popup_form_modal"
                                className="btn BtnPrimry TlkBtn"
                                target="_blank"
                              >
                                <span className="img-fluid lazyload mr-2">
                                  Visit Now
                                </span>
                                <span>Visit Now</span>
                              </button>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="TechStkTbb3"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-xl-6 order-2 order-xl-1">
                          <div className="TechStkIconSec">
                            <div className="row">
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/reactjsicon.webp"
                                      alt="React JS"
                                      title="React JS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>React JS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/reactjsicon.webp"
                                      alt="NW.JS"
                                      title="NW.JS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>NW.JS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/reactjsicon.webp"
                                      alt="App.JS"
                                      title="App.JS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>App.JS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/meteoricon.webp"
                                      alt="Meteor"
                                      title="Meteor"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Meteor</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/protonicon.webp"
                                      alt="Proton Native"
                                      title="Proton Native"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Proton Native</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/sevleticon.webp"
                                      alt="Sevelet"
                                      title="Sevelet"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Sevelet</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/angularicon.webp"
                                      alt="Angular 8"
                                      title="Angular 8"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Angular 8</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/nodejsicon.webp"
                                      alt="Node JS"
                                      title="Node JS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Node JS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/vuejsicon.webp"
                                      alt="Vue.JS"
                                      title="Vue.JS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Vue.JS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/electronjsicon.webp"
                                      alt="Electron.JS"
                                      title="Electron.JS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Electron.JS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/DeskstopIcon/angularicon.webp"
                                      alt="Angular JS"
                                      title="Angular JS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Angular JS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="visite-now-button-ignite">
                                <Link to="/contact-us">
                                  {" "}
                                  <div className="HddrBtns mt-4">
                                    <button
                                      data-toggle="modal"
                                      data-target="#popup_form_modal"
                                      className="btn BtnPrimry TlkBtn"
                                      target="_blank"
                                    >
                                      <span className="img-fluid lazyload mr-2">
                                        Visit Now
                                      </span>
                                      <span>Visit Now</span>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ignite-innovation-responsive col-xl-6 pt-5 pl-5 contant-ignite-innovation order-1 order-xl-2 ">
                          <div className="TchStkCnt">
                            <h3 className="h3_title">Desktop Apps</h3>
                            <p>
                              Once performed outstandingly in web development
                              strategies, the company broadened our field to
                              desktop application development, meeting our
                              clients’ specific needs across different
                              industries. Our apps are not resource hungry and
                              are optimized to perform their functionalities at
                              ultrahigh speed and with high accuracy. In this
                              sector, we develop windows application software
                              using Node.js, React.js, Express.js, MongoDB, PHP,
                              Laravel etc., here the developed software will be
                              running perfectly with high functionality in any
                              PC.
                            </p>
                          </div>
                          <Link to="/contact-us">
                            {" "}
                            <div className="HddrBtns mt-4">
                              <button
                                data-toggle="modal"
                                data-target="#popup_form_modal"
                                className="btn BtnPrimry TlkBtn"
                                target="_blank"
                              >
                                <span className="img-fluid lazyload mr-2">
                                  Visit Now
                                </span>
                                <span>Visit Now</span>
                              </button>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="TechStkTbb4"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-xl-6 order-2 order-xl-1">
                          <div className="TechStkIconSec">
                            <div className="row">
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/blockchainicon/solidityicon.webp"
                                      alt="Solidity"
                                      title="Solidity"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Solidity</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/blockchainicon/hyperledgericon.webp"
                                      alt="HyperLedger"
                                      title="HyperLedger"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>HyperLedger</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/blockchainicon/golangicon.webp "
                                      alt="Golang"
                                      title="Golang"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Golang</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/blockchainicon/smartcontracticon.webp"
                                      alt="SmartContract"
                                      title="SmartContract"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>SmartContract</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/blockchainicon/ipfsicon.webp"
                                      alt="IPFS"
                                      title="IPFS"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>IPFS</p>
                                  </div>
                                </div>
                              </div>
                              <div className="visite-now-button-ignite">
                                <Link to="/contact-us">
                                  {" "}
                                  <div className="HddrBtns mt-4">
                                    <button
                                      data-toggle="modal"
                                      data-target="#popup_form_modal"
                                      className="btn BtnPrimry TlkBtn"
                                      target="_blank"
                                    >
                                      <span className="img-fluid lazyload mr-2">
                                        Visit Now
                                      </span>
                                      <span>Visit Now</span>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ignite-innovation-responsive col-xl-6 pt-5 pl-5 contant-ignite-innovation order-1 order-xl-2">
                          <div className="TchStkCnt">
                            <h3 className="h3_title">Blockchain</h3>
                            <p>
                              The blockchain technology is a system that has
                              several layers; all of them contribute to the
                              development of the secure decentralized system.
                              The mid layer is the blockchain protocol through
                              which everything is made trustworthy. Besides,
                              various systems of tools and programming languages
                              enable handling transactions and functioning of
                              applications. All these layers hold significant
                              responsibilities of maintaining the system secure
                              and efficient.{" "}
                            </p>
                          </div>
                          <Link to="/contact-us">
                            {" "}
                            <div className="HddrBtns mt-4">
                              <button
                                data-toggle="modal"
                                data-target="#popup_form_modal"
                                className="btn BtnPrimry TlkBtn"
                                target="_blank"
                              >
                                <span className="img-fluid lazyload mr-2">
                                  Visit Now
                                </span>
                                <span>Visit Now</span>
                              </button>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="TechStkTbb5"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-xl-6 order-2 order-xl-1">
                          <div className="TechStkIconSec">
                            <div className="row">
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/BlockchainToolsIcon/metamaskicon.webp"
                                      alt="Metamask"
                                      title="Metamask"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Metamask</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/BlockchainToolsIcon/ganacheicon.webp"
                                      alt="Ganache"
                                      title="Ganache"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Ganache</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/BlockchainToolsIcon/truffleicon.webp"
                                      alt="Truffle"
                                      title="Truffle"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Truffle</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/BlockchainToolsIcon/remixicon.webp"
                                      alt="Remix"
                                      title="Remix"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Remix</p>
                                  </div>
                                </div>
                              </div>
                              <div className="visite-now-button-ignite">
                                <Link to="/contact-us">
                                  {" "}
                                  <div className="HddrBtns mt-4">
                                    <button
                                      data-toggle="modal"
                                      data-target="#popup_form_modal"
                                      className="btn BtnPrimry TlkBtn"
                                      target="_blank"
                                    >
                                      <span className="img-fluid lazyload mr-2">
                                        Visit Now
                                      </span>
                                      <span>Visit Now</span>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ignite-innovation-responsive col-xl-6 pt-5 pl-5 contant-ignite-innovation order-1 order-xl-2">
                          <div className="TchStkCnt">
                            <h3 className="h3_title">Blockchain Tools</h3>
                            <p>
                              It is important to remark that blockchain oriented
                              tools are indispensable to building trustworthy
                              information storage infrastructures. They assist
                              in creation of a decentralized database that is
                              secure from tampering, modification or hacking.
                              Such tools are important in that they keep data
                              secure, accurate, and from being modified by
                              unauthorized persons.
                            </p>
                          </div>
                          <Link to="/contact-us">
                            {" "}
                            <div className="HddrBtns mt-4">
                              <button
                                data-toggle="modal"
                                data-target="#popup_form_modal"
                                className="btn BtnPrimry TlkBtn"
                                target="_blank"
                              >
                                <span className="img-fluid lazyload mr-2">
                                  Visit Now
                                </span>
                                <span>Visit Now</span>
                              </button>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="TechStkTbb6"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-xl-6 order-2 order-xl-1">
                          <div className="TechStkIconSec">
                            <div className="row">
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/GameIcon/unityicon.webp"
                                      alt="Unity"
                                      title="Unity"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Unity</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/GameIcon/unrealicon.webp"
                                      alt="Unreal Engine"
                                      title="Unreal Engine"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Unreal Engine</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/GameIcon/reactjs.png"
                                      alt="React"
                                      title="React"
                                      loading="eager"
                                      // className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>React</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/GameIcon/nodejsicon.webp"
                                      alt="NodeJs"
                                      title="NodeJs"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>NodeJs</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/GameIcon/mongodbicon.webp"
                                      alt="Mongo DB"
                                      title="Mongo DB"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Mongo DB</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/GameIcon/publicblokchainicon.webp"
                                      alt="Public Blockchains"
                                      title="Public Blockchains"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Public Blockchains</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col mb-3">
                                <div className="TechStkIconBox text-center">
                                  <div className="TechStkIcon">
                                    <img
                                      src="./SGimage/GameIcon/marmaladeicon.webp"
                                      alt="Marmalade SDK"
                                      title="Marmalade SDK"
                                      loading="eager"
                                      className="img-fluid lazyload"
                                    />
                                  </div>
                                  <div className="TechStkIcnCnt">
                                    <p>Marmalade SDK</p>
                                  </div>
                                </div>
                              </div>
                              <div className="visite-now-button-ignite">
                                <Link to="/contact-us">
                                  {" "}
                                  <div className="HddrBtns mt-4">
                                    <button
                                      data-toggle="modal"
                                      data-target="#popup_form_modal"
                                      className="btn BtnPrimry TlkBtn"
                                      target="_blank"
                                    >
                                      <span className="img-fluid lazyload mr-2">
                                        Visit Now
                                      </span>
                                      <span>Visit Now</span>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ignite-innovation-responsive col-xl-6 pt-5 pl-5 contant-ignite-innovation order-1 order-xl-2">
                          <div className="TchStkCnt">
                            <h3 className="h3_title">Game</h3>
                            <p>
                              A game tech stack refers to the underlying tools
                              that exist to support game development, as well as
                              the technologies required to continue supporting
                              and Selling the games. They include managing game
                              data, working server side, to giving the game its
                              face in form of a front-end as well as its brain
                              in form of a back-end. They are tools that are
                              supposed to make the game to run, be expandable
                              for high number of players and stable. Quite
                              simply, they are the raw materials the developers
                              use to build a solid and durable gaming platform.
                            </p>
                          </div>
                          <Link to="/contact-us">
                            {" "}
                            <div className="HddrBtns mt-4">
                              <button
                                data-toggle="modal"
                                data-target="#popup_form_modal"
                                className="btn BtnPrimry TlkBtn"
                                target="_blank"
                              >
                                <span className="img-fluid lazyload mr-2">
                                  Visit Now
                                </span>
                                <span>Visit Now</span>
                              </button>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */
}
