import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import LMSSwiperSlider from "./SwiperSlider/LMSSwiperSlider";
import SeoHelmet from "../../../Helmet";

import FAQSection2 from "../../FAQSection2";
import TechnologyStack from "../../../components/TechnologyStack";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
// import '../../main.css'
const LMSWithLiveClassSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Transform Your Teaching with Advanced top LMS software Live Class Software
"
          description="  Enhance learning even further with top LMS software live class software customized for learning that teaches. It is amazing to teach using advanced tools and seamless interactivity.

"
          keywords="top LMS software"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      Custom top LMS software and Live Class Software
                      Development | Transforming E-Learning Experiences
                    </h1>
                    <p className="heading-tagline">
                      We are designing sophisticated top LMS software for
                      Learning Management Systems-(LMS) with integrated
                      functions to enable seamless online education through live
                      classes. Real-time video streaming, interactive tools, and
                      scalable platforms, customized for schools, colleges, and
                      enterprise training, are among the offerings to be
                      developed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Specialists in LMS with Live-Class Software Development
                </h2>
                <p style={{ fontSize: "20px" }}>
                  Advanced Learning Management Systems combined with live-class
                  software customized to your educational institutes or
                  corporate training house. We specialize in developing robust,
                  scalable, and feature-rich platforms, on which the flexibility
                  of the popular LMS software combined with that of real-time,
                  interactive live-class learning would be possible.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/liveclassdev.webp" alt="liveclassdev" />
              </div>
            </div>
          </section>

          <LMSSwiperSlider />
        
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
           </section>
           <section>
           <OurProjects
            projectData={projectData}
            heading="Browse our impactful portfolio showcase"
            description=" Our services encompasses web development,app development,game development,software development and blockchain development."
            filterKey="lms" // Display only software projects
          />
            <SimilarFile />
          </section>
          <FAQSection2 category="lms"/>
        </div>
      </div>
    </>
  );
};

export default LMSWithLiveClassSoftware;
