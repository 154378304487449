import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";

import EcommereceSwiperSlider from "./ApplicationSwiperSlider/EcommereceSwiperSlider";
import KeyFeatures from "../../KeyFeatures";
import TechnologyStack from "../../../components/TechnologyStack";
import Industries from "../../Industries";
import AdminControlFeatures from "../../AdminControlFeatures";

// import '../../main.css'

const EcommerceApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">

          <div className="container h-100">
            <div className="row h-100">

              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Ecommerce Apps
                  </h1>
                  <h3 className="heading-tagline">

                    Ecommerce apps transform online shopping, offering vast product selections and secure transactions. With user-friendly interfaces and personalized recommendations, users can browse, purchase, and track deliveries with ease, enhancing the digital shopping experience.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className='Key-feature-heading'>We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>
                Discover countless options with our intuitive layout, which is made to make browsing, searching, and buying easier and more stress-free from beginning to end. Whether you're shopping for electronics, home goods, apparel, or something else entirely, finding the ideal item is simple thanks to the sophisticated filtering tools and user-friendly navigation. With real-time alerts on flash discounts, exclusive offers, and product restocks, you can stay on top of the latest trends and products and stay informed. Strong security protocols and encrypted payment gateways provide you piece of mind by protecting your sensitive data and guaranteeing worry-free transactions each and every time. With the help of our cutting-edge augmented reality technologies, you can enhance your shopping experience by seeing things in your home before making a purchase, increasing customer happiness and lowering return rates.

              </p>



              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">
              <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
            </div>
          </div>
        </section>

        <EcommereceSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
        <section className="process_we_follow section-heading">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="cms text-center Process">
                  <h3>Our Step-By-Step Software Development Process</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <img src="/images/process-flow-new.webp" alt="process-flow-new" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
}

export default EcommerceApps
