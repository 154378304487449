// procedureData.js
export const procedureData = {
  software: {
    heading: "Our Procedure",
    description:
      "At MetaBlock, we have also adopted a Simple Efficient Process to ensure that we provide the best. What one needs for his or her business is well understood then a solution is provided to formulate the same. Our team focuses on development and testing, and without exception, everything is cohesive and flawless. Additionally, we offer sustainability services to make sure that the solution still addresses your business objectives.",
    steps: [
      {
        title: "Gathering Information",
        content:
          "Here are the steps of our process: The first one is to collect information. We invest time to study your corporate objectives, specifications, and rigor. It also makes it easier for us to have a concise idea of how we want the solution to be and the way that would suit your needs best. We are receptive to your suggestions and query sufficient information to ascertain your goal.s",
      },
      {
        title: "Market Research",
        content:
          "Market research assists in gaining valuable insight into your industry, your competitors, and your target group. We provide a strategic view in order to make sure that your solution is going to be relevant and competitive in the market. This enables us to develop strategies that put your business in the market for success to happen.",
      },
      {
        title: "Creating Mockup & Prototype",
        content:
          "Depending on the type and amount of information we compile, we design and develop mockups and prototypes of your solution. These are rough models that depict how the final product will look and the ideal functionality of the final product. With architectural rendering, you get a chance to fix things before actually developing them, to suit your preferences.",
      },
      {
        title: "Creating SRS & Estimate",
        content:
          " SRS (Software Requirement Specification) is a document that provides information on all the features of the project. To set parameters for the project, we prepare this paper once research information has been obtained. We also give approximate time and cost indications so that you know what is required for your assignment and to ensure success in your project.",
      },
      {
        title: " Create Content",
        content:
          "Content making is the process of coming up with the actual text and picture as well as other related materials to post to a website or an online platform. We emphasize the quality of text we produce for our clients by delivering only clear, interesting, and pertinent content that will successfully convey your intended message to your audience. It should be aimed at making sure that whatever content is on the site supports the brand and increases usability.",
      },
      {
        title: "Web Designing & Development",
        content:
          "Web designing & development is the concept of designing your website and the actual construction of the website. First, it creates and or develops the site’s framework and format, colors, and style to create an appealing and easy-to-use website. Then we implement the site with newer technologies to make it efficient, mobile-friendly, and fast. In short, the objective is to achieve aesthetic elegance on the one hand, and business functionality on the other.",
      },
      {
        title: " Hosting & Deployment",
        content:
          "Publishing is to put your website live on the internet while deploying it refers to hosting your website. While hosting can be explained as the storage of your website on a web server, deployment is the act of putting your website live. We guarantee your site is hosted securely, optimally as well as correctly configured for easy and efficient end-user utilization.",
      },
      {
        title: "Testing",
        content:
          "Testing can be defined as the process of running your website or application through the mill to check for bugs and problems before the website or application is live. We perform testing to assess functionality, performance, security, and usability to check that all is well. This makes it easy to correct all issues that can prevent users from having a smooth experience on the website.",
      },
      {
        title: " Maintenance & Support System",
        content:
          "Maintenance and support help to keep your website or application consistent after the launch phase. We make updates as often as possible, always solve any bug that appears and always respond to complaints. This ensures that your system remains safe, optimally tuned, and most importantly always current.",
      },
      {
        title: " Launch",
        content:
          "Release or launch is the last defense when your site or application is ready to be promoted for users’ access. All of the web content is ensured to be pushed to the limit, effectiveness, and efficiency as a final stage before going live. When we have developed it and our team is ready to launch it, we then keep track of the outcome to ensure the systems are up and running optimally.",
      },
      {
        title: " Long Partnership",
        content:
          "Long-term means you are in a business partnership for a long time to build your company. We value our partnership with you and constantly offer updates, further enhancements, and constant rock-solid support for your digital tools. We aim at becoming your business solution providers working for the longevity of your business.",
      },
      {
        title: "After Sales Support",
        content:
          " A successful sale usually reaches after-sales customer care that will constantly support you, even after the deal is done. Hull assists with any problems if you require it for updates and in general, anything from our service that causes dissatisfaction. Contrary to some companies’ practice of ceasing communication once a sale has been consummated, it is our philosophy that we remain easily accessible to you even after the sale has closed.",
      },
      // Add more steps as needed...
    ],
  },
  erp: {
    heading: "Our ERP Implementation Procedure",
    description:
      "At MetaBlock, we have also adopted a Simple Efficient Process to ensure```````` that we provide the best. What one needs for his or her business is well understood then a solution is provided to formulate the same. Our team focuses on development and testing, and without exception, everything is cohesive and flawless. Additionally, we offer sustainability services to make sure that the solution still addresses your business objectives.",
      steps: [
        {
          title: "Gathering Information",
          content:
            " Here are the steps of our process: The first one is to collect information. We invest time to study your corporate objectives, specifications, and rigor. It also makes it easier for us to have a concise idea of how we want the solution to be and the way that would suit your needs best. We are receptive to your suggestions and query sufficient information to ascertain your goal.ls",
        },
        {
          title: "Market Research",
          content:
            "Market research assists in gaining valuable insight into your industry, your competitors, and your target group. We provide a strategic view to make sure that your solution is going to be relevant and competitive in the market. This enables us to develop strategies that put your business in the market for success to happen.",
        },
        {
          title: "Creating Mockup & Prototype",
          content:
            "Depending on the type and amount of information we compile, we design and develop mockups and prototypes of your solution. These are rough models that depict how the final product will look and the ideal functionality of the final product. With architectural rendering, you get a chance to fix things before actually developing them, to suit your preferences.",
        },
        {
          title: "Creating SRS & Estimate",
          content:
            " SRS (Software Requirement Specification) is a document that provides information on all the features of the project. To set parameters for the project, we prepare this paper once research information has been obtained. We also give approximate time and cost indications so that you know what is required for your assignment and to ensure success in your project.",
        },
        {
          title: " Create Content",
          content:
            "Content making is the process of coming up with the actual text and picture as well as other related materials to post to a website or an online platform. We emphasize the quality of text we produce for our clients by delivering only clear, interesting, and pertinent content that will successfully convey your intended message to your audience. It should be aimed at making sure that whatever content is on the site supports the brand and increases usability.",
        },
        {
          title: "Web Designing & Development",
          content:
            " Web designing & development is the concept of designing your website and the actual construction of the website. First, it creates and or develops the site’s framework and format, colors, and style to create an appealing and easy-to-use website. Then we implement the site with newer technologies to make it efficient, mobile-friendly, and fast. In short, the objective is to achieve aesthetic elegance on the one hand, and business functionality on the other.",
        },
        {
          title: " Hosting & Deployment",
          content:
            "Publishing is to put your website live on the internet while deploying it refers to hosting your website. While hosting can be explained as the storage of your website on a web server, deployment is the act of putting your website live. We guarantee your site is hosted securely, optimally as well and correctly configured for easy and efficient end-user utilization.",
        },
        {
          title: "Testing",
          content:
            " Testing can be defined as the process of running your website or application through the mill to check for bugs and problems before the website or application is live. We perform testing to assess functionality, performance, security, and usability to check that all is well. This makes it easy to correct all issues that can prevent users from having a smooth experience on the website.",
        },
        {
          title: " Maintenance & Support System",
          content:
            " Maintenance and support help to keep your website or application consistent after the launch phase. We make updates as often as possible, always solve any bug that appears and always respond to complaints. This ensures that your system remains safe, optimally tuned, and most importantly always current.t",
        },
        {
          title: " Launch",
          content:
            "Release or launch is the last defense when your site or application is ready to be promoted for users’ access. All of the web content is ensured to be pushed to the limit, effectiveness, and efficiency as a final stage before going live. When we have developed it and our team is ready to launch it, we then keep track of the outcome to ensure the systems are up and running optimally.",
        },
        {
          title: " Long Partnership",
          content:
            "Long-term means you are in a business partnership for a long time to build your company. We value our partnership with you and constantly offer updates, further enhancements, and constant rock-solid support for your digital tools. We aim at becoming your business solution providers working for the longevity of your business.",
        },
        {
          title: "After Sales Support",
          content:
            "A successful sale usually reaches after-sales customer care that will constantly support you, even after the deal is done. Hull assists with any problems if you require it for updates and in general, anything from our service that causes dissatisfaction. Contrary to some companies’ practice of ceasing communication once a sale has been consummated, it is our philosophy that we remain easily accessible to you even after the sale has closed.",
        },
        // Add more steps as needed...
      ],
  },
  pos: {
    heading: "Our Procedure",
    description:
      "At MetaBlock, we have also adopted a Simple Efficient Process to ensure that we provide the best. If what is required for any business is well manipulated then obtain a similar output. Our team focuses on development and testing, and without exception, everything is cohesive and flawless. Additionally, we offer continuous services to make sure that the solution still addresses your business objectives.",
      steps: [
        {
          title: "Gathering Information",
          content:
            " Here are the steps of our process: The first one is to collect information. We invest time to study your corporate objectives, specifications, and rigor. It also makes it easier for us to have a concise idea of how we want the solution to be and the way that would suit your needs best. We are receptive to your suggestions and query sufficient information to ascertain your goal.ls",
        },
        {
          title: "Market Research",
          content:
            "Market research assists in gaining valuable insight into your industry, your competitors, and your target group. We provide a strategic view to make sure that your solution is going to be relevant and competitive in the market. This enables us to develop strategies that put your business in the request for success.",
        },
        {
          title: "Creating Mockup & Prototype",
          content:
            "Depending on the type and amount of information we compile, we design and develop mockups and prototypes of your solution. These are rough models that depict how the final product will look and the ideal functionality of the final product. With architectural rendering, you get a chance to fix things before actually developing them, to suit your preferences.",
        },
        {
          title: "Creating SRS & Estimate",
          content:
            " SRS( Software Requirement Specification) is a document that provides information on all the features of the design. To set parameters for the design, we prepare this paper once exploration information has been attained. We also give approximate time and cost suggestions so that you know what's needed for your assignment and to ensure success in your design.",
        },
        {
          title: " Create Content",
          content:
            "Content making is the process of coming up with the actual text and picture as well as other related materials to post to a website or an online platform. We emphasize the quality of text we produce for our clients by delivering only clear, interesting, and pertinent content that will successfully convey your intended message to your audience. It should be aimed at making sure that whatever content is on the site supports the brand and increases usability.",
        },
        {
          title: "Web Designing & Development",
          content:
            " Web designing & development is the concept of designing your website and the actual construction of the website. First, it creates and or develops the site’s framework and format, colors, and style to create an appealing and easy-to-use website. Then we implement the site with newer technologies to make it efficient, mobile-friendly, and fast. In short, the objective is to achieve aesthetic elegance on the one hand, and business functionality on the other.",
        },
        {
          title: " Hosting & Deployment",
          content:
            "Publishing is to put your website live on the internet while planting it refers to hosting your website. While hosting can be explained as the storehouse of your website on a web garçon, deployment is the act of putting your website live. We guarantee your point is hosted securely, optimally as well and rightly configured for easy and effective end-stoner application.",
        },
        {
          title: "Testing",
          content:
            " Testing can be defined as the process of running your website or application through the mill to check for bugs and problems before the website or application is live. We perform testing to assess functionality, performance, security, and usability to check that all is well. This makes it easy to correct all issues that can prevent users from having a smooth experience on the website.",
        },
        {
          title: " Maintenance & Support System",
          content:
            " Conservation and support help to keep your website or operation harmonious after the launch phase. We make updates as frequently as possible, always break any bug that appears and always respond to complaints. This ensures that your system remains safe, optimally tuned, and most importantly always current.",
        },
        {
          title: " Launch",
          content:
            "Release or launch is the last defense when your site or application is ready to be promoted for users’ access. All of the web content is ensured to be pushed to the limit, effectiveness, and efficiency as a final stage before going live. When we have developed it and our team is ready to launch it, we then keep track of the outcome to ensure the systems are up and running optimally.",
        },
        {
          title: " Long Partnership",
          content:
            "Long- term means you're in business cooperation for a long time to make your company. We value our cooperation with you and constantly offer updates, further advancements, and constant gemstone-solid support for your digital tools. We aim to get your business result providers working for the life of your business.",
        },
        {
          title: "After Sales Support",
          content:
            "A successful trade generally reaches after-deals client care that will constantly support you, indeed after the deal is done. Hull assists with any problems if you bear it for updates and in general, anything from our service that causes dissatisfaction. Contrary to some companies’ practice of ending communication once a trade has been perfected, it's our gospel that we remain fluently accessible to you indeed after the trade has closed.",
        },
        // Add more steps as needed...
      ],
  },
  inventory: {
    heading: "Our Procedure",
    description:
      "At MetaBlock, we have also adopted a Simple Efficient Process to ensure that we provide the best. If what is required for any business is well manipulated then obtain a similar output. Our team focuses on development and testing, and without exception, everything is cohesive and flawless. Additionally, we offer continuous services to make sure that the solution still addresses your business objectives.",
      steps: [
        {
          title: "Gathering Information",
          content:
            " Here are the steps of our process: The first one is to collect information. We invest time to study your corporate objectives, specifications, and rigor. It also makes it easier for us to have a concise idea of how we want the solution to be and the way that would suit your needs best. We are receptive to your suggestions and query sufficient information to ascertain your goal.ls",
        },
        {
          title: "Market Research",
          content:
            "Market research assists in gaining valuable insight into your industry, your competitors, and your target group. We provide a strategic view to make sure that your solution is going to be relevant and competitive in the market. This enables us to develop strategies that put your business in the request for success.",
        },
        {
          title: "Creating Mockup & Prototype",
          content:
            "Depending on the type and amount of information we compile, we design and develop mockups and prototypes of your solution. These are rough models that depict how the final product will look and the ideal functionality of the final product. With architectural rendering, you get a chance to fix things before actually developing them, to suit your preferences.",
        },
        {
          title: "Creating SRS & Estimate",
          content:
            " SRS( Software Requirement Specification) is a document that provides information on all the features of the design. To set parameters for the design, we prepare this paper once exploration information has been attained. We also give approximate time and cost suggestions so that you know what's needed for your assignment and to ensure success in your design.",
        },
        {
          title: " Create Content",
          content:
            "Content timber is the process of coming up with the factual textbook and picture as well as other affiliated accoutrements to post to a website or an online platform. We emphasize the quality of the textbook we produce for our guests by delivering only clear, intriguing, and material content that will successfully convey your intended communication to your followership. It should be aimed at making sure that whatever content is on the point supports the brand and increases usability.",
        },
        {
          title: "Web Designing & Development",
          content:
            " Web designing & development is the concept of designing your website and the factual construction of the website. First, it creates and or develops the point’s frame and format, colours, and style to produce a charming and easy-to-use website. Also, we apply the point with newer technologies to make it effective, mobile-friendly, and presto. In short, the ideal is to achieve aesthetic fineness on the one hand, and business functionality on the other.",
        },
        {
          title: " Hosting & Deployment",
          content:
            "Publishing is to put your website live on the internet while planting it refers to hosting your website. While hosting can be explained as the storehouse of your website on a web garçon, deployment is the act of putting your website live. We guarantee your point is hosted securely, optimally as well and rightly configured for easy and effective end-stoner application.",
        },
        {
          title: "Testing",
          content:
            "  Testing can be defined as the process of running your website or operation through the shop to check for bugs and problems before the website or operation is live. We perform testing to assess functionality, performance, security, and usability to check that all is well. This makes it easy to correct all issues that can help druggies from having a smooth experience on the website.",
        },
        {
          title: " Maintenance & Support System",
          content:
            " Conservation and support help to keep your website or operation harmonious after the launch phase. We make updates as frequently as possible, always break any bug that appears and always respond to complaints. This ensures that your system remains safe, optimally tuned, and most importantly always current.",
        },
        {
          title: " Launch",
          content:
            "Release or launch is the last defence when your point or operation is ready to be promoted for druggies’ access. All of the web content is assured to be pushed to the limit, effectiveness, and effectiveness as a final stage before going live. When we've developed it and our platoon is ready to launch it, we also keep track of the outgrowth to ensure the systems are over and running optimally.",
        },
        {
          title: " Long Partnership",
          content:
            "Long- term means you're in business cooperation for a long time to make your company. We value our cooperation with you and constantly offer updates, further advancements, and constant gemstone-solid support for your digital tools. We aim to get your business result providers working for the life of your business.",
        },
        {
          title: "After Sales Support",
          content:
            "A successful trade generally reaches after-deals client care that will constantly support you, indeed after the deal is done. Hull assists with any problems if you bear it for updates and in general, anything from our service that causes dissatisfaction. Contrary to some companies’ practice of ending communication once a trade has been perfected, it's our gospel that we remain fluently accessible to you indeed after the trade has closed.",
        },
        // Add more steps as needed...
      ],
  },
};
