// projectData.js
const projectData = {
  home: [
    {
      title: "WEBSITE",
      description:
        "Start your website with MetaBlock, because at MetaBlock we build stunning and responsive websites. Feel free to contact our team at any time and our specialists will gladly answer all your questions, provide you with any additional information you require and help to ensure your full satisfaction with both the quality of delivered tasks and their timely delivery. We develop and optimize your websites with state of art technologies.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Blockchain",
      description:
        " Introduce yourselves to the concept of blockchain with MetaBlock, and make the newer technologies work for you and your company. They understand that our commissioned developers are some of the most skilled scientists in blockchain security and scalability. Some of the services we provide are smart contract creation, dApps creation, and blockchain implementation services.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "SOFTWARE",
      description:
        "-MetaBlock has the complete software solutions to manage all types of digital assets. Blockchain infrastructure establishment, smart contract creation, wallet integration, and designing safe and intuitive frontend interfaces are in Metablock’s tool belt. They also have the compliance features and maintenance, which is quite helpful to clients who are operating their coins and tokens. Using MetaBlock’s solutions, clients are able to operate within the rapidly evolving sphere of cryptocurrency securely, using tools designed for their specifications.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "APPLICATION",
      description:
        " MetaBlock has the complete software solutions to manage all types of digital assets. Blockchain infrastructure establishment, smart contract creation, wallet integration, and designing safe and intuitive frontend interfaces are in Metablock’s tool belt. They also have the compliance features and maintenance, which is quite helpful to clients who are operating their coins and tokens. Using MetaBlock’s solutions, clients are able to operate within the rapidly evolving sphere of cryptocurrency securely, using tools designed for their specifications.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Metaverse",
      description:
        "There has been a shift of the populace to the use of different platforms and the Metaverse is the next phase in the advancement in existence of the internet. At MetaBlock, creativity goes hand in hand with expertise making it easier to produce outstanding projects. That of course is what sets us apart; we strive towards innovation and service quality to provide experiences beyond expectations. We are here to offer you customized solutions to help you create concepts within the Metaverse.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "NFT",
      description:
        "NFTs can be the tokenized version of an actual object or completely original product. MetaBlock is a company that designs custom, secure, and unique NFTs since we understand our clients ‘ unique requirements. The best and modern practices, alongside advanced technologies, are used by our team of professionals. We have a record of previous NFT launches and have always ensured that we strive to produce top-notch results that add value to our client’s businesses.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "games",
      description:
        "This is the real world of video games! At MetaBlock, we are more interested in the fun aspects of gaming and how we make gaming fun. Our team is passionate and equipped with the newest technology to create values for the gamers with what we have, including game design, programming, and many other services. This is where we stand for creating the games with colorful graphics, awesome design, and addictive gameplay which will make it possible to spend hours in the game. It doesn’t matter if you only play ‘indie’ games, or only play the most mainstream best-sellers: we make sure it is all here. Come in and open the door to opportunities without an end and exciting experiences ever.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "DAPP",
      description:
        "MetaBlock is your gateway to decentralized applications or dApps. We offer the development of unique dApps to satisfy varied functionalities while incorporating blockchain in the overall framework to deliver seamless and secure application outputs. Our talented team collaborates with different blockchains to provide you the best, non-standard solution for your project.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
  ],

  web: [
    {
      title: "Static Websites",
      description:
        "The same content is displayed to all visitors without server-side processing or database interactions in static websites. This makes them suitable for blogs, portfolios, and company landing pages that do not need to be updated frequently or personalized to the user. However, they have slower load times and are less secure compared to dynamic websites.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Dynamic Websites",
      description:
        " Dynamic websites create content in real-time as users interact with them, input data, or specify preferences. They do not have to be manually updated for changing content and layout; thus, they are commonly used for e-commerce platforms, social media sites, and content management systems.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "E-Commerce Websites",
      description:
        "An e-commerce website is an electronic market where business purchases and sales can be performed between two or more parties. It allows transactions to take place through functionalities such as product catalogs, shopping baskets, a secured payment system, and order control. In essence, it is an online equivalent of a conventional store allowing its customers to browse and buy products while making payments on the internet effortlessly.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Business Websites",
      description:
        " A business website is a digital storefront to represent a company or organization over the Internet. It conveys information about the business, its products or services, and its values to any potential customer. Moreover, it helps in facilitating customer queries and orders, thus building communication and engagement.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Personal Websites",
      description:
        "Personal websites are actually a digital portfolio of all the skills, projects, and achievements of an individual. It is important for professionals to gain an online presence, attract potential clients, or even find employment opportunities. Thus, by emphasizing expertise and personal branding, these websites provide credibility and visibility in the digital landscape.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "News Websites",
      description:
        "At MetaBlock Technologies, our specialty is in dynamic user-friendly news websites providing users with real-time information, and our solutions can easily include features such as adjustable categories, responsive design, and also SEO optimization to bring all the benefits of an exciting online news platform together under one roof.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
  ],
  static: [
    {
      title: "HTML ",
      description:
        "HTML or HyperText Markup Language is actually the basis for developing static web pages. Elements include headings, paragraphs, images, and links. HTML structure content layout via tags such as `<html>`, `<head>`, and `<body>`. When linked with CSS in styling, they result in web pages that appear aesthetically pleasing, user-friendly, and responsive.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "CSS ",
      description:
        "  Both the building blocks to static website and HTML's strength lie within making content organized-structured element form headings and images, such that CSS forms using colors and texts, the structures and placements respectively. Through its combination of creating a solid vision and effective interactive user-friendliness from every angle.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "JavaScript ",
      description:
        " JavaScript makes a static website more interactive by providing dynamic content updation, input handling from the user, animation, and actions that are driven by events. It makes a static page engaging without a complete backend. Functionalities such as form validation, image sliders, and interactive buttons make websites functional and user-friendly, thereby increasing user engagement overall.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Frameworks ",
      description:
        " Frameworks and libraries make static site development easier through pre-written code, templates, and tools. Next.js and Gatsby frameworks improve performance by using static site generation. Bootstrap and Tailwind CSS libraries help in styling, making it simpler. These make the development more efficient, maintainable, and user-friendly and reduce the development time.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Generators",
      description:
        " Static Site Generators (SSGs) auto-generate static websites by creating pre-built HTML files from templates and content beforehand. They bring speed, security, and scalability to the table, making them perfect for blogs, documentation, and portfolios. Popular SSGs like Jekyll, Hugo, and Next.js allow developers to create fast, efficient, and easily maintainable static websites.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Optimization ",
      description:
        "Clean HTML, fast loading, mobile responsiveness, and proper meta tags are required for SEO optimization in static sites. Use of structured data, optimized images, and descriptive URLs should be employed. Sitemaps and robots.txt can help with better indexing. Internal linking and canonical tags help avoid duplication. Regular updating of content is essential, as well as HTTPS for security and ranking benefits.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Control  ",
      description:
        "Bringing in SEO version control with Git & GitHub ensures that changes tracked and versions controlled are collaborating towards rollback capabilities. A clean commit history maintained with GitHub Pages for a smooth deployment enhances stability, speed, and SEO rankings of a site. The proper branching strategy and pull requests ensure an optimized, error-free website.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Performance  ",
      description:
        "Optimization in static website development improves the speed and user experience. Key techniques include reducing HTML, CSS, and JavaScript files, browser caching, use of a Content Delivery Network (CDN), optimizing images, and enabling lazy loading. Further improvement in loading times is also realized through reducing HTTP requests and hosting on efficient hosting.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
  ],
  dynamic: [
    {
      title: "E- Commerce Websites",
      description:
        " E-commerce websites are dynamic in nature, thus offering suitable platforms for business enterprises with the aim of selling some products and services over the internet. Equipped features include product catalogs, shopping carts, secure payment gateways, and customer accounts, providing smooth and convenient shopping.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Social Networking Websites",
      description:
        " Social networking sites are dynamic; users can create profiles, share content, and interact with others in real time. Websites offer features of messaging, friend connections, content sharing, and group discussions, which create an online community that allows the engagement of the users, collaboration, and relationship building.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: " Enterprise Websites",
      description:
        "Enterprise websites are major, dynamic platforms for processing complex data and processes. They have tools for handling customer relations, employee collaborations, and secure access systems, for both internal and external users.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Portfolio Websites",
      description:
        "Portfolio websites are dynamic displays of the work of an individual or business, such as photography, art, or design. These websites help users easily update and display their latest projects, offering features like galleries, project details, and contact forms to engage visitors and potential clients.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Blog Websites",
      description:
        " Blog websites are dynamic publishing systems where individuals or businesses post articles, stories, and updates. Usually, it carries a content management system with comment areas for interaction among users and categories for arranging the posts to enable the posting and interaction with content very frequently by the user.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Educational Websites",
      description:
        "Educational websites are dynamic places providing online learning resources, courses, and interactive tools. It usually features video tutorials, quizzes, forums, and even certification options where the students can learn in their own time and engage well with educational content.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Directory or Listing Websites",
      description:
        " Directory or listing websites are dynamic platforms that organize and list businesses, services, or products in an easily searchable format. Such websites may come with filters, categories, and user reviews that allow visitors to narrow their choices based on specific needs.",
      image: "./images/application.webp",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
  ],
  ecommerce: [
    {
      title: "Types of E-Commerce Websites We Develop",
      description:
        " We design for every e-commerce need, that is B2C, B2B, C2C, and multi-vendor platforms, to present innovative, user-friendly, and scalable solutions for the business.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Business-to-Consumer (B2C) E-Commerce",
      description:
        " We develop user-friendly b2c ecommerce websites to connect the business directly to the customers. Our solutions revolve around smooth shopping, secure payment, and eye-catching design to drive sales and loyalty. ",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: " Business-to-Business (B2B) E-Commerce",
      description:
        "b2b ecommerce for businesses targeting other businesses, we develop platforms that deal with bulk orders, customize pricing, and help you manage your inventory. This is how B2B solutions streamline operations and improve productivity.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Consumer-to-Consumer (C2C) Marketplaces",
      description:
        "We specialize in the creation of c2c e commerce where people can buy and sell products. Our safe and scalable marketplaces allow for easy transactions and make it easy for users to connect.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Consumer-to-Business (C2B) Platforms",
      description:
        "We innovate new c2b e commerce to enable people to give their services or products to enterprises. Suitable for freelancers and content creators, such platforms ensure easy and smooth communication.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Subscription-Based E-Commerce Websites",
      description:
        "Our subscription-based solutions are here to enable businesses to provide recurring services or products. These sites are subscription management and renewals optimized for maximum user engagement.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Mobile-Optimized E-Commerce",
      description:
        " With the rise in mobile commerce, we develop e-commerce sites fully responsive so that they work flawlessly on devices. Our approach is mobile-first, ensuring seamless shopping at any time and any place.",
      image: "./images/application.webp",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
  ],
  software: [
    {
      title: "Testing & QA",
      description:
        "Software testing and quality assurance are essential to ensure that the product functions, behaves, and performs as required.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "IoT App",
      description:
        "Software development for Internet of Things (IoT) applications involves creating solutions that enable devices to connect, communicate, and interact over the Internet.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "UX/UI",
      description:
        "User  Experience (UX) and User Interface (UI) design are pivotal in software engineering.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Cybersecurity",
      description:
        "Cybersecurity involves embedding strong security practices from the initiation of software development into the SDLC.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Scalable",
      description:
        "Scalable software design principles emphasize building systems that will be able to handle growth in an efficient manner.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Software",
      description:
        "Customized software development is the creation, design, and maintenance of software according to the specifications of a company.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "CI/CD",
      description:
        "Continuous Integration (CI) and Continuous Delivery (CD) practices help improve the quality of code and reduce integration problems.",
      image: "./images/application.webp",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
    {
      title: "Cloud Computing",
      description:
        "Cloud computing in software development allows developers access to scalable computing resources through the internet.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
  ],
  erp: [
    {
      title: " ERP ",
      description:
        "The best thing for any business is that it customizes its ERP software to streamline its operations, increase efficiency, and enhance its decision-making process. It meets the specific needs of the business and integrates several processes otherwise reduced to manual work and lessens the data inaccuracy between them. In addition, custom solutions tend to be very flexible and scalable and can be a business booster for competitive advantages that come along the way with optimum resource management.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Modernity",
      description:
        "These days, modern ERP systems provide an all-in-one solution to a great deal of applications for finance, supply chain, and human resource management. Some of the key features include real-time analytics, automation, cloud access, scalability, and better collaboration. Thus, such ERP systems help streamline business processes, improve decision-making, & reduce operational costs, as well as optimize better resource management within organizations.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: " Cloud Tech",
      description:
        "ERP development and its associated technologies will be integrated with cloud technologies to allow data storage, processing, and access on a scalable, secure, to-be-defined by the organization's flexible cloud platform. The integration would enhance real-time collaboration among numerous sites; slash infrastructure costs required to maintain those additional sites, and make seamless upgrades available, thereby elevating business operations. Cloud-based ERP system access will broaden and extend efficiency and performance across various devices and sites.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "security",
      description:
        "To protect critical business information, ERP system security is vital. Regular vulnerability analysis, solid authentication, data encryption, least-privilege access, software updates, and comprehensive backup strategies are best practices in software development. Continuous watching and training also improve the measures.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Industry",
      description:
        "The customized ERP solutions offer tailor-made features for every industry to suit the unique needs of businesses. It increases efficiency while improving the accuracy of data, streamlining workflows, and offering real-time insights. Better compliance, lowered operational costs, and enhanced overall productivity are offered through customization for each industry-specific more effective approach.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "APIs",
      description:
        "Third-party applications integration into ERP supports the automation of business processes through the data flow within systems. Such would create seamless communications between platforms without manual entries into the system, improve decision-making with immediate insights, and improve scalability. This forms the basis of enhancing automation, increased productivity, as well as better customer experience with business agility.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Trends/CD",
      description:
        "The software used for enterprise resource planning changes with AI, machine learning, and automation, which allows smarter decision-making and more streamlined operations. Cloud-based systems allow for greater flexibility and scalability. Such applications integrated with IoT and big data are more capable of providing real-time insights. ERP software is likely to become even more user interface- and personalization-oriented in the future.",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
    {
      title: "AI",
      description:
        "The enhancement of enterprise resource planning tools with artificial intelligence and machine learning is primarily automation, data analytics, and predictive trends. These sophisticated technologies permit organizations to make well-informed decisions, manage resources better, and therefore increase efficiencies. When AI-driven ERP learns patterns and gives real-time witnessing, it causes improved accuracy, low costs, and clears workflow in business processes.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
  ],
  lms: [
    {
      title: " LMS ",
      description:
        "It is the digital platform of the LMS Learning Management System that permits organizations to deliver, manage, &  track training and educational content. It has made online learning possible, course administration easy, and learner engagement top-notch. An LMS also features assessments, progress tracking, and certifications to improve efficiencies and outcomes in learning.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "BENEFITS ",
      description:
        "Collectively holding online classes would make the LMS more engaging and foster real-time communication between all instructors and their students. It will help with collaboration, instantaneous feedback, and personalized learning. A live session is much more engaging and allows for flexible scheduling and easy access to expert-led classes, while also being extremely effective in improving retention and motivation.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "LIVE ",
      description:
        " best free LMS for teachers - Students can participate effectively in live classes scheduled in their learning management systems (LMS) with interactive real-time feedback and a dynamic environment for learning. Students are expected to pose questions in front of peers, engage with them, and participate actively while learning so that their learning process is much more engaging, personalized, and effective.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "CORPORATE ",
      description:
        "Live Class Software introduces a new dimension in corporate training by making the learning experience seamless. It can share the benefits of Learning Management Systems with live, real-time, interactive sessions, allowing all employees to interface with trainers, peers, and materials on demand. This will help to retain knowledge and develop skills even further and create a more effective environment for it all.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "FUNCTION",
      description:
        "Some of the prominent LMSs like Moodle, Canvas, and Blackboard university facilities would bring about live class functionality. All these three have been a great plus for using online interactive sessions. Where Moodle may be third-party flexible, Canvas proves very system-friendly and goes well with video integration. Blackboard brings out great administrative tools. All three ensure great efficiencies in virtual learning environments.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "STREAM",
      description:
        " Live streaming is one of the features of Learning Management Systems that makes learning more immersive. It allows instantaneous interaction and feedback, enabling a personalized environment for students. They can be a part of live sessions where they are free to ask questions and engage with fellow students, hence fostering closer relations between remote and classroom learning dynamics for a more in-depth understanding.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "TROUBLE",
      description:
        "LMS live class integration troubleshooting issues may include connectivity problems, and audio, visual, or logging errors. Such issues may result from unstable internet connections, incompatibility with the device, outdated software, and so on. Browser cache clearing, checking plugin settings, and ensuring correct user permissions may also prove beneficial. System updates and collaboration with the support team tend to eradicate any persistent issues.",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
    {
      title: "CUSTOM",
      description:
        "Customizing LMS platforms for live classes would enable sectors such as education, healthcare, and corporate training to have an improved experience in learning delivery. Customized features like real-time involvement, multimedia support, and scalable content delivery will ensure that learners engage and have a meaningful transfer of their knowledge. This creates made-for-each-other enhancements that are specific to industries, promoting better learning outcomes and making learning more relevant.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
  ],

  astrology: [
    {
      title: " Feature ",
      description:
        " Modern best kundli matching software provides various features, including detailed natal charts, transit tracking, compatibility analysis (synastry), and personalized horoscopes, allowing one to have sharp insights and work interactively in the domain of astrological search.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Integrating ",
      description:
        "This incorporation of artificial intelligence in astrology software enhances the accuracy of any predictions generated from analyzing increasingly complex patterns in automated chart interpretations. Such capabilities would allow personalized insights and more efficient astrological readings for users with high levels of sophistication in computational methods.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "Development ",
      description:
        " Best kundli software - Astrology mobile application development is then that type of mobile application that is built to serve with the features of highly interactive and multi-responsive applications where users are expected to have real-time personal horoscopes, zodiac insights, and even keep a tabulated record of celestial events in their smartphones-keeping them in sync with the likes of journaling-as experience.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "UE Tool ",
      description:
        "User Experience in Astrology Tools User experiences with astrology tools often revolve around intuitive interfaces for navigation and engagement. Prioritizing simplicity, personalized insights, and aesthetically pleasing design increases usability and encourages building meaningful connections.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Custom",
      description:
        "Customization enables astrological software to be equipped with features specific to various practices, such as Individual kundli, Vedic or Western astrology. Such customizations also ensure that users enjoy personalized charts, calculations, and interpretations relevant to different astrological methodologies and user preferences.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Data",
      description:
        "  The merging of planetary databases, ephemeris calculations, and time zone support all for astrologically precise charts that bring analysis of cosmic patterns to astrologers that are location and time-specific.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Language",
      description:
        "It encourages the global user base to discover astrological insights using personalized language in astrology software by enabling multi-language support. As a result, it develops inclusivity and personalizes across varied cultures and languages.",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
    {
      title: "Monetize",
      description:
        "Subscription plans for future forecasts, in-app purchases for advanced features, or freemium models of basic free services with paid premium tools or insights are some possible options for monetizing astrology software.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
  ],
  travel: [
    {
      title: " VIRTUAL ",
      description:
        " Virtual assistants and chatbots immediately attend to client concerns by handling their inquiries and providing solutions to their problems. They help facilitate the booking of services, manage appointments, and process transactions for a seamless 24/7 service experience for users across different platforms.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "ALERT ",
      description:
        "Live travel update keeps the traveler well-informed about flight cancellations, delays, and weather updates. These alerts inform a traveler of the possible changes that he/she can make to the original plan before it comes and try to minimize the inconveniences caused by it during the travel.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "PORTALS ",
      description:
        " Custom travel portals provide personalized booking solutions such as online flight, hotel, and tour reservations with seamless user experiences. Such portals can be customized to meet travelers' specific requests so they can plan the trip smoothly using tailored options and choices in traveling.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "APP ",
      description:
        "Travel apps should have a complete travel backup solution right from the booking to the itinerary management, offer real-time updates, facilitate easy access to the reservations, and be super convenient with unique personalization features. All in all, travel apps have made it smooth when it comes to planning a trip. Traveling has become that much easier, more comfortable, and less stressful because of the technology it offers for the entire world.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "CRM",
      description:
        "CRM solutions provide travel businesses with personalized experiences, track their preferences, and streamline communication making customer engagement richer. Managing relationships effectively, CRM tools boost customer retention, enhancing loyalty and repeat bookings. Their tools seek greater success and growth in travel.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "GDS",
      description:
        " Such integration provides both online travel agencies and travel agencies with access to global distribution systems to facilitate real-time booking and reservation of flights and accommodations. Enables optimized operations, and competitive pricing, and ups your customer experience with seamless global inventory management.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "ENGINES",
      description:
        "Automated systems that offer the ability to schedule appointments are called booking engines fast, secure, and scalable. They create efficient platform flows for booking user experiences in addition to fast and dependable online booking procedures that handle thousands of transactions yet stand firm against the many malicious threats against data endured by the company and consumers alike.",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
    {
      title: "PAYMENT",
      description:
        "Payment gateways can deal wita h variety of online transaction methods safely and easily process payments. They are very much into various methods of payment such as credit cards, digital wallets, direct bank transfers, and so on. They are reputable and easy to go for customers and businesses.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
  ],
  restaurant: [
    {
      title: " Sale ",
      description:
        " Sales-focused restaurant software development involves the provision of such solutions that would facilitate order management, differentiate management, and payment entry. Using these tools, it becomes much easier to deliver a pleasing customer experience, improve efficiency, and drive revenues, such as offering a promotion, real-time analytics, or integration.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Online ",
      description:
        "Intro - Online restaurant software development has created a lot of simplicity in the ordering, payment, inventory, and customer management processes. Such software optimizes solutions for the coordination of staff, installs online ordering systems, and real-time updates to increase customer experience and the revenue that modern restaurants make.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "Reserve  ",
      description:
        "  Essentially modern hospitality software streamlines the process of restaurant reservation system creation in a restaurant. It allows customers to book tables online, manage availability, and optimize seating. This benefits the patrons by improving customer experience and reducing waiting periods at the same time. It also offers efficiency to the restaurant's operations and staff.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Marketing ",
      description:
        "Optimizing marketing through restaurant applications emphasizes the development of customer engagement-enhancing tools, streamlining processes, and improving online presence. Below are a few attributes of such software: reservation systems, loyalty system management, and analytics helping restaurants target customers more effectively, optimize marketing initiatives, and ultimately increase sales.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Data",
      description:
        " Restaurant management software development makes performance great by offering inventory management, sales, customer intelligence, browsing, and analyzing the real-time. It gives freedom to the restaurant in effective workflow management, better delivery of customer services well, and waste minimization making decisions according to statistics for sustained growth and prosperity.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Digitaly",
      description:
        " With digital restaurant software development, they provide a comprehensive range of solutions such as point-of-sale systems, online ordering systems, inventory management, etc. with modern technology that improves the operation and customer experience to uplift profits for any category of restaurant",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Recipe",
      description:
        "This restaurant recipe management software organizes ingredient lists, builds a portion control system, and provides cooking directions for streamlined operations. The program ensures consistency in quality, reduces kitchen wastage, and improves cost control to allow chefs to prepare high-quality dishes while keeping inventories optimized and the overall kitchen functioning as efficiently as possible.",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
    {
      title: "Delivery",
      description:
        "Online food delivery software improves order management, fulfillment, and customer communication. This helps integrate online platforms to optimize the delivery route and improve efficiency to deliver to customers right on time with their satisfaction supplemented by a real-time update facility, payment processing, and communication made easy between staff and drivers.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
  ],

  mobileapp: [
    {
      title: " Game ",
      description:
        " Welcome to our world of exciting games! We focus on creating fun and immersive gaming experiences. With the latest technology and a dedicated team, we offer services like game design, programming, and more. We take pride in making visually amazing games with great graphics and gameplay that keep players hooked for hours. Whether you're into indie games or big hits, we have something for everyone. Be a Part of our world full of imagination and unforgettable adventures.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Websites Development ",
      description:
        "Launch your new website with MetaBlock! We specialize in creating beautiful and highly functional websites. Our team is dedicated to making sure you're happy with the results by keeping communication clear, delivering on time, and providing ongoing support. We use the latest technology to ensure your website performs at its best.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "Blockchain  ",
      description:
        "  Step into the world of blockchain and use the latest technology to stay ahead of your competition.We are an expert  in creating secure and scalable blockchain solutions. We offer services like developing smart contracts, building decentralized apps (dApps), and integrating blockchain into different industries.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Software ",
      description:
        "MetaBlock offers complete software for managing digital assets, including blockchain setup, smart contracts, wallet integration, and security. We also provide compliance and ongoing support, helping clients easily launch and manage their coins and tokens in the cryptocurrency world.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Applications",
      description:
        " MetaBlock creates custom solutions for unique projects, ensuring high quality and reliability. We offer infrastructure setup, system integration, and mobile app development tailored to your needs. Using the latest technology and best practices, we deliver top-quality app developers for startups that exceed expectations and help clients succeed in the digital world.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Metaverse",
      description:
        "  The Metaverse is the next big step in the internet, blending the digital and physical worlds. Our team uses creativity and technical skills to make each project unique and exciting. What makes us different is our focus on innovation and quality, creating experiences that go beyond what you expect. We offer personalized solutions to help bring your ideas to life in the Metaverse.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "NFTs",
      description:
        "NFTs are unique digital assets that can represent real-world items. We create secure, custom NFTs using the latest technology. Our experienced team ensures high-quality, innovative projects. With a strong track record of successful launches, we deliver results that exceed expectations and provide real value for our clients.",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
    {
      title: "Dapp",
      description:
        "Step into the world of decentralized apps. We specialize in creating custom apps that integrate blockchain technology smoothly. Our team is skilled in different blockchain platforms, offering flexible and scalable solutions to bring your idea to life.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
  ],
  pos: [
    {
      title: "Functionality",
      description:
        "Best pos system for small business: software that manages sales transactions, inventory, and customer data. It allows for efficient billing, tracks stock levels, generates reports, processes payments, and provides insights into business performance, improving operational efficiency and customer experience.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Integration",
      description:
        "POS software integration streams business operations through the connection of a point-of-sale system with other software applications that could include inventory management, accounting, and customer relationship software. This smoothens efficiency and reduces manual errors while giving real-time data for better decision-making and smoother transactions.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "Security",
      description:
        "POS software security entails the protection of the sensitive data of customers such as credit card information. It involves encryption, updating regularly, secure payment gateways, and controlling user access in order not to have breaches, fraud, or unauthorized access, thereby ensuring the transaction is safe and sound.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Reporting ",
      description:
        "POS software reporting offers businesses detailed information on sales, inventory, and customer behaviour. It helps in tracking performance, managing stock levels, and identifying trends, thereby allowing data-driven decisions to optimize operations, boost profits, and enhance customer experience.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Support",
      description:
        " POS software support ensures the smooth operational functionality of point-of-sale systems through technical support, troubleshooting, software updates, and system maintenance. This ensures quick resolution of problems, lowers operational inefficiency and ensures reliable transaction processingtoo maintain a smooth customer experience.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Pricing",
      description:
        "The common pricing models for POS software include subscription-based, one-time payment, or usage-based structures. Subscription models give ongoing updates and support, while a one-time payment requires upfront costs. Usage-based pricing adapts to business volume, offering flexibility for varying transaction levels.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Performance",
      description:
        "Efficient performance of POS software is important for effective business activities. Quick processing, low downtime, and smooth integration with other systems ensure smooth transactions. A high-performance POS improves the customer experience, reduces errors, and enhances the overall efficiency of the operation.",
      image: "./images/application.webp",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
    {
      title: "Scalability",
      description:
        "It's the ability to grow with your business. POS software scalability simply means that if sales volume increases, locations and users increase; scalable POS systems can handle that load, with flexibility, which ensures smooth running without compromising either performance or functionality.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
  ],
  inventory: [
    {
      title: "Features ",
      description:
        "Best Inventory management software makes it easier to manage stock through real-time tracking, automated reorder alerts, integration of barcodes, reporting, and analytics. This helps in optimal inventory levels, reduced errors, increased efficiency, and better decision-making for any business.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
    {
      title: "Challenges",
      description:
        " Inventory management faces the challenges of inaccuracy in demand forecasting, overstocking or stockouts, high storage costs, supply chain disruptions, and tracking inefficiencies. The balancing of inventory levels with customer demands requires efficient systems and real-time data analysis.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Technology ",
      description:
        "The new technologies in inventory management, such as AI-driven analytics, IoT-enabled tracking, and cloud-based systems, ensure accuracy, streamlined operations, and optimized stock levels, ensuring efficiency, cost reduction, and better decision-making in modern supply chains.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "Customize ",
      description:
        "Customization and scalability in inventory management allow businesses to adapt systems to unique needs, making it easy to handle growth. Tailored solutions improve efficiency, while scalable systems ensure smooth operations as inventory demands evolve, supporting long-term success.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Adoption  ",
      description:
        "Effective implementation and adoption in inventory management require sound technology integration, staff training, and streamlined processes. Data accuracy, real-time tracking, and adaptability ensure smooth operations, prevent errors and improve decision-making for optimal control of inventory.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Software ",
      description:
        " Select your business needs, scalability, and budget to choose the right inventory management software. Features such as real-time tracking, integrations, and user-friendliness should be prioritized. Read reviews, test demos, and ensure reliable customer support before finalizing.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Valuation ",
      description:
        "The three most common inventory valuation methods in inventory management are FIFO, LIFO, and Weighted Average Cost. These determine the cost of inventory and influence financial reporting, taxation, and profitability analysis.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Strategy ",
      description:
        "Inventory control strategies in inventory management optimize stock levels to meet demand while minimizing costs. The major methods include JIT, ABC analysis, EOQ, and safety stock, which assure efficiency, reduction of waste, and satisfaction of the customer.",
      image: "./images/application.webp",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
  ],
  accounting: [
    {
      title: "Requirement ",
      description:
        "To complete requirement analysis in accounting and billing software development requires requisite determination of user needs, knowledge of business processes, and definition of original or technical requirements in a way that aligns solutions with organizational goals to boost effective financial management.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
    {
      title: "Design",
      description:
        " The system design of accounting and billing software entails the creation of an integrated solution for financial transactions, invoicing, and reporting, delivering data integrity, security, and compliance with due standards of regulatory authority while ensuring a simple interface for businesses for increased efficiency in financial management.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Management ",
      description:
        "Database management is important in accounting and billing software development, guaranteeing secure, perfect, and accurate handling of financial data, automating processes, and enabling real-time reporting for businesses.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "User Interface ",
      description:
        " User Interface development in accounting and billing software focuses on the creation of intuitive, efficient, and user-friendly interfaces that make data entry, financial management, and reporting tasks easier, thereby enhancing accuracy and productivity for users.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Integration   ",
      description:
        "Coding and billing software is compatible with other systems such as CRM, ERP, and payment gateways. These enhance data accuracy, streamline workflows, and improve financial management by providing a synergistic platform for effective communication and efficient operations.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Security  ",
      description:
        " Security and compliance are highly prioritized in the development of accounting and billing software. Protection of sensitive financial information, including GDPR, SOC 2, and HIPAA compliance considerations, is essential. Stronger encryption, regular security audits, and secure coding practices guarantee software compliance with industry standards and the protection of user data. ",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Customization ",
      description:
        "Automation and customization in accounting and billing software optimize the financial process, including the increasing accuracy of transactions and enhancing the overall customer experience. This reduces the time taken to effectively make financial transactions, generate reports and provide customizable solutions that upscale the organization's financial management. ",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Testing  ",
      description:
        "Testing and Quality Assurance in accounting and billing software development ensure accuracy, security, and efficiency. It means running functionality tests such as transaction processing, data validation, and conformity with financial standards. QA strives for seamless performance to eliminate errors and increase user faith.",
      image: "./images/application.webp",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
  ],
  employee: [
    {
      title: "Functionalities ",
      description:
        "Employee tracking software contains core features such as time tracking, attendance management, task assignment, and performance monitoring. It further covers real-time location tracking and automated reporting along with project management integration. The rest of the key features are leave management, payroll tracking, and communication tools. All this helps streamline HR operations, boost productivity, and ensure compliance.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
    {
      title: "Technology ",
      description:
        " Technologies involved in the development of employee tracking software include Python, JavaScript, and frameworks such as Django or React. Common tools used are Firebase for real-time data, AWS for cloud hosting, and MySQL for database management. Integrating third-party APIs and tools such as Slack or Zapier also enhances productivity and communication features.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: "Security  ",
      description:
        "For sensitive information stored in employee tracking software, security and compliance are crucial. Encryption, multi-factor authentication, and regular security audits all ensure privacy regarding data. Compliance with regulations like GDPR and HIPAA will act as a safeguard for personal information. Conformity with industry standards and best practices minimizes risks, gaining trust and legal compliance.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: "User Experience ",
      description:
        " In employee tracking software development, UX and interface design focus on intuitive, user-friendly experiences. The usability of the application is improved through clear navigation, simple dashboards, and easy-to-understand data visualizations. Responsive design, seamless integration, and minimalistic interfaces ensure that employees can efficiently track tasks, hours, and performance, thus improving engagement and productivity.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: "Challenges",
      description:
        " It involves the challenges that come with surveillance, invasion of privacy, as well as exposure to data risks. Ethical concerns include abuse of surveillance in the workplace and over-monitoring, which slowly erodes employer-employee relationships. Developers, therefore, should ensure efficiency balanced with respect for individual privacy in addition to full compliance with all data protection regulation requirements to garner trust.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Future   ",
      description:
        "  Future trends in employee tracking software are towards AI-driven analytics, performance insights in real-time, and maximizing employee privacy. More extensions will be towards cloud integration, mobile accessibility, and support for remote work. Tools will be expected to include behaviour analysis, wellness tracking, and integration with other business systems, making the system more personalized, and efficient workforce management. ",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
    {
      title: "Integration  ",
      description:
        "The integration of employee tracking software with other business systems will enhance efficiency and data consistency. Seamless connectivity with HR, payroll, and project management tools will automate workflows, reduce manual entry, and ensure real-time updates. API integrations and cloud-based solutions improve accessibility, allowing businesses to streamline operations and make data-driven workforce decisions effortlessly.",
      image: "./SGimage/playto.webp",
      buttons: ["Static Website", "Dynamic Website", "E-commerce Website"],
    },
    {
      title: "Engagement   ",
      description:
        " Employee tracking software should focus on engagement and well-being by providing transparency, real-time feedback, and wellness insights. Features such as productivity analytics, stress detection, and personalized support help to create a positive work environment. Pulse surveys, recognition systems, and flexible tracking are integrated into the system to ensure that performance monitoring is balanced with employee satisfaction, thus enhancing overall morale.",
      image: "./images/application.webp",
      buttons: [
        "Application Development",
        "Travelling Application",
        "Social media application",
        "Grocery Application",
        "Fitness application",
      ],
    },
  ],
  hrm: [
    {
      title: "Core HR Functions ",
      description:
        "Core HR functions are essential for managing hand records, payroll, attendance, leave, and benefits administration. They ensure compliance, streamline HR operations, and enhance pool effectiveness. robotization in HRMSS reduces crimes, saves time, and improves hand experience. By handling these abecedarian processes effectively, associations can concentrate on strategic HR enterprise, fostering a productive and well-managed pool.",
      image: "./SGimage/dapp.webp",
      buttons: ["Dapp Development", "Tron Dapp Development", "Ethereum Dapp"],
    },
    {
      title: "Recruitment & Onboarding ",
      description:
        "  Reclamation and onboarding are crucial HRMSS functions that help attract, hire, and integrate new workers efficiently. The reclamation module includes job advertisement, aspirant shadowing, capsule webbing, and interview operation. Onboarding ensures a smooth transition for new hires through digital attestation, training, and exposure. Automating these processes enhances hiring speed, improves seeker experience, and ensures compliance with company programs.",
      image: "./SGimage/gamerimg.webp",
      buttons: [
        "Skill Game",
        "TeenPati Game",
        "Ludo Game",
        "Fantasy Game",
        "Callbreak Game",
        "Rummy Game",
      ],
    },
    {
      title: " Performance Management  ",
      description:
        " Performance Operation in HRMSS focuses on assessing and enhancing hand productivity through thing setting, performance reviews, feedback, and skill development. It includes tools for tracking KPIs, conducting 360-degree feedback, and managing training programs. robotization ensures fair assessments, boosts engagement, and aligns individual performance with organizational pretensions, eventually driving business growth and hand development.",
      image: "./SGimage/metaverse.webp",
      buttons: [
        "Smart Contract",
        "Private/Public Blockchain",
        "Blockchain For Industries",
        "POC Development",
        "Enterprise Blockchain",
      ],
    },
    {
      title: " Workforce Management ",
      description:
        " Pool operation in HRMSS ensures effective planning, shadowing, and optimization of hand productivity. It includes shift scheduling, workload operation, time shadowing, and hand engagement tools. By automating these processes, associations can enhance effectiveness, reduce labour costs, and ameliorate compliance. A well-managed pool leads to better resource allocation, increased hand satisfaction, and overall business success.",
      image: "./SGimage/blockchainimg.webp",
      buttons: [
        "Decentralization",
        "Immutability",
        "Transparency",
        "Security",
        "Smart Contracts",
      ],
    },
    {
      title: " Compliance & Security",
      description:
        "  Compliance and security in HRMSS ensure that associations cleave to labour laws, assiduity regulations, and data protection programs. This includes managing hand records securely, administering access controls, and automating compliance reporting. Robust security measures protect sensitive HR data from breaches, while compliance features help avoid legal pitfalls. A strong compliance and security frame enhances trust, reduces arrears, and ensures smooth HR operations.",
      image: "./images/software-crypto.webp",
      buttons: [
        "Software Development",
        "HRMS Software",
        "SAAS Software",
        "Matrimonial Software",
        "Hotel Management",
      ],
    },
    {
      title: "Self-Service & Automation",
      description:
        "  Tone- service and robotization in HRMSS empower workers to manage their HR tasks singly, similar as streamlining particular details, applying for leave, penetrating payroll, and submitting requests. robotization streamlines workflows, reduces executive burden, and enhances effectiveness. Features like chatbots, digital blessings, and AI- driven perceptivity ameliorate HR responsiveness and hand experience, fostering a more nimble and productive plant.",
      image: "./SGimage/neft.webp",
      buttons: [
        "NFT Marketplace",
        "NFT Game Development",
        "NFT Token Development",
        "White Label NFT Solutions",
        "Launchpad Solutions",
      ],
    },
  ],
};

export default projectData;
