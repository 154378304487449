import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";

import EmployeeSwiperSlider from "./SwiperSlider/EmployeeSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import OurStepByStep from "../../../components/OurStepByStep";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
import SeoHelmet from "../../../Helmet";
import FAQSection2 from "../../FAQSection2";
import OurProcedureNew from "../../OurProcedureNew";
import OurProjects from "../../OurProjects";
import projectData from "../../projectData";
// import '../../main.css'

const EmployeeTrackingSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Advanced Employee Monitoring Software Development | Boost Productivity & Engagement"
          description="Develop cutting-edge employee monitoring software development with real-time analytics, productivity insights, and well-being features. Enhance workforce efficiency, engagement, and transparency with customized solutions tailored to your business needs. Optimize performance while ensuring employee satisfaction."
          keywords="Employee Monitoring Software Development"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                    Employee Monitoring Software Development Company 
                    </h1>
                    <p className="heading-tagline">
                    MetaBlock is a leading software development company for innovatively employee-monitoring software development. We at MetaBlock design highly productive and time-saving software that helps employers track their teams, optimize time tracking, and make the best use of workforce management. Our customized software enables businesses to monitor attendance, track remote teams, and gain real-time insights into employee performance. Our customizable software with a focus on security, compliance, and seamless integration enables organizations to improve their efficiency and make data-driven decisions. Whether you happen to be a small business or an enterprise, our solutions are customized to meet the exact needs you require.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Specialist In Employee Tracking Software
                </h2>
                <p style={{ fontSize: "20px" }}>
                  A Specialist in Employee Tracking Software Development is an
                  expert in designing, developing, and implementing digital
                  solutions that help businesses monitor workforce activities,
                  productivity, and attendance. They have an in-depth
                  understanding of real-time tracking technologies, geolocation
                  services, biometric authentication, and data analytics. Their
                  expertise is designed to enable seamless integration with HR
                  systems and compliance with labour regulations while achieving
                  greater operational efficiency. Whether developing tracking
                  apps based on GPS, time management tools, or indeed AI-driven
                  performance analytics, these experts empower businesses with
                  the required insights to optimize workforce management and
                  increase overall productivity.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/trakingdeva.webp" alt="trakingdeva" />
              </div>
            </div>
          </section>

          <EmployeeSwiperSlider />
          <KeyFeatures
            heading="Key Features Of Employee Tracking Software "
            description=" Key Features of Our Employee Tracking Software Development: Custom Solutions for Maximum Impact"
            filterKey="employee"
          />
          <TechnologyStack />

          <Industries
            category="employee"
            heading="Industries That Use Our Software Solutions" // Dynamic heading for software
          />

          <AdminControlFeatures
            category="pos"
            heading="Admin Control Features" // Dynamic heading for admin control
          />
          
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>

          <section>
            <OurProjects
              projectData={projectData}
              heading="Our Projects: A Showcase of Impactful Solutions"
              description="View different categories that depict the most advanced services provided by our company. Based on dynamic web development, mobile applications, gaming, and blockchain, these projects represent the best samples of our work. No matter if you are seeking a game, NFT, Metaverse experience, or enterprise software, our engaged team is involved in turning your ideas into reality using cutting-edge technologies. Check out the portfolio page of MetaBlock see some of the successful projects and learn how ideas are brought to life."
              filterKey="employee" 
            />
          </section>
          <section>
            <SimilarFile />
          </section>
          <FAQSection2 category="employee" />
          <OurProcedureNew category="inventory" />
        </div>
      </div>
    </>
  );
};

export default EmployeeTrackingSoftware;
