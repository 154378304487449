import React, { useState, useEffect, useRef } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { postData } from '../../Api/Clientfunctions';


const ContactForm = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const contactFormRef = useRef(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowContactForm(false);

      // This is where you set the aria-label without jQuery
      document.querySelectorAll('.btn-light').forEach(element => {
        element.setAttribute('aria-label', 'Contact Details');
      });
    });

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contactFormRef.current && !contactFormRef.current.contains(event.target)) {
        setShowContactForm(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [contactFormRef]);

  const toggleContactForm = () => {
    setShowContactForm(prevState => !prevState);
  };



  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: "",
    phone: "",
  });


  const handleInputChange = (e) => {
    const { name, value, email, country, phone, message } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      [email]: value,
      [phone]: value,
      [message]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.type = 0;
    console.log("Form data:", formData);
    const res = await postData("/contact/submit-enquiry", formData)
    console.log(res)
  };


  return (

    <li className="HddrBtns co web_menu_btn_sec" ref={contactFormRef}>
      <button
        className="btn menu_btn button_christ"
        id="proposal_btn"
        onClick={toggleContactForm}
      >
        {showContactForm ? 'GET A PROPOSAL' : 'GET A PROPOSAL'}{' '}
        {/* <img
          src="https://www.metablocktechnologies.com/asset/img/reindeer.webp"
          className="img-fluid raindeer_img"
          alt="raindeer-img"
          width="22"
          height="22"
        /> */}
      </button>

      <article 
  className="contact_form_mainsec mt-2" 
  id="contact_form_main" 
  style={{ display: showContactForm ? "block" : "none" }}
>
  <div className="topNewContact w-[305px] text-center">
    <p className=" text-xl sm:text-2xl md:text-3xl font-semibold pt-2">Let's quickly bond</p>
  </div>

  <div className="relative w-[305px] max-w-screen-sm mx-auto">
    <iframe 
      className="w-full h-[450px] sm:h-[450px] md:h-[450px] lg:h-[450px] xl:h-[450px] rounded-b-md" 
      src="https://metablock-software.metablocktechnologies.org/forms/wtl/fd876e62bfe1d7911b0e2be0ddb895fb" 
      frameBorder="0" 
      sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin allow-popups" 
      allowFullScreen
    ></iframe>
  </div>
</article>


      {/* <FaWhatsapp /> */}


      {/* Your script tag */}
      <script type="text/javascript">
        {`
          setTimeout(function () {
            $(window).on('load', function () {
              $('.btn-light').attr("aria-label", "Contact Details");
            });
          }, 2000);
        `}
      </script>
    </li>
  );
};

export default ContactForm;
