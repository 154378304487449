import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import HRMSSwiperSlider from "./SwiperSlider/HRMSSwiperSlider";
import faqData from "../../faqData";
import FAQSection2 from "../../FAQSection2";
import TechnologyStack from "../../../components/TechnologyStack";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
import SeoHelmet from "../../../Helmet";
// import '../../main.css'

const HRMSSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Custom HR Management Software Development"
          description="Streamline your HR processes with Custom HR Management Software Development. From payroll and recruitment to performance tracking, we develop scalable HR management solutions customise to your business needs. Contact us today."
          keywords=""
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      HR Management Software Development Company
                    </h1>
                    <p className="heading-tagline">
                      Welcome to MetaBlock Technologies, the leading software
                      development company specializing in cutting-edge Human
                      Resource Management Software Solutions (HRMSS). We're
                      committed to changing the way pool operation is approached
                      and empowering associations to streamline their HR
                      processes, optimize productivity, and drive growth through
                      invention.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Specialist In HR Management Software
                </h2>
                <p style={{ fontSize: "20px" }}>
                  MetaBlock Technologies provides state-of-the-art Human
                  Resource Management Software results( HRMSS) to help with the
                  simplification and improvement of HR operations from
                  reclamation to payroll, hand engagement, and more. All this
                  with our intuitive and highly customized tools so that
                  businesses work smarter, not harder. With a passionate, expert
                  team, we understand what you need and provide tailored
                  solutions to fit your unique goals. Discover a better way to
                  manage your workforce with us.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img
                  src="./images/devanshusoftware-4.jfif.webp"
                  alt="managmentsoft"
                />
              </div>
            </div>
          </section>

          <HRMSSwiperSlider />
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=""
            filterKey="hrm" // Pass 'software' as filterKey
          />
          <TechnologyStack />

          <Industries
            category="hrm"
            heading="Industries Using Our HRMSS Software"
          />

          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>
          <OurProjects
            projectData={projectData}
            heading="Explore our impressive portfolio showcase."
            description=" We offer a range of services including web development, Flutter app development, game development, software development, mobile app development and blockchain development."
            filterKey="hrm" 
          />
          <section>
            <SimilarFile />
          </section>
          <FAQSection2 category="hrmsoftware" />
        </div>
      </div>
    </>
  );
};

export default HRMSSoftware;
