// // OurProjects.js
// import React from "react";
// import RelatedPortfolioSlider from "./MainPage/RelatedPortfolioSlider";

// const OurProjects = ({ projectData, heading, description }) => {
//   return (
//     <div className="browse-our-impactful MtaverseSec DapsMainBg OurPrjctBg">
//       <div className="container container-1170">
//         <div className="row">
//           <div className="col-xl-10">
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//               className="OurSrvcHdd mb-3"
//             >
//               <h2
//                 className="title our-service-heading"
//                 style={{
//                   color: "purple",
//                   margin: "0px 0px 0px 0px",
//                   width: "100%",
//                 }}
//               >
//                 {heading} {/* Dynamic heading */}
//               </h2>
//             </div>
//             <div
//               className="Browse-impactful BnrCnt OurservHdd mb-4 OurPrjctHddSec"
//               style={{ textAlign: "center" }}
//             >
//               <p className="text-left">
//                 {description} {/* Dynamic description */}
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="Portfolio-buttons col-lg-2">
//             <div
//               className="nav flex-column nav-pills VrtclTbb PrjctTb"
//               id="v-pills-tab"
//               role="tablist"
//               aria-orientation="vertical"
//             >
//               {projectData.map((project, index) => (
//                 <button
//                   key={index}
//                   className={`nav-link ${index === 0 ? "active" : ""}`}
//                   data-toggle="pill"
//                   data-target={`#PrjctTbb${index + 1}`}
//                   type="button"
//                   role="tab"
//                   aria-controls={`PrjctTbb${index + 1}`}
//                   aria-selected={index === 0}
//                 >
//                   <h3 style={{ fontSize: "1.2rem" }}>{project.title}</h3>
//                 </button>
//               ))}
//             </div>
//           </div>
//           <div className="col-xl-10">
//             <div className="tab-content" id="v-pills-tabContent">
//               {projectData.map((project, index) => (
//                 <div
//                   key={index}
//                   className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
//                   id={`PrjctTbb${index + 1}`}
//                   role="tabpanel"
//                 >
//                   <div>
//                     <div className="PrjctDtlsItm">
//                       <div className="row align-items-center">
//                         <div className="col-lg-7">
//                           <div className="PrjctDtlCnt">
//                             <h3 className="port_sub_title">{project.title}</h3>
//                             <p>{project.description}</p>
//                           </div>
//                         </div>
//                         <div className="col-lg-5">
//                           <div className="PrjctImg text-center">
//                             <img
//                               src={project.image}
//                               alt={project.title}
//                               title={project.title}
//                               loading="eager"
//                               className="img-fluid lazyload"
//                               width="70%"
//                             />
//                           </div>
//                         </div>
//                       </div>
//                       <div className="PrjctsBtnSec">
//                         {project.buttons.map((button, btnIndex) => (
//                           <div className="mr-4 mb-4" key={btnIndex}>
//                             <div className="PrjctBtn_new Btn211-42">
//                               {button}
//                             </div>
//                           </div>
//                         ))}
//                       </div>
//                       <div className="RltdPrtflo">
//                         <div className="RltdPrjHddSec">
//                           <div className="RltdPrjHdd">
//                             <div className="h5_title">Related Portfolio</div>
//                           </div>
//                         </div>
//                         <RelatedPortfolioSlider />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OurProjects;




import React from "react";
import RelatedPortfolioSlider from "./MainPage/RelatedPortfolioSlider";

const OurProjects = ({ projectData, heading, description, filterKey }) => {
  // Filtered data based on the filterKey prop
  const filteredData = projectData[filterKey] || [];

  return (
    <div className="browse-our-impactful MtaverseSec DapsMainBg OurPrjctBg">
      <div className="container container-1170">
        <div className="row">
          <div className="col-xl-10">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="OurSrvcHdd mb-3"
            >
              <h2
                className="title our-service-heading"
                style={{
                  color: "purple",
                  margin: "0px 0px 0px 0px",
                  width: "100%",
                }}
              >
                {heading} {/* Dynamic heading */}
              </h2> 
            </div>
            <div
              className="Browse-impactful BnrCnt OurservHdd mb-4 OurPrjctHddSec"
              style={{ textAlign: "center" }}
            >
              
              <p className="text-left">
                {description} {/* Dynamic description */}
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="Portfolio-buttons col-lg-2">
            <div
              className="nav flex-column nav-pills VrtclTbb PrjctTb"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              {filteredData.map((project, index) => (
                <button
                  key={index}
                  className={`nav-link ${index === 0 ? "active" : ""}`}
                  data-toggle="pill"
                  data-target={`#PrjctTbb${index + 1}`}
                  type="button"
                  role="tab"
                  aria-controls={`PrjctTbb${index + 1}`}
                  aria-selected={index === 0}
                >
                  <h3 style={{ fontSize: "1.2rem" }}>{project.title}</h3>
                </button>
              ))}
            </div>
          </div>
          <div className="col-xl-10">
            <div className="tab-content" id="v-pills-tabContent">
              {filteredData.map((project, index) => (
                <div
                  key={index}
                  className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
                  id={`PrjctTbb${index + 1}`}
                  role="tabpanel"
                >
                  <div>
                    <div className="PrjctDtlsItm">
                      <div className="row align-items-center">
                        <div className="col-lg-7">
                          <div className="PrjctDtlCnt">
                            <h3 className="port_sub_title">{project.title}</h3>
                            <p>{project.description}</p>
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="PrjctImg text-center">
                            <img
                              src={project.image}
                              alt={project.title}
                              title={project.title}
                              loading="eager"
                              className="img-fluid lazyload"
                              width="70%"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="PrjctsBtnSec">
                        {project.buttons.map((button, btnIndex) => (
                          <div className="mr-4 mb-4" key={btnIndex}>
                            <div className="PrjctBtn_new Btn211-42">
                              {button}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="RltdPrtflo">
                        <div className="RltdPrjHddSec">
                          <div className="RltdPrjHdd">
                            <div className="h5_title">Related Portfolio</div>
                          </div>
                        </div>
                        <RelatedPortfolioSlider />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProjects;

