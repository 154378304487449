import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";

import FitnessSwiperSlider from "./ApplicationSwiperSlider/FitnessSwiperSlider";
import KeyFeatures from "../../KeyFeatures";
import TechnologyStack from "../../../components/TechnologyStack";
import Industries from "../../Industries";
import AdminControlFeatures from "../../AdminControlFeatures";
import OurStepByStep from "../../../components/OurStepByStep";

const FitnessApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="main-div-Application">
        <header className="enterprise-mobile-app-development arkit header-shadow">

          <div className="container h-100">
            <div className="row h-100">

              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <h1 className="mb-5">
                    Fitness Apps
                  </h1>
                  <h3 className="heading-tagline">
                    Fitness apps empower users to prioritize their health and wellness, offering personalized workout routines, nutrition plans, and progress tracking. With features like goal setting and workout reminders, they inspire motivation and accountability, facilitating a healthier lifestyle anytime, anywhere.
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="Enterprise-container">
          <div className="Enterprise-box">
            <div className="Enterprise-left-div">
              <h1 className='Key-feature-heading'>We Are Specialist</h1>
              <p style={{ fontSize: "20px" }}>

                As pioneers in the realm of fitness chatting apps, we've redefined how individuals engage with their health goals. Our platform serves as a dynamic hub for enthusiasts, providing tailored support, expert advice, and a vibrant community for sharing achievements and challenges. With real-time messaging, personalized workout plans, and nutrition guidance, users can seamlessly integrate wellness into their daily conversations. Whether you're seeking motivation, tips, or camaraderie, our app fosters connections that inspire progress and success. Join us in embracing a healthier lifestyle through interactive communication with our specialized fitness chatting app.
              </p>



              <button
                type="button"
                data-toggle="modal"
                data-target="#popup_form_modal"
                class="btn BtnPrimry TlkBtn"
                onClick={() => navigate("/contact-us")}
              >
                <span>Talk to our Experts</span>
                <span>Talk to our Experts</span>
              </button>
            </div>
            <div className="Enterprise-right-div">
              <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
            </div>
          </div>
        </section>

        <FitnessSwiperSlider />
        <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
       <OurStepByStep/>
        <section className="OnDemandApp_section">
          <OnDemandAppSlider />
          {/* <CardSlider/> */}
        </section>

        <section>
          <SimilarFile />
        </section>
      </div>
    </>
  );
}

export default FitnessApps
