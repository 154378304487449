import React from "react";
import { procedureData } from "./procedureData";

const OurProcedureNew = ({ category }) => {
  const data = procedureData[category];

  if (!data) {
    return <p>Invalid category selected.</p>;
  }

  return (
    <main id="metaverse_game_main">
      <section className="metaverse_game_process">
        <div className="container">
          <h2 className="grident-color-sub-title title">{data.heading}</h2>
          <p>{data.description}</p>
          <div className="row justify-content-center process_after">
            <div className="col-lg-12">
              {data.steps.map((step, index) => (
                <div className="row process_list_after" key={index}>
                  <div
                    className={`col-lg-6 col-md-6 col-12 ${
                      index % 2 === 0 ? "" : "offset-lg-6"
                    }`}
                  >
                    <div className="process_box">
                      <h3
                        className="grident-color-sub-title text-center"
                        style={{ fontWeight: "bold" }}
                      >
                        {step.title}
                      </h3>
                      <p>{step.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default OurProcedureNew;
