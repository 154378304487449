import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import TravelSwiperSlider from "./SwiperSlider/TravelSwiperSlider";
import SeoHelmet from "../../../Helmet";

import FAQSection2 from "../../FAQSection2";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

// import '../../main.css'

const TravelBookingSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Best travel software development company | Innovative & Better
"
          description="Discover travel software development company Convert to Elegant your travel business. Our expert team delivers custom solutions for booking management, itinerary planning, payment integration, and more, enhancing user experience and boosting nicely.
"
          keywords="travel software development company"
        />

        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      Custom Booking Travel Software Development Company
                    </h1>
                    <p className="heading-tagline">
                      Today's travel industry is fast-changing, and companies
                      need advanced solutions to improve their operation,
                      customer experience, and competitive advantage. Custom
                      Travel Booking Software Development Companies design
                      custom software solutions to meet the specific needs of
                      travel agencies, tour operators, and online travel
                      organizations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Specialist In Travel Software Development
                </h2>
                <p style={{ fontSize: "20px" }}>
                  MetaBlock specializes in the best travel agency software
                  solutions specifically styled to fit the cutting-edge worlds
                  of travel and tourism. With a long history in this business
                  and an in-depth understanding of what is required to make such
                  apps use natural platforms for inner operations, users
                  experience and increase sales. Be it any travel-inclusive
                  booking systems or itinerary planners, CRMs for travel
                  agencies, or travel APIs on best travel agents, we have some
                  cutting-edge solutions of great value. We help new and
                  traditional businesses understand the ins and outs of the
                  digital travel market, making it all very well-planned. Work
                  with us now to deliver technology as tightly wound into the
                  travels as you would like.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/traveldev.webp" alt="traveldev" />
              </div>
            </div>
          </section>

          <TravelSwiperSlider />
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
            {/* <CardSlider/> */}
          </section>

           <section>
          <OurProjects
            projectData={projectData}
            heading="Our Projects: A Showcase of Impactful Solutions"
            description="View different categories that depict the most advanced services provided by our company. Based on dynamic web development, mobile applications, gaming, and blockchain, these projects represent the best samples of our work. No matter if you are seeking a game, NFT, Metaverse experience, or enterprise software, our engaged team is involved in turning your ideas into reality using cutting-edge technologies. Check out the portfolio page of MetaBlock see some of the successful projects and learn how ideas are brought to life."
            filterKey="travel" // Display only software projects
          />
        </section>
        
          <section>
          <OurProjects
            projectData={projectData}
            heading="Browse our impactful portfolio showcase"
            description=" Our services encompasses web development,app development,game development,software development and blockchain development."
            filterKey="travel" // Display only software projects
          />
            <SimilarFile />
          </section>
          <FAQSection2 category="travel"/>
        </div>
      </div>
    </>
  );
};

export default TravelBookingSoftware;
