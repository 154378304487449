import React from 'react'
import './Visitbutton.css'

const Visitbutton = () => {
    return (

        <>
            <button className="btn-hover"><span>Visit Now <i class="fa-solid fa-right-long"></i></span></button>
            {/*<p class="link">    Design by     <a href="https://dribbble.com/skytrip" target="_blank">SkyTrip</a>
             </p>*/}
        </>
    )
}

export default Visitbutton
