import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";

import FoodDeleverySwiperSlider from "./ApplicationSwiperSlider/FoodDeleverySwiperSlider";
import SeoHelmet from "../../../Helmet";
import TechnologyStack from "../../../components/TechnologyStack";
import faqData from "../../faqData";
import FAQSection2 from "../../FAQSection2";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

// import '../../main.css'

const FooddeliveryApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="On Low-Cost Demand Food Delivery App Development Services Company
"
          description="We are an experienced Low-cost-demand food delivery app development services company in India. It has a cost-effective solution that can be implemented in just a few weeks. Build your ordering app with metablock technologies!
"
keywords=" Food Delivery App Development Services Company"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      {" "}
                      |Grow Your Business| With Best Food Delivery App
                      Development Services Company
                    </h1>
                    <p className="heading-tagline">
                      Metablock Technologies is the best Food delivery app
                      development services company, which is in jaipur. make it
                      easy to order meals and have them delivered to your home.
                      With simple ordering and fast delivery,
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Expert In Food Delivery App Solutions
                </h2>
                <p style={{ fontSize: "20px" }}>
                  With Metablock Technologies, easily search for meals based on
                  your preferences, such as food and dietary needs. We have
                  experienced food app developers, who make food delivery apps
                  like Swiggy, Zomato, and Uber Eats. Share your favorite dishes
                  with friends through social media. We ensure quick,
                  high-quality delivery right to your door. Explore new food
                  delivery app startups, like restaurants and hotels. Stay
                  updated with real-time notifications on your order, local
                  promotions, and events. Customize your experience with
                  contactless delivery options and flexible delivery times to
                  match your schedule.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
              </div>
            </div>
          </section>

          <FoodDeleverySwiperSlider />
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
          
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
           </section>
           <OurProjects
            projectData={projectData}
            heading="Explore our impressive portfolio showcase."
            description=" We offer a range of services including web development, Flutter app development, game development, software development, mobile app development and blockchain development."
            filterKey="mobileapp" // Display only software projects
          />
          <section>
            <SimilarFile />
          </section>
        </div>
        <FAQSection2 category="foodapp"/>
      </div>
    </>
  );
};

export default FooddeliveryApps;
