// import React from "react";
// import "../WebApplicationMainPage.css";
// import OnDemandAppSlider from "../OnDemandAppSlider";
// import CardSlider from "../CardSlider";
// import DeliveringNative from "../Delivering Native/DeliveringNative";
// import Phonecardslider from "../Phonecardslider/Phonecardslider";
// import SimilarFile from "../../SimilarFile";
// import SwiperSlider from "./SwiperSlider/SwiperSlider";
// import AdminControlFeature from "./AdminControlFeature/AdminControlFeature";
// import SoftwareKeyFeature from "./KeyFeatures/SoftwareKeyFeature";
// import SoftwareUsesTechnology from "./SoftwareUsesTechnology/SoftwareUsesTechnology";
// import { useNavigate } from "react-router-dom";
// import LibrarySwiperSlider from "./SwiperSlider/LibrarySwiperSlider";
// import SeoHelmet from "../../../Helmet";

// // import '../../main.css'

// const SoftwareDevelopment = () => {
//   const navigate = useNavigate();

//   return (
//     <>
//       <div>
//         <SeoHelmet
//           pagetitle="Custom Software Development Services - Innovate & Scale Your Business"
//           description="Custom software development Services can transform your business into an innovative place of work where effusive output becomes an everyday occurrence. Our expert team develops customized, scalable solutions that enhance efficiency, foster innovation, and produce sustainable measures for future growth and success.
// "
//           keywords=" Custom Software Development Services"
//         />

//         <div className="main-div-Application">
//           <header className="enterprise-mobile-app-development arkit header-shadow">
//             <div className="container h-100">
//               <div className="row h-100">
//                 <div className="col-lg-12 my-auto">
//                   <div className="header-content mx-auto">
//                     <h1 className="mb-5">
//                       {/* Blockchain Finance Management */} Custom Software
//                       Development Services for Your Business Needs
//                     </h1>
//                     <p className="heading-tagline">
//                       Software Development is the design, development,
//                       deployment, and maintenance of software applications. It
//                       includes several stages and practices intended to solve
//                       specific problems, automate processes, or enhance system
//                       efficiency. Here are the key aspects of software
//                       development.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </header>

//           <section className="Enterprise-container">
//             <div className="Enterprise-box">
//               <div className="Enterprise-left-div">
//                 <h2 className="Key-feature-heading">
//                   Specialists in Custom Software Development Solutions
//                 </h2>
//                 <p style={{ fontSize: "20px" }}>
//                   At Metablock Technology, we deliver cutting-edge software
//                   solutions tailored to the unique needs of your business in the
//                   Software Development Industry. Our team of experts specializes
//                   in designing, developing, and deploying high-quality
//                   applications that drive innovation and efficiency.
//                 </p>

//                 <button
//                   type="button"
//                   data-toggle="modal"
//                   data-target="#popup_form_modal"
//                   class="btn BtnPrimry TlkBtn"
//                   onClick={() => navigate("/contact-us")}
//                 >
//                   <span>Talk to our Experts</span>
//                   <span>Talk to our Experts</span>
//                 </button>
//               </div>
//               <div className="Enterprise-right-div">
//                 <img
//                   src="./images/devanshusoftware-2.jfif.webp"
//                   alt="devanshusoftware"
//                 />
//               </div>
//             </div>
//           </section>
//           <LibrarySwiperSlider />
//           <SoftwareKeyFeature />
// {/*============= Technology Stack We Use========= */}

// <section className="section-heading mid_section-1">
//   <div className="container">
//     <div className="row">
//       <div className="col-md-12 grident-color-sub-title">
//         <h2
//           style={{ color: "#D166AF ", fontSize: "40px" }}
//           className="text-center Technology-Stack"
//         >
//           Technology Stack We Use
//         </h2>
//       </div>
//     </div>
//     <div className="row tech p-5 pt-0 mt-5 mb-5">
//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/physics.webp"
//           className="w-100 h-40"
//           alt="React"
//         />
//         <h5>React</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/python.webp"
//           alt="Python"
//           className="w-100 h-40"
//         />
//         <h5>Python</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/mysql.webp"
//           alt="MySQL"
//           className="w-100 h-40"
//         />
//         <h5>MySQL</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/java-script.webp"
//           alt="Java Srcipt"
//           className="w-100 h-40"
//         />
//         <h5>Java Srcipt</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/SQL-Lite.webp"
//           alt="SQL Lite"
//           className="w-100 h-40"
//         />
//         <h5>SQL Lite</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/html.webp"
//           alt="html"
//           className="w-100 h-40"
//         />
//         <h5>HTML</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/android.webp"
//           alt="Android"
//           className="w-100 h-40"
//         />
//         <h5>Android</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/mongo-db-icon.webp"
//           alt="Mongo DB"
//           className="w-100 h-40"
//         />
//         <h5>Mongo DB</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/app-store.webp"
//           alt="App Store"
//           className="w-100 h-40"
//         />
//         <h5>App Store</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/atom.webp"
//           alt="Atom"
//           className="w-100 h-40"
//         />
//         <h5>Atom</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/laravel.webp"
//           alt="Laravel"
//           className="w-100 h-40"
//         />
//         <h5>Laravel</h5>
//       </div>

//       <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
//         <img
//           src="./images/python.webp"
//           alt="Python"
//           className="w-100 h-40"
//         />
//         <h5>Python</h5>
//       </div>
//     </div>
//   </div>
// </section>
//           <SoftwareUsesTechnology />
//           <AdminControlFeature />
//           <section className="process_we_follow section-heading-1">
//             <div className="container-fluid">
//               <div className="row justify-content-center">
//                 <div className="col-md-12">
//                   <div className="cms text-center Process">
//                     <h2 className="our-app">
//                       Our Step-By-Step Software Development Process
//                     </h2>
//                   </div>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-md-12 text-center">
//                   <img
//                     src="/images/process-flow-new.webp"
//                     className="img-fluid"
//                     alt="process-flow-new"
//                   />
//                 </div>
//               </div>
//             </div>
//           </section>
//           <section className="OnDemandApp_section">
//             <OnDemandAppSlider />
//             {/* <CardSlider/> */}
//           </section>

//           {/* <section >
//           <CardSlider/>
//         </section> */}
//           {/* <RelatedPortfolioSlider/> */}
//           {/* <OnDemandAppSlider/> */}

//           {/* <section className='Phone-card-slider'>
//           <Phonecardslider />
//         </section> */}

//           <section>
//             <SimilarFile />
//           </section>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SoftwareDevelopment;

import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";
import LibrarySwiperSlider from "./SwiperSlider/LibrarySwiperSlider";
import SeoHelmet from "../../../Helmet";
import FAQSection2 from "../../FAQSection2"; // Import the FAQ section
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import TechnologyStack from "../../../components/TechnologyStack";
import OurStepByStep from "../../../components/OurStepByStep";
import KeyFeatures from "../../KeyFeatures";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import WhyChooseUs from "../../WhyChooseUs2";
import OurProcedureNew from "../../OurProcedureNew";

const SoftwareDevelopment = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="Custom Software Development Services - Innovate & Scale Your Business"
          description="Custom software development Services can transform your business into an innovative place of work where effusive output becomes an everyday occurrence. Our expert team develops customized, scalable solutions that enhance efficiency, foster innovation, and produce sustainable measures for future growth and success."
          keywords="Custom Software Development Services"
        />

        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      Custom Software Development Services for Your Business
                      Needs
                    </h1>
                    <p className="heading-tagline">
                      Software Development is the design, development,
                      deployment, and maintenance of software applications. It
                      includes several stages and practices intended to solve
                      specific problems, automate processes, or enhance system
                      efficiency. Here are the key aspects of software
                      development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Specialists in Custom Software Development Solutions
                </h2>
                <p style={{ fontSize: "20px" }}>
                  At Metablock Technology, we deliver cutting-edge software
                  solutions tailored to the unique needs of your business in the
                  Software Development Industry. Our team of experts specializes
                  in designing, developing, and deploying high-quality
                  applications that drive innovation and efficiency.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  className="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img
                  src="./images/devanshusoftware-2.jfif.webp"
                  alt="devanshusoftware"
                />
              </div>
            </div>
          </section>

          <LibrarySwiperSlider />

          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />

          <TechnologyStack />

          <Industries
            category="software"
            heading="Industries That Use Our Software Solutions" // Dynamic heading for software
          />

          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />

          {/* <OurStepByStep/> */}

          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>

          <section>
            <OurProjects
              projectData={projectData}
              heading="Our Projects: A Showcase of Impactful Solutions"
              description="View different categories that depict the most advanced services provided by our company. Based on dynamic web development, mobile applications, gaming, and blockchain, these projects represent the best samples of our work. No matter if you are seeking a game, NFT, Metaverse experience, or enterprise software, our engaged team is involved in turning your ideas into reality using cutting-edge technologies. Check out the portfolio page of MetaBlock see some of the successful projects and learn how ideas are brought to life."
              filterKey="software" // Display only software projects
            />
          </section>
          {/* <section>
            <WhyChooseUs category="software"/>
          </section> */}

          <section>
            <SimilarFile />
          </section>

          <FAQSection2 category="software"/>
          <OurProcedureNew category="software" />

        </div>
      </div>
    </>
  );
};

export default SoftwareDevelopment;
