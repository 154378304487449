import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";
import CardSlider from "../CardSlider";
import DeliveringNative from "../Delivering Native/DeliveringNative";
import Phonecardslider from "../Phonecardslider/Phonecardslider";
import SimilarFile from "../../SimilarFile";
import SwiperSlider from "./SwiperSlider/SwiperSlider";

import { useNavigate } from "react-router-dom";

import POSSwiperSlider from "./SwiperSlider/POSSwiperSlider";
import TechnologyStack from "../../../components/TechnologyStack";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";
import SeoHelmet from "../../../Helmet";
import OurProjects from "../../OurProjects";
import projectData from "../../projectData";
import FAQSection2 from "../../FAQSection2";
import OurProcedureNew from "../../OurProcedureNew";
// import '../../main.css'

const POSSystemSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="POS System Software Development: Custom Solutions for Businesses"
          description="Explore professional POS system software development services. We provide custom, scalable solutions to improve sales transactions, inventory management, and customer experience for retail, restaurants, and more."
          keywords="POS System Software Development"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">POS System Software Development</h1>
                    <p className="heading-tagline">
                      POS system software development: The software development
                      process in a Point of Sale (POS) system typically involves
                      building company-specific business solution applications
                      and/or systems with integrated hardware and software
                      applications that reduce complex sales process streams to
                      improve precision and furnish additional insights about
                      general business performances and operations to industries
                      in the service, hospitality, and retailing sectors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  Specialist In POS System Software
                </h2>
                <p style={{ fontSize: "20px" }}>
                  A POS Specialist is an expert in designing, developing, and
                  optimizing software solutions that enable businesses to
                  process transactions efficiently and manage their sales
                  operations. Having a deep understanding of software
                  engineering and the unique needs of the retail, hospitality,
                  and other service industries, they craft a system that has
                  enhanced user experience, improved the accuracy of
                  transactions, and streamlined business processes. Their skills
                  range from hardware integration to more complex features such
                  as inventory tracking, reporting, customer management, and
                  payment processing, which ensure businesses can provide
                  seamless, secure, and efficient services to their customers.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/possoft.webp" alt="possoft" />
              </div>
            </div>
          </section>

          <POSSwiperSlider />
          <KeyFeatures
            heading=" Key Features of Point of Sale Systems "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="pos" // Pass 'software' as filterKey
          />
          <TechnologyStack />

          <Industries
            category="pos"
            heading="Industries That Use Our Software Solutions"
          />

          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />

          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>

          <section>
            <OurProjects
              projectData={projectData}
              heading="Our Projects: A Showcase of Impactful Solutions"
              description="View different categories that depict the most advanced services provided by our company. Based on dynamic web development, mobile applications, gaming, and blockchain, these projects represent the best samples of our work. No matter if you are seeking a game, NFT, Metaverse experience, or enterprise software, our engaged team is involved in turning your ideas into reality using cutting-edge technologies. Check out the portfolio page of MetaBlock see some of the successful projects and learn how ideas are brought to life."
              filterKey="pos"
            />
          </section>
          <section>
            <SimilarFile />
          </section>

          <FAQSection2 category="pos" />
          <OurProcedureNew category="pos" />
        </div>
      </div>
    </>
  );
};

export default POSSystemSoftware;
