// KeyFeatures.js
import React from "react";
import keyData from "./keyData"; // Ensure this path is correct
import "./KeyFeatures.css";

const KeyFeatures = ({ heading, description, filterKey }) => {
  // Filtered data based on the filterKey prop
  const features = keyData[filterKey] || [];

  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="dashboard-main-box">
        <h2 className="Key-feature-heading mb-4" style={{ fontSize: "40px" }}>
          {heading} {/* Dynamic heading */}
        </h2>
        <p>{description}</p> {/* Dynamic description */}
        <div className="dashboard-container">
          {features.map((feature) => (
            <div key={feature.id} className="dashboard-container-box dashboard-boxes key-feature-box">
              <div className="key-feature-heading">
                <img src={feature.image} alt={feature.title} />
                <h3 className="software-feature-headings d-flex flex-column justify-center align-center">
                  {feature.title}
                </h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;