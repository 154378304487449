import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Blog_add = () => {
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState("");
  const [likes, setLikes] = useState(0);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null); // Holds the selected image for preview
  const [tags, setTags] = useState("");
  const [categories, setCategories] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentBlogId, setCurrentBlogId] = useState(null);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [expandedBlogId, setExpandedBlogId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage, setBlogsPerPage] = useState(6);

  useEffect(() => {
    fetchBlogs();
    fetchCategories();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org/api/v1/artical"
      );
      const blogsWithTrendingDefault = response.data.map((blog) => ({
        ...blog,
        Trending: blog.Trending ?? false,
      }));
      setBlogs(blogsWithTrendingDefault);
      setFilteredBlogs(blogsWithTrendingDefault);
    } catch (error) {
      console.error("Error fetching blogs", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://maincompanybackend.metablocktechnologies.org/api/v1/blogcategory"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file); // Update the selected image for preview
  };

  const handleTagsChange = (e) => setTags(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("category_id", category);
    formData.append("likes", likes);
    formData.append(
      "tags",
      tags.split(",").map((tag) => tag.trim())
    );

    if (image) {
      formData.append("image", image); // Add new image if selected
    } else {
      // For editing, send the existing image path if no new image is uploaded
      const existingImagePath =
        editing && blogs.find((blog) => blog._id === currentBlogId)?.image;
      if (existingImagePath) {
        formData.append("image", existingImagePath);
      }
    }

    try {
      if (editing) {
        await axios.put(
          `https://maincompanybackend.metablocktechnologies.org/api/v1/artical/update/${currentBlogId}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        toast.success("Blog updated successfully");
      } else {
        await axios.post(
          "https://maincompanybackend.metablocktechnologies.org/api/v1/artical/create",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        toast.success("Blog created successfully");
      }
      fetchBlogs();
    } catch (error) {
      console.error("Error submitting blog", error);
      toast.error("Error submitting blog");
    }
  };

  const handleEdit = (blog) => {
    setTitle(blog.title);
    setLikes(blog.likes);
    setDescription(blog.description);
    setCategory(blog.category_id?._id || "");
    setTags(blog.tags.join(", "));
    setCurrentBlogId(blog._id);
    setEditing(true);
    setImage(null); // Clear image preview when editing
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://maincompanybackend.metablocktechnologies.org/api/v1/artical/delete/${id}`
      );
      toast.success("Blog deleted successfully");
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog", error);
      toast.error("Error deleting blog");
    }
  };

  const handleFilter = (categoryId) => {
    if (!categoryId) {
      setFilteredBlogs(blogs);
    } else {
      const filtered = blogs.filter(
        (blog) => blog.category_id?._id === categoryId
      );
      setFilteredBlogs(filtered);
    }
  };

  const toggleDescription = (blogId) => {
    setExpandedBlogId(expandedBlogId === blogId ? null : blogId);
  };

  const handleSetTrending = async (id, isTrending) => {
    try {
      await axios.patch(
        `https://maincompanybackend.metablocktechnologies.org/api/v1/artical/trending/${id}`,
        { trending: isTrending }
      );
      toast.success(
        `Blog marked as ${isTrending ? "Trending" : "Not Trending"}`
      );
      fetchBlogs();
    } catch (error) {
      console.error("Error updating trending status", error);
      toast.error("Error updating trending status");
    }
  };

  const handleLoadMore = () => {
    setBlogsPerPage(blogsPerPage + 6);
  };

  const truncateDescription = (description, length) => {
    return description.length > length
      ? description.substring(0, length) + "..."
      : description;
  };

  // Pagination Logic
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <ToastContainer />
      <header className="bg-blue-500 text-white p-4 rounded-lg mb-4 text-center">
        <h1 className="text-3xl">{editing ? "Edit Blog" : "Add Blog"}</h1>
      </header>
      <main className="container mx-auto">
        <form
          onSubmit={handleSubmit}
          className="bg-white p-4 rounded-lg shadow-md mb-8"
        >
          <label className="block mb-2 text-black" htmlFor="title">
            Title
          </label>
          <input
            id="title"
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 mb-2 border rounded-lg text-black"
            required
          />

          <label className="block mb-2 text-black" htmlFor="description">
            Description
          </label>
          <textarea
            id="description"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 mb-2 border rounded-lg text-black"
            required
          />

          <label className="block mb-2 text-black" htmlFor="likes">
            Likes
          </label>
          <input
            id="likes"
            type="number"
            placeholder="Likes"
            value={likes}
            onChange={(e) => setLikes(e.target.value)}
            className="w-full p-2 mb-2 border rounded-lg text-black"
          />

          <label className="block mb-2 text-black" htmlFor="category">
            Category
          </label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full p-2 mb-2 border rounded-lg text-black"
            required
          >
            <option className="text-black" value="">
              Select a Category
            </option>
            {categories.map((cat) => (
              <option className="text-black" key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
          </select>

          <label className="block mb-2 text-black" htmlFor="image">
            Image
          </label>
          <input
            id="image"
            type="file"
            onChange={handleImageChange}
            className="w-full p-2 mb-2 border rounded-lg text-black"
            required={!editing}
          />

          {/* Show Image Preview After Upload */}
          {image && (
            <div className="mt-4">
              <h3 className="text-black">Image Preview:</h3>
              <img
                src={URL.createObjectURL(image)}
                alt="Image Preview"
                className="w-[100px] h-[100px] mt-2"
              />
            </div>
          )}

          {/* Show Existing Image When Editing */}
          {editing &&
            !image &&
            blogs.find((blog) => blog._id === currentBlogId)?.image && (
              <div className="mt-4">
                <h3 className="text-black">Current Image:</h3>
                <img
                  src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${
                    blogs.find((blog) => blog._id === currentBlogId)?.image
                  }`}
                  alt="Current Image"
                  className="w-[100px] h-[100px] mt-2"
                />
              </div>
            )}

          <label className="block mb-2 text-black" htmlFor="tags">
            Tags (comma-separated)
          </label>
          <input
            id="tags"
            type="text"
            placeholder="Tags (comma-separated)"
            value={tags}
            onChange={handleTagsChange}
            className="w-full p-2 mb-2 border rounded-lg text-black"
          />

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            {editing ? "Update Blog" : "Add Blog"}
          </button>
        </form>

        <div className="mb-4">
          <select
            onChange={(e) => handleFilter(e.target.value)}
            className="w-full p-2 mb-4 border rounded-lg text-black"
          >
            <option className="text-black" value="">
              Filter by Category
            </option>
            <option value="" className="text-black">
              All
            </option>
            {categories.map((cat) => (
              <option className="text-black" key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        {/* Table to display blogs with scrollable feature for smaller screens */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg shadow-md mb-8 table-auto">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-black">Sr.no</th>
                <th className="px-4 py-2 text-black">Image</th>
                <th className="px-4 py-2 text-black">Title</th>
                <th className="px-4 py-2 text-black">Likes👍</th>
                {/* <th className="px-4 py-2 text-black">#Tags</th> */}
                <th className="px-4 py-2 text-black">Category</th>
                <th className="px-4 py-2 text-black">Description</th>
                <th className="px-4 py-2 text-black">Trending</th>
                <th className="px-4 py-2 text-black">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentBlogs.map((blog, index) => (
                <tr key={blog._id}>
                  <td className="px-4 py-2 text-black">{index + 1}.</td>
                  <td className="px-4 py-2">
                    <img
                      src={`https://maincompanybackend.metablocktechnologies.org/blogArtical/images/${blog.image}`}
                      alt={blog.title}
                      className="w-16 h-16 object-cover rounded-full"
                    />
                  </td>
                  <td className="px-4 py-2 text-black">{blog.title}</td>
                  <td className="px-4 py-2 text-black">{blog.likes}👍</td>
                  {/* <td className="px-4 py-2 text-black">
                    <ul className="list-none flex flex-wrap gap-2">
                      {blog?.tags?.length > 0 ? (
                        blog.tags.map((tag, index) => (
                          <li key={index} className="bg-blue-500 text-white px-2 py-1 rounded-full">
                            {tag}
                          </li>
                        ))
                      ) : (
                        <li className="bg-gray-300 text-black px-2 py-1 rounded-full">No Tags</li>
                      )}
                    </ul>
                  </td> */}
                  <td className="px-4 py-2 text-black">
                    {blog.category_id?.name}
                  </td>
                  <td className="px-4 py-2 text-black">
                    <button
                      onClick={() => toggleDescription(blog._id)}
                      className="text-blue-600 underline"
                    >
                      {expandedBlogId === blog._id ? "Show less" : "Show more"}
                    </button>
                    {expandedBlogId === blog._id && (
                      <p className="mt-2">
                        {truncateDescription(blog.description, 100)}
                      </p>
                    )}
                  </td>
                  <td className="px-4 py-2 text-black">
                    <input
                      type="checkbox"
                      checked={blog.Trending}
                      onChange={() =>
                        handleSetTrending(blog._id, !blog.Trending)
                      }
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                  </td>
                  <td className="px-4 py-2 text-black">
                    <button
                      onClick={() => handleEdit(blog)}
                      className="bg-yellow-500 text-white px-4 py-2 rounded-lg mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(blog._id)}
                      className="bg-red-600 text-white px-4 py-2 rounded-lg"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-center space-x-4 mt-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[...Array(Math.ceil(filteredBlogs.length / blogsPerPage))].map(
            (_, index) => (
              <button
                key={index}
                className={`bg-blue-500 text-white px-4 py-2 rounded-lg ${
                  currentPage === index + 1 ? "bg-blue-700" : ""
                }`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            )
          )}
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(filteredBlogs.length / blogsPerPage)
            }
          >
            Next
          </button>
        </div>

        <button
          onClick={handleLoadMore}
          className="bg-green-500 text-white px-4 py-2 rounded-lg mt-4"
        >
          Load More
        </button>
      </main>
    </div>
  );
};

export default Blog_add;
