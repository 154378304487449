// adminControlData.js
const adminControlData = {
  software: [
    {
      id: 1,
      title: "User  Management",
      description: "User  management involves overseeing and structuring user access to a system, application, or network. It guarantees that users possess the appropriate permissions and roles to carry out their tasks safely.",
      image: "./images/User-Management.webp",
    },
    {
      id: 2,
      title: "Access Control",
      description: "Access control is a security measure that determines who is allowed to view, use, or change resources within a system. It guarantees that only authorized users can access sensitive information and features, safeguarding systems against unauthorized access.",
      image: "./images/access-control.webp",
    },
    {
      id: 3,
      title: "Content Moderation",
      description: "Content moderation involves overseeing and regulating user-generated content to make sure it complies with platform guidelines and community standards.",
      image: "./images/creative.webp",
    },
    {
      id: 4,
      title: "Configuration and Settings",
      description: "Users can customize and optimize software or systems through configuration and settings to better suit their specific needs. This involves adjusting features, setting preferences, managing system behavior, and ensuring everything functions smoothly.",
      image: "./images/process.webp",
    },
    {
      id: 5,
      title: "Security Management",
      description: "Security management encompasses the development and execution of policies, procedures, and controls aimed at safeguarding an organization's assets, data, and systems from various threats. This process involves conducting risk assessments.",
      image: "./images/process.webp",
    },
    {
      id: 6,
      title: "Monitoring and Analytics",
      description: "Monitoring and analytics focus on observing system performance and user behavior to gather important insights into operations. By consistently tracking key metrics and analyzing the data, businesses can pinpoint issues, enhance performance, and make informed decisions based on data.",
      image: "./images/web-analytics.webp",
    },
    {
      id: 7,
      title: "Reporting and Audit Trails",
      description: "Reporting and audit trails offer comprehensive records of system activities, enabling organizations to monitor actions, detect anomalies, and maintain compliance.",
      image: "./images/sheet.webp",
    },
    {
      id: 8,
      title: "Integration and Interoperability",
      description: "Integration and interoperability involve the smooth connection and communication among various systems, applications, and platforms. By making sure that different technologies collaborate effectively, organizations can optimize workflows, boost data sharing, and enhance overall efficiency.",
      image: "./images/connection.webp",
    },
  ],
  erp: [
    {
      id: 1,
      title: "User  Management",
      description: "User  management involves overseeing and structuring user access to a system, application, or network. It guarantees that users possess the appropriate permissions and roles to carry out their tasks safely.",
      image: "./images/User-Management.webp",
    },
    {
      id: 2,
      title: "Access Control",
      description: "Access control is a security measure that determines who is allowed to view, use, or change resources within a system. It guarantees that only authorized users can access sensitive information and features, safeguarding systems against unauthorized access.",
      image: "./images/access-control.webp",
    },
    {
      id: 3,
      title: "Content Moderation",
      description: "Content moderation involves overseeing and regulating user-generated content to make sure it complies with platform guidelines and community standards.",
      image: "./images/creative.webp",
    },
    {
      id: 4,
      title: "Configuration and Settings",
      description: "Users can customize and optimize software or systems through configuration and settings to better suit their specific needs. This involves adjusting features, setting preferences, managing system behavior, and ensuring everything functions smoothly.",
      image: "./images/process.webp",
    },
    {
      id: 5,
      title: "Security Management",
      description: "Security management encompasses the development and execution of policies, procedures, and controls aimed at safeguarding an organization's assets, data, and systems from various threats. This process involves conducting risk assessments.",
      image: "./images/process.webp",
    },
    {
      id: 6,
      title: "Monitoring and Analytics",
      description: "Monitoring and analytics focus on observing system performance and user behavior to gather important insights into operations. By consistently tracking key metrics and analyzing the data, businesses can pinpoint issues, enhance performance, and make informed decisions based on data.",
      image: "./images/web-analytics.webp",
    },
    {
      id: 7,
      title: "Reporting and Audit Trails",
      description: "Reporting and audit trails offer comprehensive records of system activities, enabling organizations to monitor actions, detect anomalies, and maintain compliance.",
      image: "./images/sheet.webp",
    },
    {
      id: 8,
      title: "Integration and Interoperability",
      description: "Integration and interoperability involve the smooth connection and communication among various systems, applications, and platforms. By making sure that different technologies collaborate effectively, organizations can optimize workflows, boost data sharing, and enhance overall efficiency.",
      image: "./images/connection.webp",
    },
  ],
  pos: [
    {
      id: 1,
      title: "User  Management",
      description: "User  management involves overseeing and structuring user access to a system, application, or network. It guarantees that users possess the appropriate permissions and roles to carry out their tasks safely.",
      image: "./images/User-Management.webp",
    },
    {
      id: 2,
      title: "Access Control",
      description: "Access control is a security measure that determines who is allowed to view, use, or change resources within a system. It guarantees that only authorized users can access sensitive information and features, safeguarding systems against unauthorized access.",
      image: "./images/access-control.webp",
    },
    {
      id: 3,
      title: "Content Moderation",
      description: "Content moderation involves overseeing and regulating user-generated content to make sure it complies with platform guidelines and circle standards.",
      image: "./images/creative.webp",
    },
    {
      id: 4,
      title: "Configuration and Settings",
      description: "Users can customize and optimize software or systems through configuration and settings to better suit their specific needs. This involves adjusting features, setting preferences, managing system behavior, and ensuring everything functions smoothly.",
      image: "./images/process.webp",
    },
    {
      id: 5,
      title: "Security Management",
      description: "Security management involves the development and performance of policies, procedures, & controls aimed at safeguarding an organization's assets, data, & systems from various troubles. This process involves conducting risk assessments, detecting potential problems managing access controls, utilizing encryption, and planning for incident responses.",
      image: "./images/process.webp",
    },
    {
      id: 6,
      title: "Monitoring and Analytics",
      description: "Monitoring and analytics focus on observing system performance and user behavior to gather important insights into operations. By consistently tracking key metrics and analyzing the data, businesses can pinpoint issues, enhance performance, and make informed decisions based on data.",
      image: "./images/web-analytics.webp",
    },
    {
      id: 7,
      title: "Reporting and Audit Trails",
      description: "Reporting and audit trails offer comprehensive records of system activities, enabling organizations to monitor actions, detect anomalies, and maintain compliance.",
      image: "./images/sheet.webp",
    },
    {
      id: 8,
      title: "Integration and Interoperability",
      description: "Integration and interoperability involve the smooth connection and communication among various systems, applications, and platforms. By making sure that different technologies collaborate effectively, organizations can optimize workflows, boost data sharing, and enhance overall efficiency.",
      image: "./images/connection.webp",
    },
  ],
};

export default adminControlData;