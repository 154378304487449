// import React, { useState } from "react";
// import "./AboutForm.css";
// import { postData } from "../../Api/Clientfunctions";

// const AboutForm = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//     phone: "",
//   });

//   const handleInputChange = (e) => {
//     const { name, value, email, phone, message } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//       [email]: value,
//       [phone]: value,
//       [message]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     formData.type = 0;
//     console.log("Form data:", formData);
//     const res = await postData("/contact/submit-enquiry", formData);
//     console.log(res);
//   };

//   return (
//     <div className="contect-form">
//       <form onSubmit={handleSubmit} className="form-div" method="POSt">
//         <h3 className="form-heading-1">Send your Query</h3>

//         <iframe
//           className="rounded-md h-[437px] sm:h-[437px] md:h-[437px] lg:h-[437px] xl:h-[437px]"
//           width="340"
//           height="437"
//           src="https://metablock-software.metablocktechnologies.org/forms/wtl/fd876e62bfe1d7911b0e2be0ddb895fb"
//           frameborder="0"
//           sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin allow-popups"
//           allowfullscreen
//         ></iframe>

       
//       </form>
//     </div>
//   );
// };

// export default AboutForm;



import React, { useState } from "react";
import { postData } from "../../Api/Clientfunctions";
import "./AboutForm.css";

const AboutForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.type = 0;
    console.log("Form data:", formData);
    const res = await postData("/contact/submit-enquiry", formData);
    console.log(res);
  };

  return (
    <div className="contact-form-container">
      <form onSubmit={handleSubmit} className="form-div">
        <h3 className="form-heading-1">Send your Query</h3>

        <iframe
          className="contact-form-iframe"
          src="https://metablock-software.metablocktechnologies.org/forms/wtl/fd876e62bfe1d7911b0e2be0ddb895fb"
          frameBorder="0"
          sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin allow-popups"
          allowFullScreen
        ></iframe>
      </form>
    </div>
  );
};

export default AboutForm;





 {/* <input type="text"
          name="name"
          placeholder="Enter Your Name"
          value={formData.name}
          onChange={handleInputChange}
          required autoComplete='off' />
        <input type="text"
          name="email"
          placeholder="Enter Your Email"
          value={formData.email}
          onChange={handleInputChange}
          required
          autoComplete='off' />
        <input type="number"
          name="phone"
          placeholder="Your Phone Number"
          className="telephone"
          value={formData.phone}
          onChange={handleInputChange}
          required
          autoComplete='off' />
        <textarea cols="28" rows="2" name="message" placeholder="Enter Your Message *" required
          value={formData.message}
          onChange={handleInputChange} autoComplete='off'></textarea>
        <input className='submit' type="submit" value='Send your Query' /> */}