import React from "react";
import "./MetaverseGameDevelopment.css";
import { Link } from "react-router-dom";

const MetaverseAvatarDevelopment = () => {
  return (
    <>
      <main id="metaverse_game_main">
        <section className="metaverse_game_banner">
          <div className="container-fluid cus_container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-7 col-12 p-0 nft_top">
                <img
                  style={{ width: "540px" }}
                  src="./SGimage/3avatar_top.webp"
                  alt="Metaverse-Game-Development-Company"
                  title="Metaverse Game Development Company"
                  className="banner_img img-fluid"
                />
              </div>
              <div className="col-lg-5 col-12">
                <div className="banner_contsec">
                  <h1 className=" title">
                    <span className="cus_brn_title">Metaverse Avatar </span>
                    <br /> DEVELOPMENT COMPANY
                  </h1>
                  <p className="contents">
                    Organizing and participating in social events, such as
                    official meetings or informal get-togethers, is no longer a
                    laborious chore. People may participate in these activities
                    from anywhere in the world using their 3D digital
                    representations. Avatars from the metaverse have drastically
                    changed how we interact with the outside world.
                  </p>
                  <div className="HddrBtns banner_btn_sec">
                    <button
                      toggle="modal"
                      target="#popup_form_modal"
                      className="btn BtnPrimry TlkBtn banner_btn"
                    >
                      <Link to="/contact-us">
                        {" "}
                        <span
                          alt="enquiry"
                          loading="eager"
                          className="img-fluid lazyload mr-2 lazyload"
                        >
                          Get A Live Quote
                        </span>
                      </Link>
                      <span
                        alt="enquiry"
                        loading="eager"
                        className="img-fluid lazyload mr-2"
                      >
                        Get A Live Quote
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-12">
                <h2 className="grident-color-sub-title ourservice-titles-new">
                  Metaverse Virtual Development
                </h2>
                <p className="contents">
                  A user's digital depiction in a virtual environment is
                  referred to as a metaverse avatar. They are made to be able to
                  interact with other avatars and virtual environments. Because
                  they enable users to interact and explore virtual environments
                  in ways that aren't feasible in reality, they are becoming
                  more and more popular. Avatars from the metaverse are
                  currently utilized on numerous platforms, including
                </p>
                <div className="HddrBtns metaverse_game_btn_sec mb-5">
                  <button
                    toggle="modal"
                    target="#popup_form_modal"
                    className="btn BtnPrimry TlkBtn metaverse_game_btn"
                  >
                    <Link to="/contact-us">
                      <span
                        alt="enquiry"
                        loading="eager"
                        className="img-fluid lazyload mr-2 lazyload"
                      >
                        Talk To Our Expert
                      </span>
                    </Link>
                    <span
                      alt="enquiry"
                      loading="eager"
                      className="img-fluid lazyload mr-2"
                    >
                      Talk To Our Expert
                    </span>
                  </button>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <img
                  src="./SGimage/metavarse game development-2 (1)-min.webp"
                  alt="Metaverse Game Development"
                  title="Metaverse Game Development"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="metaverse_game_whatsec">
          <div className="container">
            <div className="row justify-content-center what-is-metaverse-nft-container">
              <div className="col-lg-12 col-12 nft_top">
                <img src="./SGimage/metaverse game development-1 (1)-min.webp" alt="Metaverse Game Development" title="Metaverse Game Development" className="img-fluid lazyload What-is-metaverse-NFT-img" />
              </div>
              <div className="col-lg-12 col-12">
                <h2 className="grident-color-sub-title title">What is Metaverse Avatar?</h2>
                <p className="contents">Users have new opportunities for interaction in virtual environments since metaverse avatars enable them to connect with each other even when they are not physically there. Everyone can have a more personalized experience of others' presence in the metaverse with these avatars.</p>
                <p className="contents">Metaverse avatar development streamlines the development of 3D avatars which allows the users to explore the virtual environment and experience a different aspect of the metaverse. These avatars are created through 3D modeling and animation to make them more realistic and attractive. These avatars can be customized to fit the user’s unique style. </p>
                <div className="met_game_what_about_btn_sec">
                  <a href="https://wa.me/9828284421" className="btn met_game_what_about_btn desktop" target="_blank"><i className="fab fa-whatsapp" />&nbsp;WhatsApp</a>
                  <a href="https://wa.me/9828284421" className="btn met_game_what_about_btn mobile" target="_blank"><i className="fab fa-whatsapp" />&nbsp;WhatsApp</a>
                  <a href="https://web.telegram.org/k/#@metablocktechnologies" className="btn met_game_what_about_btn1" target="_blank"><i className="fab fa-telegram" />&nbsp;Telegram</a>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="metaverse_game_whatsec">
          <div className="container">
            <div className="row align-items-center what-is-metaverse-nft-container">
              <div className="col-lg-7 col-12 nft_top">
                <img
                  src="./SGimage/metaverse game development-1 (1)-min.webp"
                  alt="Metaverse Game Development"
                  title="Metaverse Game Development"
                  className="img-fluid lazyload metav_game_dev_img What-is-metaverse-NFT-img"
                />
              </div>
              <div className="col-lg-5 col-12">
                <h2 className="ourservice-titles-new grident-color-sub-title">
                  What is Metaverse Avatar?
                </h2>
                <p className="contents">
                  Users have new opportunities for interaction in virtual
                  environments since metaverse avatars enable them to connect
                  with each other even when they are not physically there.
                  Everyone can have a more personalized experience of others'
                  presence in the metaverse with these avatars.
                </p>
                <p className="contents">
                  Metaverse avatar development streamlines the development of 3D
                  avatars which allows the users to explore the virtual
                  environment and experience a different aspect of the
                  metaverse. These avatars are created through 3D modeling and
                  animation to make them more realistic and attractive. These
                  avatars can be customized to fit the user’s unique style.{" "}
                </p>
                <div className="met_game_what_about_btn_sec">
                  <a
                    href="https://wa.me/9828284421"
                    className="btn met_game_what_about_btn desktop"
                    target="_blank"
                  >
                    <i className="fab fa-whatsapp" />
                    &nbsp;WhatsApp
                  </a>
                  <a
                    href="https://wa.me/9828284421"
                    className="btn met_game_what_about_btn mobile"
                    target="_blank"
                  >
                    <i className="fab fa-whatsapp" />
                    &nbsp;WhatsApp
                  </a>
                  <a
                    href="https://web.telegram.org/k/#@metablocktechnologies"
                    className="btn met_game_what_about_btn1"
                    target="_blank"
                  >
                    <i className="fab fa-telegram" />
                    &nbsp;Telegram
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_comp">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-12 metaverse_game_comp_cardsec">
                <div id="metaverse_game_comp_card">
                  <h2 className="grident-color-sub-title title-14">
                    Metaverse Avatar Development company
                  </h2>
                  <p className="contents">
                    Metaverse avatars that represent the user’s own reflection
                    are the most crucial thing in the digital realm. A user's
                    identity across the universe is represented by an avatar.
                    All elements of the metaverse can communicate with one
                    another thanks to avatars. The relationship between the real
                    user and their virtual existence will be reflected in the
                    characters. Additionally, the user can take advantage of all
                    the metaverse's capabilities, including winning challenges,
                    playing online games, and earning tokens.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_services">
          <div className="container">
            <h2 className="ourservice-titles-new grident-color-sub-title">
              Our Metaverse Avatar Development Services
            </h2>
            <p className="contents">
              We provide end-to-end solutions that facilitate your entry into
              and interaction with the metaverse. A list of our offerings is
            </p>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      style={{ height: "40vh" }}
                      src="./images/met_3dspace.webp"
                      alt="3D Space Development"
                      title="3D Space Development"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Development of 3D Space
                  </h3>
                  <p className="sub_contents">
                    Our team of talented game developers uses a variety of
                    technological stacks to adjust to changing game needs, which
                    enables them to create unique 3D maps and environments for
                    different games.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      style={{ height: "36vh" }}
                      src="./images/met_gamecredit.webp"
                      alt="Play to Earn Game Development"
                      title="Play to Earn Game Development"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Play to Get Developer Credit for Games
                  </h3>
                  <p className="sub_contents">
                    The usage of blockchain technology has completely changed
                    the user experience by providing a number of functions
                    including play-to-earn, which includes stake-to-earn,
                    move-to-earn, and create-to-earn.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      src="./images/met_owngame.webp"
                      alt="Custom Games Development"
                      title="Custom Games Development"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Develop Your Own Video Games
                  </h3>
                  <p className="sub_contents">
                    We craft games from unique concepts, customizing every
                    element to fit your vision. Our specialists create a gaming
                    experience that connects with your audience through
                    fascinating tales and captivating gameplay mechanics.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      src="./images/nftgame.webp"
                      alt="NFT Game Development"
                      title="NFT Game Development"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    NFT Game Creation
                  </h3>
                  <p className="sub_contents">
                    The goal of game creation is to use NFTs as game assets in
                    order to include them into gameplay, which is a crucial
                    aspect of Metaverse games.Integrating NFTs into gameplay
                    enriches Metaverse gaming experiences effectively.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      src="./images/met_3davatar.webp"
                      alt="3D Avatar Development"
                      title="3D Avatar Development"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Development of 3D Avatars
                  </h3>
                  <p className="sub_contents">
                    With our state-of-the-art technology, which provides
                    customisable hyper-realistic 3D avatars for games, real
                    estate transactions, NFT trading platforms, and countless
                    other interesting activities, you may immerse yourself in
                    the metaverse like never before.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      src="./images/met_gamecredit.webp"
                      alt="Metaverse Marketplace Development"
                      title="Metaverse Marketplace Development"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Development of Metaverse Marketplaces
                  </h3>
                  <p className="sub_contents">
                    Our skilled staff specializes in creating vibrant in-game
                    markets for seamless trading experiences. Navigate this
                    dynamic environment to discover unique treasures and connect
                    with other players, unlocking endless opportunities.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      src="./images/gamereleated_dapps.webp"
                      alt="Gaming dApps"
                      title="Gaming dApps"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Games-related dApps
                  </h3>
                  <p className="sub_contents">
                    Embark on a journey into decentralized realms, where
                    peer-to-peer transactions and transparent gameplay await.
                    With blockchain-powered gaming dApps, we redefine the gaming
                    sector, ensuring safety, fairness, and engaging experiences
                    for all players.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      src="./images/game_decentralized.webp"
                      alt="Decentralized Gaming Platform"
                      title="Decentralized Gaming Platform"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Decentralized Platform for Gaming
                  </h3>
                  <p className="sub_contents">
                    A distributed ledger and smart contracts regulate every
                    facet of the gaming experience on a decentralized gaming
                    platform, including player interactions, game development,
                    item ownership, and rewards distribution.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service_box">
                  <div className="img_sec">
                    <img
                      style={{ height: "30vh" }}
                      src="./images/game (1).webp"
                      alt="Decentralized Gaming Platform"
                      title="Decentralized Gaming Platform"
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    NFT Integration in Metaverse Game Development
                  </h3>
                  <p className="sub_contents">
                    NFTs in Metaverse game dev redefine ownership, fostering
                    dynamic economies. Blockchain ensures security,
                    immutability. Cross-platform play boosts engagement, shaping
                    the future of virtual gaming.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_benefits">
          <div className="container">
            <h2 className="grident-color-sub-title ourservice-titles-new">
              Benefits of the Metaverse Avatar for Business{" "}
            </h2>
            <p className="contents">
              Playing metaverse Avatar has benefits for players, producers, and
              the gaming industry overall. Among the advantages for businesses
              are:
            </p>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="benefits_box clippath1">
                  <div className="img_sec">
                    <img
                      style={{
                        width: "80px",
                        height: "fit-content",
                        borderRadius: "50px",
                      }}
                      src="./SGimage/280448-P6AHMF-297-min.webp"
                      alt="Opportunities for the economy"
                      title="Opportunities for the economy"
                      className=""
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Prospects for the economy
                  </h3>
                  <p className="sub_contents">
                    Games in the metaverse offer new opportunities for players,
                    developers, and businesses. While players can earn virtual
                    cash or exchange virtual products, developers can create
                    additional revenue streams through in-game advertising or
                    subscription-based business models.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="benefits_box cp_center">
                  <div className="img_sec">
                    <img
                      src="./SGimage/3828822.webp"
                      style={{
                        width: "80px",
                        height: "fit-content",
                        borderRadius: "50px",
                      }}
                      alt="Opportunities for the economy"
                      title="Opportunities for the economy"
                      className=""
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Fascinating Avatar
                  </h3>
                  <p className="sub_contents">
                    Players can escape reality and explore new worlds with the
                    rich and captivating gaming environment that metaverse games
                    offer.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="benefits_box clippath2">
                  <div className="img_sec">
                    <img
                      src="./SGimage/6213049.webp"
                      style={{
                        width: "80px",
                        height: "fit-content",
                        borderRadius: "50px",
                      }}
                      alt="Opportunities for the economy"
                      title="Opportunities for the economy"
                      className=""
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Social exchange
                  </h3>
                  <p className="sub_contents">
                    Metaverse Avatar provide an environment for it, allowing
                    users to socialize and form groups based on shared
                    interests.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="benefits_box clippath1">
                  <div className="img_sec">
                    <img
                      src="./SGimage/6681650-min.webp"
                      style={{
                        width: "80px",
                        height: "fit-content",
                        borderRadius: "50px",
                      }}
                      alt="Opportunities for the economy"
                      title="Opportunities for the economy"
                      className=""
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Artistic manifestation
                  </h3>
                  <p className="sub_contents">
                    Players in metaverse games can express their creativity by
                    creating virtual things, creating avatars, and creating
                    their own virtual spaces.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="benefits_box cp_center">
                  <div className="img_sec">
                    <img
                      src="./SGimage/6594175-min.webp"
                      style={{
                        width: "80px",
                        height: "fit-content",
                        borderRadius: "50px",
                      }}
                      alt="Opportunities for the economy"
                      title="Opportunities for the economy"
                      className=""
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Novel concepts
                  </h3>
                  <p className="sub_contents">
                    Metaverse games are expanding the boundaries of what is
                    conceivable in gaming by fusing virtual reality, artificial
                    intelligence, and blockchain technology.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="benefits_box clippath2">
                  <div className="img_sec">
                    <img
                      src="./SGimage/52124-min.webp"
                      style={{
                        width: "80px",
                        height: "fit-content",
                        borderRadius: "50px",
                      }}
                      alt="Opportunities for the economy"
                      title="Opportunities for the economy"
                      className=""
                    />
                  </div>
                  <h3 className="grident-color-sub-title sub_title">
                    Reachable
                  </h3>
                  <p className="sub_contents">
                    A broader spectrum of players, including those who would not
                    have access to traditional gaming platforms, may usually
                    play games in the metaverse.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="metaverse_game_unique">
          <div className="container">
            <h2 className="grident-color-sub-title ourservice-titles-new">
              Exclusive Features of Developing Metaverse Avatar
            </h2>
            <p className="contents">
              There are many options for unique features that could enhance the
              user experience when creating metaverse games. These are a few
              examples of traits that might set a metaverse game apart.
            </p>
            <div className="row justify-content-center row_line">
              <div className="col-lg-4 col-md-6 col-12 col_line">
                <div className="unique_box">
                  <img
                    src="./SGimage/2103.i039.026.cryptographic_art_crypto_art_NFT_isometric-min.webp"
                    style={{
                      width: "150px",
                      height: "fit-content",
                      borderRadius: "5px",
                    }}
                    alt="Opportunities for the economy"
                    title="Opportunities for the economy"
                    className=""
                  />
                  <h3 className="grident-color-sub-title sub_title">
                    Decentralized Economy and Ownership
                  </h3>
                  <p className="sub_contents">
                    Players can buy, sell, and trade virtual products with other
                    players in a decentralized market by having actual ownership
                    over their virtual assets in a metaverse game that makes use
                    of blockchain technology.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 col_line">
                <div className="unique_box">
                  <img
                    src="./SGimage/52221.webp"
                    style={{
                      width: "150px",
                      height: "fit-content",
                      borderRadius: "5px",
                    }}
                    alt="Opportunities for the economy"
                    title="Opportunities for the economy"
                    className=""
                  />
                  <h3 className="grident-color-sub-title sub_title">
                    Incorporating virtual reality technology
                  </h3>
                  <p className="sub_contents">
                    A metaverse game can make players feel more involved and
                    immersed by using virtual reality (VR) technology, which
                    makes it seem as though they are physically within the game
                    world.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 col_line">
                <div className="unique_box">
                  <img
                    src="./SGimage/6585595.webp"
                    style={{
                      width: "200px",
                      height: "fit-content",
                      borderRadius: "5px",
                    }}
                    alt="Opportunities for the economy"
                    title="Opportunities for the economy"
                    className=""
                  />
                  <h3 className="grident-color-sub-title sub_title">
                    Material created by users
                  </h3>
                  <p className="sub_contents">
                    Allows users to create and share their own material within
                    the game world, such as personalized avatars, virtual
                    locations, and items, which can increase player engagement
                    and promote a sense of community.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 col_line">
                <div className="unique_box">
                  <img
                    src="./SGimage/world metaverse.webp"
                    style={{
                      width: "150px",
                      height: "fit-content",
                      borderRadius: "5px",
                    }}
                    alt="Opportunities for the economy"
                    title="Opportunities for the economy"
                    className=""
                  />
                  <h3 className="grident-color-sub-title sub_title">
                    A world that is dynamic and evolving
                  </h3>
                  <p className="sub_contents">
                    A metaverse virtual showroom might provide a dynamic,
                    constantly-evolving gaming environment with missions and
                    other features that adapt to the needs of the community and
                    player behavior.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 col_line">
                <div className="unique_box">
                  <img
                    src="./SGimage/album artwork.webp"
                    style={{
                      width: "150px",
                      height: "fit-content",
                      borderRadius: "5px",
                    }}
                    alt="Opportunities for the economy"
                    title="Opportunities for the economy"
                    className=""
                  />
                  <h3 className="grident-color-sub-title sub_title">
                    Compatibility between platforms
                  </h3>
                  <p className="sub_contents">
                    Both the player base and accessibility may grow if players
                    can access the game from a range of platforms, including
                    PCs, smartphones, and gaming consoles.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 col_line">
                <div className="unique_box">
                  <img
                    src="./SGimage/trending on artstation.webp"
                    style={{
                      width: "100px",
                      height: "fit-content",
                      borderRadius: "5px",
                    }}
                    alt="Opportunities for the economy"
                    title="Opportunities for the economy"
                    className=""
                  />
                  <h3 className="grident-color-sub-title sub_title">
                    Integration in the real world
                  </h3>
                  <p className="sub_contents">
                    Real-world events like sporting competitions or concerts can
                    be included into the game world to offer players
                    one-of-a-kind experiences and to give brands a chance to
                    market to consumers.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="unique_box">
                  <img
                    src="./SGimage/artificial ai.webp"
                    style={{
                      width: "150px",
                      height: "fit-content",
                      borderRadius: "5px",
                    }}
                    alt="Opportunities for the economy"
                    title="Opportunities for the economy"
                    className=""
                  />
                  <h3 className="grident-color-sub-title sub_title">
                    Artificial Intelligence Integration
                  </h3>
                  <p className="sub_contents">
                    By incorporating intelligence, a metaverse game can provide
                    more challenging and realistic non-player characters (NPCs),
                    in addition to dynamically generated events and obstacles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_model">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="grident-color-sub-title ourservice-titles-new">
                  Development of a Metaverse Avatar business model
                </h2>
                <p className="contents">
                  Because constructing a metaverse virtual Avatar could be
                  costly, it is essential to have a distinct company location in
                  order to generate revenue. The following is a list of several
                  popular business models for developing metaverse:
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 col-12 p-lg-0">
                <img
                  src="./SGimage/6620122.webp"
                  alt="Business model for Metaverse game development"
                  title="Business model for Metaverse game development"
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-lg-6 col-12 pr-lg-5 p-5">
                <div className="row justify-content-center pr-lg-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="model_box">
                      <h3 className="grident-color-sub-title sub_title">
                        Model based on subscribers
                      </h3>
                      <p className="sub_contents">
                        You can pay a monthly or annual fee to access the games.
                        This steady revenue stream can encourage players to stay
                        in the game longer.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="model_box">
                      <h3 className="grident-color-sub-title sub_title">
                        Virtual goods sales
                      </h3>
                      <p className="sub_contents">
                        Gamers can purchase virtual goods for their avatars,
                        such as furniture, clothing, and accessories. This
                        strategy generates revenue through the markup-based sale
                        of virtual products.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="model_box">
                      <h3 className="grident-color-sub-title sub_title">
                        Advertisements within games
                      </h3>
                      <p className="sub_contents">
                        Advertisements are a way for game makers to make money.
                        This tactic might be effective if the adverts are
                        relevant to the player and don't get in the way of their
                        gameplay.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="model_box">
                      <h3 className="grident-color-sub-title sub_title">
                        High-end model
                      </h3>
                      <p className="sub_contents">
                        To access additional content or premium services, users
                        must pay. Players can test the game out with this system
                        before determining whether to invest more money in it.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="model_box">
                      <h3 className="grident-color-sub-title sub_title">
                        NFT transactions
                      </h3>
                      <p className="sub_contents">
                        It is up to game developers to create unique in-game
                        products and market them to players as NFTs. If there is
                        a high demand for the products, this approach could be
                        very profitable.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="model_box">
                      <h3 className="grident-color-sub-title sub_title">
                        Type of sponsorship model
                      </h3>
                      <p className="sub_contents">
                        Brands and game developers can collaborate to sponsor
                        in-game events or activities. This idea for a business
                        can make money and provide players with engaging
                        experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_process">
          <div className="container">
            <div className="bg_title">METAVERSE</div>
            <div className="bg_title1">
              A<br />V<br />A<br />T<br />A<br />R
            </div>
            <h2 className="grident-color-sub-title ourservice-titles-new">
              Development Process For Metaverse Avatar
            </h2>
            <p className="contents">
              To construct a metaverse avatar, we should adhere to a few
              guidelines.
            </p>
            <div className="row justify-content-center process_after">
              <div className="col-lg-12">
                <div className="row process_list_after">
                  <div className="col-lg-6 col-md-6 col-12" />
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="process_box">
                      <p className="sub_contents">
                        Choose a metaverse avatar engine first. A metaverse
                        virtual showroom can be developed using a variety of
                        game engines, including Unreal Engine, Unity 3D, and
                        CryEngine. Prior to choosing one, it is essential to
                        research and compare each engine because each one has
                        pros and cons of its own.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row process_list_after">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="process_box">
                      <p className="sub_contents">
                        We then focus on the design aspect since a visually
                        beautiful, immersive, and engaging gaming environment is
                        essential. Typically, programs like Maya, Blender, and
                        3D Studio Max are used to build games.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12" />
                </div>
                <div className="row process_list_after">
                  <div className="col-lg-6 col-md-6 col-12" />
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="process_box">
                      <p className="sub_contents">
                        The gameplay will then need to be developed, and it
                        should be made to be entertaining and captivating with
                        lots of hard goals and incentives for players. Playing
                        this game involves engaging in social interaction,
                        exploration, fighting, and puzzles.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row process_list_after">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="process_box">
                      <p className="sub_contents">
                        Next, include social elements like text and voice chat,
                        group activities, and real-time communication between
                        members.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12" />
                </div>
                <div className="row process_list_after">
                  <div className="col-lg-6 col-md-6 col-12" />
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="process_box">
                      <p className="sub_contents">
                        Create virtual economies by specifying the currency and
                        the ways in which players can obtain it after completing
                        the aforementioned stage. You might even create an
                        online marketplace where people can exchange virtual
                        currency for actual commodities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row process_list_after">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="process_box">
                      <p className="sub_contents">
                        Next, put blockchain technology into practice, which
                        gives players a feeling of control over their in-game
                        belongings and delivers decentralization, security, and
                        transparency.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12" />
                </div>
                <div className="row process_list_after">
                  <div className="col-lg-6 col-md-6 col-12" />
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="process_box">
                      <p className="sub_contents">
                        To ensure that the game is dependable and entertaining
                        to play, it is imperative that it be tested and refined
                        after it has been established.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_clone">
          <div className="container">
            <h2 className="grident-color-sub-title ourservice-titles-new">
              List Metaverse Avatar Clone Scripts
            </h2>
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-12 col-12 pr-lg-0">
                <div className="clone_list_sec">
                  <div className="clone_list">
                    <img
                      src="./SGimage/right-arrow.webp"
                      alt="Clone List"
                      title="Clone List"
                      className="img-fluid lazyload"
                      style={{ width: "25px" }}
                    />
                    <div className="grident-color-sub-title sub_title">
                      Alien worlds clone script
                    </div>
                  </div>
                  <div className="clone_list">
                    <img
                      src="./SGimage/right-arrow.webp"
                      alt="Clone List"
                      title="Clone List"
                      className="img-fluid lazyload"
                      style={{ width: "25px" }}
                    />
                    <div className="grident-color-sub-title sub_title">
                      Sorare clone script
                    </div>
                  </div>
                  <div className="clone_list">
                    <img
                      src="./SGimage/right-arrow.webp"
                      alt="Clone List"
                      title="Clone List"
                      className="img-fluid lazyload"
                      style={{ width: "25px" }}
                    />
                    <div className="grident-color-sub-title sub_title">
                      Decentraland clone
                    </div>
                  </div>
                  <div className="clone_list">
                    <img
                      src="./SGimage/right-arrow.webp"
                      alt="Clone List"
                      title="Clone List"
                      className="img-fluid lazyload"
                      style={{ width: "25px" }}
                    />
                    <div className="grident-color-sub-title sub_title">
                      Neondis clone script
                    </div>
                  </div>
                  <div className="clone_list">
                    <img
                      src="./SGimage/right-arrow.webp"
                      alt="Clone List"
                      title="Clone List"
                      className="img-fluid lazyload"
                      style={{ width: "25px" }}
                    />
                    <div className="grident-color-sub-title sub_title">
                      Sandbox clone script
                    </div>
                  </div>
                  <div className="clone_list">
                    <img
                      src="./SGimage/right-arrow.webp"
                      alt="Clone List"
                      title="Clone List"
                      className="img-fluid lazyload"
                      style={{ width: "25px" }}
                    />
                    <div className="grident-color-sub-title sub_title">
                      Horizon worlds clone script
                    </div>
                  </div>
                  <div className="clone_list">
                    <img
                      src="./SGimage/right-arrow.webp"
                      alt="Clone List"
                      title="Clone List"
                      className="img-fluid lazyload"
                      style={{ width: "25px" }}
                    />
                    <div className="grident-color-sub-title sub_title">
                      Second Life clone script
                    </div>
                  </div>
                  <div className="clone_list">
                    <img
                      src="./SGimage/right-arrow.webp"
                      alt="Clone List"
                      title="Clone List"
                      className="img-fluid lazyload"
                      style={{ width: "25px" }}
                    />
                    <div className="grident-color-sub-title sub_title">
                      Ember sword clone script
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-12 pl-0 nft_top">
                <img
                  src="./SGimage/game_dev.webp"
                  alt="Clone List"
                  title="Clone List"
                  className="img-fluid lazyload text-center"
                  style={{ width: "500px", borderRadius: "5px" }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_category">
          <div className="container">
            <h2 className="grident-color-sub-title ourservice-titles-new">
              Various Metaverse Avatar categories that we create
            </h2>
            <p className="contents">
              Utilizing our understanding of numerous popular blockchain
              networks and tech stacks, we focus on the following areas.
            </p>
            <div className="row align-items-center justify-content-center category_box">
              <div className="col-lg-7 col-12">
                <img
                  src="./SGimage/Virtual reality.webp"
                  alt="Business model for Metaverse game development"
                  title="Business model for Metaverse game development"
                  className="img-fluid lazyload"
                  style={{ width: "500px", borderRadius: "500px" }}
                />
              </div>
              <div className="col-lg-5 col-md-12 col-12">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="category_list">
                      <img
                        src="./SGimage/video-game.webp"
                        alt="Adventure"
                        title="Adventure"
                        className="sprite_icons lazyload"
                        style={{ objectPosition: "-5px -7px" }}
                      />
                      <div className="grident-color-sub-title sub_title">
                        Adventure
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="category_list">
                      <img
                        src="./SGimage/flags.webp"
                        alt="Racing"
                        title="Racing"
                        className="sprite_icons lazyload"
                      />
                      <div className="grident-color-sub-title sub_title">
                        Racing
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="category_list">
                      <img
                        src="./SGimage/action-game.webp"
                        alt="Action"
                        title="Action"
                        className="sprite_icons lazyload"
                      />
                      <div className="grident-color-sub-title sub_title">
                        Action
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="category_list">
                      <img
                        src="./SGimage/video-games.webp"
                        alt="Simulation"
                        title="Simulation"
                        className="sprite_icons lazyload"
                      />
                      <div className="grident-color-sub-title sub_title">
                        Simulation
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="category_list">
                      <img
                        src="./SGimage/cricket.webp"
                        alt="Sports"
                        title="Sports"
                        className="sprite_icons lazyload"
                      />
                      <div className="grident-color-sub-title sub_title">
                        Sports
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="category_list">
                      <img
                        src="./SGimage/card-game.webp"
                        alt="skill"
                        title="Skill"
                        className="sprite_icons lazyload"
                      />
                      <div className="grident-color-sub-title sub_title">
                        Skill
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="category_list">
                      <img
                        src="./SGimage/jigsaw.webp"
                        alt="Puzzle"
                        title="Puzzle"
                        className="sprite_icons lazyload"
                      />
                      <div className="grident-color-sub-title sub_title">
                        Puzzle
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_industry">
          <div className="container">
            <h2 className="grident-color-sub-title ourservice-titles-new">
              Examining Services for Metaverse Avatar Development for Different
              Industries
            </h2>
            <p className="contents">
              We provide the following enterprise-focused solutions to help
              businesses connect with the metaverse.
            </p>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="industry_box">
                  <div className="grident-color-sub-title sub_title">
                    Real-estate
                  </div>
                  <img
                    src="./images/real-estate2.webp"
                    alt="Real Estate"
                    title="Real Estate"
                    className="sprite_icons lazyload"
                    style={{ border: "1px solid #C434E5", borderRadius: "1px" }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="industry_box">
                  <div className="grident-color-sub-title sub_title">
                    Gaming
                  </div>
                  <img
                    src="./images/gamings.webp"
                    alt="Real Estate"
                    title="Real Estate"
                    className="sprite_icons lazyload"
                    style={{ border: "1px solid #C434E5", borderRadius: "1px" }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="industry_box">
                  <div className="grident-color-sub-title sub_title">
                    Fashion
                  </div>
                  <img
                    src="./images/dress.webp"
                    alt="Real Estate"
                    title="Real Estate"
                    className="sprite_icons lazyload"
                    style={{ border: "1px solid #C434E5", borderRadius: "1px" }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="industry_box">
                  <div className="grident-color-sub-title sub_title">
                    Entertainment
                  </div>
                  <img
                    src="./images/entertainment.webp"
                    alt="Real Estate"
                    title="Real Estate"
                    className="sprite_icons lazyload"
                    style={{ border: "1px solid #C434E5", borderRadius: "1px" }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="industry_box">
                  <div className="grident-color-sub-title sub_title">
                    Social media
                  </div>
                  <img
                    src="./images/social.webp"
                    alt="Real Estate"
                    title="Real Estate"
                    className="sprite_icons lazyload"
                    style={{ border: "1px solid #C434E5", borderRadius: "1px" }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="industry_box">
                  <div className="grident-color-sub-title sub_title">
                    Tourism
                  </div>
                  <img
                    src="./images/tourisrs.webp"
                    alt="Real Estate"
                    title="Real Estate"
                    className="sprite_icons lazyload"
                    style={{ border: "1px solid #C434E5", borderRadius: "1px" }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="industry_box">
                  <div className="grident-color-sub-title sub_title">
                    Ecommerce
                  </div>
                  <img
                    src="./images/ecommerces.webp"
                    alt="Real Estate"
                    title="Real Estate"
                    className="sprite_icons lazyload"
                    style={{ border: "1px solid #C434E5", borderRadius: "1px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="token_dev_techstack">
          <div className="container">
            <h2 className="grident-color-sub-title ourservice-title">
              Our Technology Stack
            </h2>
            <div className="row tech ">
              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/physics.webp"
                  className="w-100 h-40"
                  alt="React"
                />
                <h5 className="grident-color-sub-title">React</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/python.webp"
                  alt="Python"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">Python</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/mysql.webp"
                  alt="MySQL"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">MySQL</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/java-script.webp"
                  alt="Java Srcipt"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">Java Srcipt</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/SQL-Lite.webp"
                  alt="SQL Lite"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">SQL Lite</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/html.webp"
                  alt="HTML"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">HTML</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/android.webp"
                  alt="Android"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">Android</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/mongo-db-icon.webp"
                  alt="Mongo DB"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">Mongo DB</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/app-store.webp"
                  alt="App Store"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">App Store</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/atom.webp"
                  alt="Atom"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">Atom</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/laravel.webp"
                  alt="Laravel"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">Laravel</h5>
              </div>

              <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                <img
                  src="./images/python.webp"
                  alt="Python"
                  className="w-100 h-40"
                />
                <h5 className="grident-color-sub-title">Python</h5>
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_hire">
          <div className="container-fluid">
            <h2 className="grident-color-sub-title ourservice-titles-new">
              Employ Developers for Metaverse Avatar
            </h2>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-12 pl-lg-5">
                <div className="pl-lg-5">
                  <p className="contents">
                    With the assistance of a professional team of Metaverse
                    Avatar developers, MetaBlock continuously works to improve
                    the metaverse environment and the platforms that are
                    connected to it. In an attempt to boost innovation in the
                    gaming business, our creative team is working as a metaverse
                    game developer to incorporate many cutting-edge components
                    in the metaverse games. Increasing player interaction with
                    the game is the aim of metaverse games.MetaBlock is working
                    on improving the metaverse environment and graphics to make
                    the metaverse game more visually appealing, interactive, and
                    engaging for players.
                  </p>
                  <p className="contents">
                    As a metaverse avatar designer, the MetaBlock team uses a
                    range of blockchain-related technology stacks and tools to
                    give players the best possible visual experience while
                    building your game platform from the ground up in accordance
                    with your game plan. The experts at MetaBlock also focus on
                    boosting the worth of your business's portfolio by
                    developing a platform with unique features and a range of
                    visually appealing elements that attract to internet
                    consumers. Engage with our knowledgeable metaverse game
                    developers to discuss your unique concepts for enhancing the
                    real-world gaming experience.
                  </p>
                  <div className="metaverse_game_btn_sec">
                    <button
                      toggle="modal"
                      target="#popup_form_modal"
                      className="btn BtnPrimry TlkBtn metaverse_game_btn"
                    >
                      <Link to="/contact-us">
                        {" "}
                        <span
                          alt="enquiry"
                          loading="eager"
                          className="img-fluid lazyload mr-2 lazyload"
                        >
                          Get A Live Demo
                        </span>
                      </Link>
                      <span
                        alt="enquiry"
                        loading="eager"
                        className="img-fluid lazyload mr-2 lazyload"
                      >
                        Get A Live Demo
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 p-lg-0 nft_top">
                <img
                  src="./images/employ_develpoer.webp"
                  alt="Hire Metaverse Game Developers"
                  title="Hire Metaverse Game Developers"
                  className="img-fluid lazyload"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="metaverse_game_whydo">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-12 col-12">
                <div className="img_sec"></div>
                <div className="why_do_box">
                  <h2 className="grident-color-sub-title ourservice-titles-new">
                    Why is MetaBlock necessary for developing Metaverse Avatar?
                  </h2>
                  <p className="contents">
                    One of India's leading Metaverse Avatar developers,
                    MetaBlock, is currently developing a futuristic game with
                    cutting-edge features. MetaBlock has access to a group of
                    more than fifty knowledgeable developers. Regarding the
                    present blockchain ecosystem, which includes Ethereum,
                    Binance Smart Chain, Solana, Cardano, Polygon, and others,
                    the professionals at MetaBlock are extremely knowledgeable.
                  </p>
                  <p className="contents">
                    With complete awareness of the Metaverse development,
                    MetaBlock will enable you to launch your metaverse game and
                    make a name for yourself online. If you release your game
                    now, you will have the opportunity to participate in the
                    future gaming empire when the Metaverse gaming community
                    leaps to unprecedented heights in the upcoming years.For
                    clients worldwide, MetaBlock has successfully developed over
                    200 blockchain-related platforms. Make an appointment with
                    MetaBlock experts to bring your virtual game to life.
                  </p>
                  <h3 className="grident-color-sub-title sub_title">
                    The following are our possible gains in the creation of a
                    metaverse avatar:
                  </h3>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-12">
                      <div className="whydo_list">
                        <img
                          src="./SGimage/right-arrow.webp"
                          alt="Game-Pad"
                          title="Game Pad"
                          className="met_advant"
                        />
                        <h4 className="sub_contents">
                          Development Across Platforms
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="whydo_list">
                        <img
                          src="./SGimage/right-arrow.webp"
                          alt="Game-Pad"
                          title="Game Pad"
                          className="met_advant"
                        />
                        <h4 className="sub_contents">Low-Cost Innovation</h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="whydo_list">
                        <img
                          src="./SGimage/right-arrow.webp"
                          alt="Game-Pad"
                          title="Game Pad"
                          className="met_advant"
                        />
                        <h4 className="sub_contents">
                          A track record of success
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="whydo_list">
                        <img
                          src="./SGimage/right-arrow.webp"
                          alt="Game-Pad"
                          title="Game Pad"
                          className="met_advant"
                        />
                        <h4 className="sub_contents">Personalization</h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="whydo_list">
                        <img
                          src="./SGimage/right-arrow.webp"
                          alt="Game-Pad"
                          title="Game Pad"
                          className="met_advant"
                        />
                        <h4 className="sub_contents">Media Immersion</h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="whydo_list">
                        <img
                          src="./SGimage/right-arrow.webp"
                          alt="Game-Pad"
                          title="Game Pad"
                          className="met_advant"
                        />
                        <h4 className="sub_contents">Continuous Assistance</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default MetaverseAvatarDevelopment;
