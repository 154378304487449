import React from "react";
import "../WebApplicationMainPage.css";
import OnDemandAppSlider from "../OnDemandAppSlider";

import SimilarFile from "../../SimilarFile";

import { useNavigate } from "react-router-dom";
import SwiperSlider from "../SoftwarePages/SwiperSlider/SwiperSlider";

import GrocerySwiperSlider from "./ApplicationSwiperSlider/GrocerySwiperSlider";
import SeoHelmet from "../../../Helmet";
import TechnologyStack from "../../../components/TechnologyStack";
import faqData from "../../faqData";
import FAQSection2 from "../../FAQSection2";
import projectData from "../../projectData";
import OurProjects from "../../OurProjects";
import AdminControlFeatures from "../../AdminControlFeatures";
import Industries from "../../Industries";
import KeyFeatures from "../../KeyFeatures";

const GroceryApps = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <SeoHelmet
          pagetitle="On-Demand Custom Grocery App Development Company"
          description="MetaBlock Technologies leading a best grocery app development company in jaipur. We offer top-notch grocery app development solutions with the top quality grocery app developers.
"
keywords="Grocery App Development Company"
        />
        <div className="main-div-Application">
          <header className="enterprise-mobile-app-development arkit header-shadow">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <h1 className="mb-5">
                      On Requirement Best Grocery App Development Company
                    </h1>
                    <p className="heading-tagline">
                      Grocery app development company allows making purchase
                      orders for grocery items easily. We make grocery
                      applications User friendly browse, place, and then have
                      good delivery right at-a-time. This will make the whole
                      purchasing method convenient and less time-consuming.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="Enterprise-container">
            <div className="Enterprise-box">
              <div className="Enterprise-left-div">
                <h2 className="Key-feature-heading">
                  MetaBlock Technologies Specialist Like Grocery App
                </h2>
                <p style={{ fontSize: "20px" }}>
                  Metablock Technologies' grocery app development services makes
                  healthy shopping easy. It helps you make better options for
                  your good health. It has grocery shopping app development
                  features like nutritional information and personalized meal
                  plans. We offer a wide range of Grocery app services, from
                  organic produce to permanent basis sourced items, that support
                  both your virtue and the planet. Enjoy grocery shopping as a
                  simple, healthy habit in just one tap.
                </p>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#popup_form_modal"
                  class="btn BtnPrimry TlkBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  <span>Talk to our Experts</span>
                  <span>Talk to our Experts</span>
                </button>
              </div>
              <div className="Enterprise-right-div">
                <img src="./images/cabserviceimg.webp" alt="cabserviceimg" />
              </div>
            </div>
          </section>

          <GrocerySwiperSlider />
          <KeyFeatures
            heading=" Criteria Of Key Features "
            description=" Key Features of Our Custom Software Development Services: Custom Solutions for Maximum Impact"
            filterKey="software" // Pass 'software' as filterKey
          />
          <TechnologyStack/>
          
          <Industries
              category="software"
              heading="Industries That Use Our Software Solutions" // Dynamic heading for software
            />
          

         
          <AdminControlFeatures
            category="software"
            heading="Admin Control Features: The Backbone of Secure System Management" // Dynamic heading for admin control
          />
         
          <section className="OnDemandApp_section">
            <OnDemandAppSlider />
          </section>
          <OurProjects
            projectData={projectData}
            heading="Explore our impressive portfolio showcase."
            description=" We offer a range of services including web development, Flutter app development, game development, software development, mobile app development and blockchain development."
            filterKey="mobileapp" // Display only software projects
          />
          <section>
            <SimilarFile />
          </section>
        </div>
        <FAQSection2 category="groceryapp"/>
      </div>
    </>
  );
};

export default GroceryApps;
