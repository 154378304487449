const keyData = {
  software: [
    {
      id: 1,
      title: "User  Interface",
      description:
        "A Good UI enables users to navigate and use software or devices easily without confusion.",
      image: "./images/user-interface.webp",
    },
    {
      id: 2,
      title: "Real-Time Updates",
      description:
        "It means technology will continuously give updates and information from time to time.",
      image: "./images/system-update.webp",
    },
    {
      id: 3,
      title: "Customization Options",
      description:
        "Allowing to modify and customize softwares for your reliable occupations and companies.",
      image: "./images/customization.webp",
    },
    {
      id: 4,
      title: "Data Security",
      description:
        "Data security gives protection for unauthorized users' access, corrupt files/data, theft, or losses. Data security is helpful in internal systems/technology.",
      image: "./images/encrypted.webp",
    },
    {
      id: 5,
      title: "Scalability",
      description:
        "An ability to measure the system control. Provide a report of work and show the losses and profits. Gives the necessary advice for improvements.",
      image: "./images/user-interface.webp",
    },
    {
      id: 6,
      title: " Automation Features",
      description:
        "The feature helps reduce errors, performs tasks automatically without the help of humans, and saves the time of the system. ",
      image: "./images/system-update.webp",
    },
    {
      id: 7,
      title: "Collaboration Tools",
      description:
        "Collaboration – means community. A collaboration tool is a digital platform designed to help teams work together seamlessly, no matter where they are.",
      image: "./images/customization.webp",
    },
    {
      id: 8,
      title: "Analytics and reports",
      description:
        "Data backup is the process of copying important files to avoid loss due to system failure, theft, or damage, ensuring information can be restored.",
      image: "./images/encrypted.webp",
    },
  ],
  erp: [
    {
      id: 1,
      title: "Inventory Monitoring",
      description:
        "Inventory monitoring tracks stock levels, ensuring optimal supply, reducing waste, and improving operations for efficient resource management.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 2,
      title: "Make Better Decision",
      description:
        "Make better decisions by analyzing options, considering long-term effects, trusting your intuition, and learning from past experiences.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/1.webp",
    },
    {
      id: 3,
      title: "Remote Access",
      description:
        "Remote access allows users to connect to a computer or network from a distant location, enabling efficient work and data access without being physically present.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/5.webp",
    },
    {
      id: 4,
      title: "Comfortable Sales System",
      description:
        "A Comfortable Sales System streamlines transactions, enhances customer experience, and boosts efficiency, ensuring a seamless process from start to finish.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 5,
      title: "Versatile Service",
      description:
        "Versatile service offers flexible solutions to meet diverse needs, ensuring efficiency, adaptability, and quality across various industries and customer requirements.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
    {
      id: 6,
      title: "Bulk Data Insert",
      description:
        "Bulk data insert is a process of adding large volumes of data to a database efficiently, reducing time and enhancing performance for large datasets.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 7,
      title: "Spectacular Dashboard",
      description:
        "A spectacular dashboard offers intuitive design, real-time data, and interactive features, enabling users to easily track, analyze, and visualize key metrics.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 8,
      title: "Data Backup",
      description:
        "Data backup is the process of copying important files to prevent loss due to system failure, theft, or damage, ensuring information can be restored.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
  ],
  pos: [
    {
      id: 1,
      title: "Sales Transaction",
      description:
        "Handles all aspects of the deals process, including point scanning, pricing, abatements, and final payment processing.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 2,
      title: "Inventory Management",
      description:
        " racks stock Situations in real-time, manages to reorder and provides cautions when force is low. It can also induce detailed reports on product performance.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/1.webp",
    },
    {
      id: 3,
      title: "Payment Processing",
      description:
        " Supports a variety of payment styles, including credit/ disbenefit cards, mobile payments, and cash. Numerous systems also integrate with payment gateways for secure deals.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/5.webp",
    },
    {
      id: 4,
      title: "Customer Management",
      description:
        "Stores Client data, including contact information and purchase history, to enable individualized service,  fidelity programs, and targeted marketing juggernauts.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 5,
      title: "Employee Management",
      description:
        "Manages hand laces, schedules, and time shadowing. It can also induce reports on hand performance and deal benefactions.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
    {
      id: 6,
      title: "Reporting & Analytics",
      description:
        "Provides detailed reports on deals,  force,  client geste, and hand performance. Helps businesses dissect trends, identify openings, and ameliorate decision-  timber.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 7,
      title: "Multi-Location",
      description:
        "Enables businesses with multiple locales to manage deals,  force, and reporting from a centralized system,  furnishing a unified view across all branches",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 8,
      title: "Security Features",
      description:
        "Includes part-grounded access control, secure payment processing, encryption, and fraud forestallment tools to ensure the safety of deals and sensitive data.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
  ],
  inventory: [
    {
      id: 1,
      title: "Multi-Location Support",
      description:
        "Manage inventory across multiple warehouses or locations seamlessly.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 2,
      title: " Real-Time Inventory",
      description:
        " Monitor the stock levels, locations, and movements in real-time.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/1.webp",
    },
    {
      id: 3,
      title: "Barcode & QR Scanning",
      description:
        " This technology makes it easy to update stock information and monitor things.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/5.webp",
    },
    {
      id: 4,
      title: "Stock Alert/Notification",
      description:
        "Get instant alerts for low stock, overstock, or expiring items.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 5,
      title: "Reporting & Analytics",
      description:
        "Get insights into the performance of inventory through detailed reports and dashboards.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
    {
      id: 6,
      title: "Customizable Feature",
      description:
        "Customize the software to meet your unique business needs.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 7,
      title: "User Access Control",
      description:
        "Assign roles and permissions to ensure secure data access.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 8,
      title: "Order Management",
      description:
        "All of these (purchase orders, sales orders, returns) are optimized for smooth operation.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
  ],
  accounting: [
    {
      id: 1,
      title: "Invoicing and Billing",
      description:
        "Automated Invoice Generation, Customization And Recurring Billing.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 2,
      title: " Expense Management",
      description:
        " Track And Categorize Expenses Orderliness.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/1.webp",
    },
    {
      id: 3,
      title: "Payment Integration",
      description:
        " Support For Multiple Payment Gateway And Method.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/5.webp",
    },
    {
      id: 4,
      title: " Tax Calculation",
      description:
        " Automated Tax Computation And Compliance With Local Regulations.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 5,
      title: "Reporting & Analytics",
      description:
        "Get insights into the performance of inventory through detailed reports and dashboards.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
    {
      id: 6,
      title: "Multi-Currency Support",
      description:
        "Ideal For Businesses Operating Internationally",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 7,
      title: "Client Management",
      description:
        "Centralized Database For Customer Details And History.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 8,
      title: "Data Security",
      description:
        "Secure Backups To Protect Sensitive Financial Data.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
  ],
  hrm: [
    {
      id: 1,
      title: " Employee Information",
      description:
        " Centralized and secure platform for employee data management, streamlining of HR work tasks, and improvement of workflows, hence decision making.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 2,
      title: " Attendance Tracking",
      description:
        "  Effective monitoring of attendance; managing leaves, and integration with payroll, for the most seamless and streamlined HR functions.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/1.webp",
    },
    {
      id: 3,
      title: " Leave Management",
      description:
        " Streamline leave requests, track balances, ensure compliance with the rules, and fully integrate into an attendance and payroll system.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/5.webp",
    },
    {
      id: 4,
      title: " Performance Monitoring",
      description:
        "  Employee performance tracking, goal setting, giving feedback, and reports for the increase of productivity and growth.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 5,
      title: "Recruitment & Tracking",
      description:
        "Streamline hiring through centralized applicant tracking, automated scheduling, and seamless integration for efficient recruitment.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
    {
      id: 6,
      title: "Training & Development",
      description:
        "Monitor employee training needs, participation, and progress to promote continuous learning and skill development.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 7,
      title: "Self-Service Portal",
      description:
        "Empower employees by using our Self-Service Portal to allow them to update personal details, request leave, and download payslips in one easy place.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 8,
      title: "Compliance Management",
      description:
        "Maintain the compliance of your business to labor laws and regulations using our Compliance Management system. Minimize risk with automated tracking, reporting, and updates.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
  ],
  employee: [
    {
      id: 1,
      title: " Time Tracking & Attendance",
      description:
        " Track Hand work hours, breaks, and attendance in real-time with automated time shadowing and attendance monitoring for effectiveness and compliance. ",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 2,
      title: " Geolocation Tracking",
      description:
        "  Geolocation shadowing in hand shadowing software boosts productivity, ensures safety, and verifies attendance by covering real-time position data. ",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/1.webp",
    },
    {
      id: 3,
      title: "  Screen & Activity Monitoring",
      description:
        "  Track Hand productivity with screen &  exertion monitoring. Capture screenshots, track app operation, and dissect exertion for better workflow perceptivity. ",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/5.webp",
    },
    {
      id: 4,
      title: " Productivity Analytics & Reporting",
      description:
        "   Productivity analytics & reporting in hand shadowing software help cover performance, identify trends, and optimize workflow for advanced platoon effectiveness. ",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 5,
      title: " Payroll & Billing Integration",
      description:
        "Seamlessly integrate payroll & billing with hand shadowing software for accurate time shadowing, automated payments, and hassle-free invoicing.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
    {
      id: 6,
      title: "Communication & Collaboration Tools",
      description:
        " Communication and collaboration tools in hand shadowing software enhance platoon commerce,  icing lawless updates, feedback, and design collaboration in real time. ",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/2.webp",
    },
    {
      id: 7,
      title: "Integration with Third-Party Tools",
      description:
        "Integration with third-party tools in hand shadowing software streamlines data inflow, enhances productivity, and simplifies payroll, HR, and design operations.",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/4.webp",
    },
    {
      id: 8,
      title: "Automated Alerts & Notifications",
      description:
        "Automated actions and announcements in hand shadowing software keep directors informed of crucial conditioning,  perfecting effectiveness and icing timely conduct. ",
      image:
        "https://www.bdtask.com/sp/sales-erp-software/img/sales-icon/6.webp",
    },
  ],
};

export default keyData;
